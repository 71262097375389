import React from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles,
} from '@material-ui/core'

import ContactsList from '../Contacts/ContactsList'


const useStyles = makeStyles(() => ({
    root: {paddingBottom: '20px'}
}))

const ContactSettings = ({ className, ...rest }) => {
    const classes = useStyles()
    return (
        <ContactsList className={classes.root} title="Contacts"></ContactsList>
    )
}

ContactSettings.propTypes = {
    className: PropTypes.string,
}

export default ContactSettings
