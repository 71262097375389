import React from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Paper,
    Typography,
    makeStyles,
} from '@material-ui/core'
import {
    BarChart,
    Bar,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    YAxis,
    XAxis,
} from 'recharts'

import { capitalize } from 'lodash'

import { generateTimeSequence } from 'src/views/Dashboards/RTaaS/util'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        marginBottom: theme.spacing(3),
        // marginTop: '17px',
    },
    barChart: {},
    container: {},
}))

const CustomLegend = (props) => {
    const { payload } = props

    return (
        <Box
            display="flex"
            width={400}
            mb={5}
            ml={7}
            mt={3}
            justifyContent="space-between"
        >
            {payload.map((entry, index) => {
                return (
                    <Box key={index}>
                        <svg width="36" height="12">
                            <rect
                                width="36"
                                height="12"
                                stroke="none"
                                fill={entry.color}
                                rx="6"
                            />
                        </svg>

                        <Typography
                            style={{ marginLeft: '16px' }}
                            display="inline"
                        >{`${capitalize(entry.value)} Emails`}</Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

const CustomBar = (props) => {
    const { fill, x, y, height, name } = props

    const barGap = name === 'sent' ? 30 : 5
    return (
        <rect
            x={x + barGap}
            y={y}
            width="10"
            height={height}
            stroke="none"
            fill={fill}
            rx="5"
        />
    )
}

const CustomTooltip = ({ active, payload, label }) => {
    const nameMapping = {
        totalCount: 'Sent Emails',
        compromisedCount: 'Compromised Emails',
    }
    if (active && payload && payload.length) {
        return (
            <Box>
                <Paper>
                    {payload.map((segment, index) => {
                        return (
                            <Box style={{ padding: '10px' }} key={index} display="flex">
                                <Box height={20} width={20} mr={1}>
                                    <svg>
                                        <circle
                                            fill={segment.color}
                                            cx="12"
                                            cy="12"
                                            r="4"
                                        />
                                    </svg>
                                </Box>
                                <Typography display="inline">{`${
                                    nameMapping[segment['dataKey']]
                                } : ${segment.value}`}</Typography>
                            </Box>
                        )
                    })}
                </Paper>
            </Box>
        )
    }

    return null
}

const CampaignGraph = ({ providerData, className, loadingComplete }) => {
    const classes = useStyles()

    const xAxisCategory = generateTimeSequence('months', 12, 'MMM', {
        reverseOrdering: true,
    })
    const transformedProviderData = Object.keys(providerData).map(
        (providerKey) => ({
            ...xAxisCategory[providerKey],
            ...providerData[providerKey],
        })
    )

    return (
        <Paper className={classes.root}>
            <ResponsiveContainer height={480} width="100%">
                {loadingComplete ? (
                    <BarChart
                        data={transformedProviderData}
                        margin={{
                            top: 10,
                            right: 15,
                            left: 15,
                        }}
                    >
                        <Legend
                            verticalAlign="top"
                            align="left"
                            content={CustomLegend}
                        />
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <YAxis
                            domain={['auto', 'auto']}
                            tickLine={false}
                            axisLine={false}
                        />
                        <XAxis
                            dataKey="label"
                            tickLine={false}
                            padding={{ left: -15 }}
                            axisLine={false}
                        />
                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        <Bar
                            dataKey="totalCount"
                            fill="#C2CBD8"
                            shape={CustomBar}
                            name="sent"
                        />
                        <Bar
                            dataKey="compromisedCount"
                            fill="#6314FF"
                            shape={CustomBar}
                            name="compromised"
                        />
                    </BarChart>
                ) : (
                    <div />
                )}
            </ResponsiveContainer>
        </Paper>
    )
}

CampaignGraph.propTypes = {
    providerData: PropTypes.object,
    headerText: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
}

export default CampaignGraph
