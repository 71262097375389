import { Box, makeStyles, Dialog, IconButton } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import { capitalize } from 'lodash'
import MUIDataTable from 'mui-datatables'
import { useState } from 'react'
import { deleteContact, getAllContacts } from 'src/actions/openapi'
import { TableButton } from 'src/components/Button'
import { useToastContext } from 'src/context/ToastContext'
import useLoader from 'src/hooks/useLoader'
import { AddContactForm, EditContactForm } from './ContactForm'
import { canChangeContact } from 'src/utils/permission'
import useUser from 'src/hooks/useUser'

const ContactForm = ({ onClose, children }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
        },
        closeIcon:{
            top: '14px',
            right: '15px',
            cursor: 'pointer',
            position: 'absolute',
        }
    }))
    const classes = useStyles()

    return (
        <Dialog
            open={true}
            aria-labelledby="modal-add-contact-title"
            aria-describedby="modal-add-contact-description"
            className={classes.root}
        >
            <IconButton className={classes.closeIcon} size='small' onClick={onClose} >
            <CloseIcon  />
            </IconButton>
            {children}
        </Dialog>
    )
}

const ContactsList = ({ title = 'Contacts', columns = [], options = {} }) => {
    const useStyles = makeStyles((theme) => ({
        actionsContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        profileTable:{
            paddingTop:"10px",
            '& th':{
                paddingBottom: '10px'
            }
        }
    }))
    const classes = useStyles()
    const [loading, showLoader, hideLoader] = useLoader()
    const user = useUser()
    const [contacts, setContacts] = useState([])
    const [showAddContactForm, setShowAddContactForm] = useState(false)
    const [showEditContactForm, setShowEditContactForm] = useState(false)
    const [editFormData, setEditFormData] = useState({})
    const { sendToast } = useToastContext()

    const fetchData = () => {
        showLoader()
        getAllContacts()
            .then((response) => {
                setContacts(response)
            })
            .catch((err) => {
                sendToast(
                    `Error getting contacts: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                hideLoader()
            })
    }

    const handleEdit = (dataIndex) => {
        const contact = contacts[dataIndex]
        setEditFormData({
            id: contact.id,
            firstName: contact.first_name,
            lastName: contact.last_name,
            phoneNumber: contact.phone_number,
            title: contact.title,
            email: contact.email,
            type: contact.type,
        })
        setShowEditContactForm(true)
    }

    const handleDelete = (dataIndex) => {
        deleteContact(contacts[dataIndex].id)
            .then((data) => {
                sendToast('Contact deleted successfully')
                fetchData()
            })
            .catch((err) => {
                sendToast(
                    `Error deleting contact: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {})
    }

    const handleTableInit = (action, tableState) => {
        fetchData()
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'filterChange':
            case 'changeRowsPerPage':
            case 'resetFilters':
            case 'search':
            case 'sort':
            case 'changePage': {
                fetchData()
            }
            /* falls through */  
            // no default 
        }
    }

    const CustomToolbar = () => {
        if (!canChangeContact(user)) {
            return <></>
        }
        return (
            <TableButton
                tooltipTitle="Add"
                onClick={() => {
                    setShowAddContactForm(true)
                }}
            >
                <Add color="primary" fontSize="small"></Add>
            </TableButton>
        )
    }

    const defaultOptions = {
        print: false,
        download: false,
        filter: false,
        search: false,
        sort: false,
        selectableRows: 'none',
        serverSide: false,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 15],
        //customToolbar: () => {},
        onTableInit: handleTableInit,
        onTableChange: handleTableChange,
        customToolbar: CustomToolbar,
    }

    const defaultColumns = [
        {
            name: 'title',
            label: 'Title',
        },
        {
            name: 'first_name',
            label: 'First name',
        },
        {
            name: 'last_name',
            label: 'Last name',
            options: {
                display: true,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <>{contacts[dataIndex].email}</>
                },
                display: true,
            },
        },
        {
            name: 'phone_number',
            label: 'Phone',
            options: {
                display: true,
            },
        },
        {
            name: 'type',
            label: 'Type',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <Box>{capitalize(contacts[dataIndex].type)}</Box>
                },
            },
        },
        {
            name: 'created_date',
            label: 'Created date',
            options: {
                display: false,
            },
        },
        {
            name: 'updated_date',
            label: 'Updated date',
            options: {
                display: false,
            },
        },
        {
            name: 'Actions',
            options: {
                display: canChangeContact(user),
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Box className={classes.actionsContainer}>
                            <TableButton
                                tooltipTitle="Edit"
                                onClick={() => {
                                    handleEdit(dataIndex)
                                }}                                
                                size="small"
                            >
                                <Edit color="primary" fontSize="small"></Edit>
                            </TableButton>
                            <TableButton
                                tooltipTitle="Delete"
                                onClick={() => {
                                    handleDelete(dataIndex)
                                }}                                
                                size="small"
                            >
                                <Delete
                                    color="primary"
                                    fontSize="small"
                                ></Delete>
                            </TableButton>
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            {loading}
            {showAddContactForm && (
                <ContactForm
                    onClose={() => {
                        setShowAddContactForm(false)
                    }}
                >
                    <AddContactForm
                        postSubmit={() => {
                            setShowAddContactForm(false)
                            fetchData()
                        }}
                    />
                </ContactForm>
            )}
            {showEditContactForm && (
                <ContactForm
                    onClose={() => {
                        setShowEditContactForm(false)
                    }}
                >
                    <EditContactForm
                        values={editFormData}
                        postSubmit={() => {
                            setShowEditContactForm(false)
                            fetchData()
                        }}
                    />
                </ContactForm>
            )}
            <MUIDataTable
                className={classes.profileTable}
                title={title}
                data={contacts}
                columns={[...defaultColumns, ...columns]}
                options={{ ...defaultOptions, ...options }}
            />
        </Box>
    )
}
export { ContactsList as default }
