import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Assets from './Assets'

import {
    getAllAssets,
    getCredentials,
    createAsset,
    createAWSCredential,
    createAZURECredential,
    createGCPCredential,
    addAssetsToEngagement,
    addCredentialsToEngagement,
    deleteAssetBulk,
    deleteCredentialBulk,
} from 'src/actions/openapi'
import camelcaseKeys from 'camelcase-keys'
import { useToastContext } from 'src/context/ToastContext'
import { useOrganization } from 'src/actions/customHooks'
import useLoader from 'src/hooks/useLoader'
// import useSocket from 'src/hooks/useSocket'

const AssetsPath = () => {
    const [credentials, setCredentials] = useState([])
    const [assets, setAssets] = useState([])
    const { sendToast } = useToastContext()
    const organization = useOrganization()
    const [loading, showLoader, hideLoader] = useLoader()
    // const { SocketResponse } = useSocket()

    // useEffect(() => {
    //     if (SocketResponse?.severity != 'success') {
    //         asyncGetAssets()
    //     }
    // }, [SocketResponse])
    async function handleCreateAsset(payload) {
        const apiPayload = {
            assetType: payload.scopeOptions,
            identifier: payload.alias,
        }
        return createAsset(apiPayload)
            .then((assetResponse) => {
                setAssets([...assets, camelcaseKeys(assetResponse)])
            })
            .catch((err) => {
                console.log('createAsset', err)
                sendToast(
                    `Error creating asset: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    async function handleDeleteAsset(assetId) {
        await deleteAssetBulk(organization.id, assetId)
            .then(() => {
                showLoader()
                sendToast('Asset deleted successfully.')
                asyncGetAssets()
            })
            .catch((err) => {
                console.log('deleteAssetBulk', err)
                sendToast(
                    `Error deleting asset(s): ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    async function handleCreateCredential(payload) {
        let cloudCredentialFunction
        const cloudPlatform = payload['cloudPlatform']
        switch (cloudPlatform) {
            case 'AWS':
                cloudCredentialFunction = createAWSCredential
                break
            case 'AZURE':
                cloudCredentialFunction = createAZURECredential
                break
            case 'GCP':
                cloudCredentialFunction = createGCPCredential
                break
            default:
                return Promise.reject('Invalid Cloud Platform')
        }
        return cloudCredentialFunction(payload)
            .then((assetResponse) => {
                asyncGetAssets()
            })
            .catch((err) => {
                console.log('cloudCredentialFunction', err)
                sendToast(
                    `Error creating credential: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    async function handleDeleteCredential(credentialId) {
        sendToast('Credential deletion is in progress.')
        const response = await deleteCredentialBulk(
            organization.id,
            credentialId
        ).catch((err) => {
            console.log('handleDeleteCredential', err)
            sendToast(
                `Error deleting credential(s): ${err?.response?.data?.detail}`,
                'error'
            )
        })
        return response
        //   setCredentials(credentials.filter(_cred => !credentialId.includes(_cred.id)))
    }
    async function asyncGetAssets() {
        showLoader()
        const functionsToExecute = [getAllAssets, getCredentials].map(
            (asyncFunction) =>
                asyncFunction().catch((err) => {
                    if (asyncFunction.name === 'getAllAssets') {
                        console.log('getAllAssets', err)
                        sendToast(
                            `Error getting assets: ${err?.response?.data?.detail}`,
                            'error'
                        )
                    } else if (asyncFunction.name === 'getCredentials') {
                        console.log('getCredentials', err)
                        sendToast(
                            `Error getting credentials: ${err?.response?.data?.detail}`,
                            'error'
                        )
                    } else {
                        sendToast(
                            `Error getting assets/credentials: ${err?.response?.data?.detail}`,
                            'error'
                        )
                    }
                    return []
                })
        )
        Promise.all(functionsToExecute)
            .then(([assetsResponse, credentialsResponse]) => {
                setAssets(assetsResponse.map(camelcaseKeys))
                setCredentials(
                    credentialsResponse.map(({ key, value }) => {
                        return { id: key, ...camelcaseKeys(value) }
                    })
                )
            })
            .finally(() => {
                hideLoader()
            })
    }
    useEffect(() => {
        asyncGetAssets()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const AddAssetToEngage = async (engage_id, alias) => {
        const params = {
            engagementId: engage_id,
            assets: Array.from(alias),
        }
        const response = await addAssetsToEngagement(params)
        if (response?.success) {
            sendToast('Asset added to Engagement Successfully.')
        } else {
            sendToast(response?.detail, 'warning')
        }
        asyncGetAssets()
    }
    const AddCredToEngage = async (engage_id, alias) => {
        const params = {
            engagementId: engage_id,
            credentials: Array.from(alias),
        }
        const response = await addCredentialsToEngagement(params)
        if (response?.success) {
            sendToast('Credential added to Engagement Successfully.')
        } else {
            sendToast(response?.detail, 'warning')
        }
        asyncGetAssets()
    }
    return (
        <>
            {loading}
            <Assets
                credentials={credentials}
                assets={assets}
                onAssetDelete={handleDeleteAsset}
                onAssetCreate={handleCreateAsset}
                onCredentialDelete={handleDeleteCredential}
                onCredentialCreate={handleCreateCredential}
                onRefreshAsset={asyncGetAssets}
                addAssetToEngage={AddAssetToEngage}
                addCredToEngage={AddCredToEngage}
            />
        </>
    )
}

export default AssetsPath

AssetsPath.propTypes = {
    credentials: PropTypes.array.isRequired,
}

AssetsPath.defaultProps = {
    credentials: [],
}
