import React, { useCallback } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'

const useStyles = makeStyles((theme) => ({
    fileUploadContainer: {
        padding: theme.spacing(3),
        border: `2px dashed ${theme.palette.divider}`,
        borderRadius: '8px',
        height: '80px',
        cursor: 'pointer',
    },
}))

const FileUpload = (props) => {
    const files = props.getter
    const setFiles = props.setter

    const onDrop = useCallback(
        (acceptedFiles) => {
            setFiles(acceptedFiles)
        },
        [setFiles]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: props.supported_formats,
    })

    const classes = useStyles()

    return (
        <div>
            <Box className={classes.fileUploadContainer} {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Typography>Drop the files here ...</Typography>
                ) : (
                    <Typography>
                        Drag or click to select image (png or jpeg)
                    </Typography>
                )}
            </Box>
            {files.length > 0 && (
                <Typography variant="h4" style={{ padding: '10px 0' }}>
                    Selected :
                    {files.map((file) => (
                        <Typography style={{ padding: '0px 5px' }} variant="p">
                            {file.path}
                        </Typography>
                    ))}
                </Typography>
            )}
        </div>
    )
}

export default FileUpload
