import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Box, makeStyles} from '@material-ui/core'

import GeneralSettings from './GeneralSettings'
import Password from './Password'
import OrganizationSetting from './Organization'
import ContactSettings from './ContactSettings'
import useUser from 'src/hooks/useUser'
import { canUpdateOrganization } from "src/utils/permission"


const useStyles = makeStyles((theme) => ({
    root: { margin: `-19px ${theme.spacing(3)}px ${theme.spacing(3)}px `,
            },
    spacer: { padding: theme.spacing(1) },
    profileDataScroll:{
        height: 'calc(100vh - 170px)',
        overflowY: 'auto',
        position: 'relative' 
    }
}))

const General = ({ className, ...rest }) => {
    const classes = useStyles()
    const user = useUser()
    
    return (
        <Box className={clsx(classes.root, className)} {...rest}>
            <div className={classes.profileDataScroll}>
            <GeneralSettings user={user} /> 
            <Box className={classes.spacer} />  
            <Password />          
            
            <Box className={classes.spacer} />
            <ContactSettings />            
            {canUpdateOrganization(user) && <OrganizationSetting></OrganizationSetting>}
            </div>
        </Box>
    )
}

General.propTypes = {
    className: PropTypes.string,
}

export default General
