import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { Box, makeStyles, Button } from '@material-ui/core'

function applyBoldStyle(inputText, classes, fontClass) {
    return (
        <Box
            className={classes[fontClass]}
            fontWeight="fontWeightBold"
            display="inline"
        >
            {inputText}
        </Box>
    )
}

const isPlural = (number, restString) => {
    if (number > 1 || number === 0) return `${number} ${restString}s`
    return `${number} ${restString}`
}

const useStyles = makeStyles((theme) => ({
    root: {},
    findingButton: {

        backgroundColor: `rgba(${theme.palette.primary.mediumbg})`,
        boxShadow: `0 0 0.5px 1px rgba(${theme.palette.primary.medium})`,
        color: theme.palette.text.lightdark,
        padding: '0px 3px',
        margin: '0 2px 2px',
        '&:hover': {
            backgroundColor: `rgba(${theme.palette.primary.medium})`,
            color: theme.palette.text.darklight,
        },
    },
    signalButton: {
        backgroundColor: `rgba(${theme.palette.text.purple}, 0.1)`,
        boxShadow: `0 0 0.5px 1px rgba(${theme.palette.text.purple},1)`,
        color: theme.palette.text.lightdark,
        padding: '0px 3px',
        margin: '0 2px 2px',
        '&:hover': {
            backgroundColor: `rgba(${theme.palette.text.purple}, .8)`,
            color: theme.palette.text.darklight,
        },
    },
    escalatedButton: {
        backgroundColor: `rgba(${theme.palette.text.orange}, 0.1)`,
        boxShadow: `0 0 0.5px 1px rgba(${theme.palette.text.orange},1)`,
        color: theme.palette.text.lightdark,
        padding: '0px 3px',
        margin: '0 2px 2px',
        '&:hover': {
            backgroundColor: `rgba(${theme.palette.text.orange}, .8)`,
            color: theme.palette.text.darklight,
        },
    },
    outcomeButton: {
        backgroundColor: `rgba(${theme.palette.text.red}, 0.1)`,
        boxShadow: `0 0 0.5px 1px rgba(${theme.palette.text.red},1)`,
        color: theme.palette.text.lightdark,
        padding: '0px 3px',
        margin: '0 2px 2px',
        '&:hover': {
            backgroundColor: `rgba(${theme.palette.text.red}, .8)`,
            color: theme.palette.text.darklight,
        },
    },
    lastRow: {
        marginTop: '4px'
    },
    cardText: {
        color: theme.palette.background.primary,
        fontSize: '14px',
        lineHeight: '25px',
        textAlign: 'center',
        letterSpacing: '-0.2px',
    },
    assessmentLink: {
        color: '#6792FF',
        textDecoration: 'none',
    },
    buttonSpan: {
        marginBottom: '-2px',
    },
}))

const CategoryCardText = ({
    findingsCount,
    escalatedCount,
    outcomesCount,
    category,
    categoryUrlParams,
    signalUrlParams
}) => {
    const classes = useStyles()
    const isPopulatedCard = findingsCount > 0 || escalatedCount > 0 || outcomesCount > 0
    return (
        <Box className={classes.cardText}>
            {isPopulatedCard && (
                <Fragment>
                    This service generated {' '}
                    <br></br>
                    <Button
                        color="primary"
                        onClick={() => { }}
                        variant="contained"
                        size="small"
                        className={classes.findingButton}
                        component={RouterLink}
                        to={`/signals?${signalUrlParams}`}
                    >
                        {applyBoldStyle(
                            `${isPlural(
                                findingsCount,
                                'signal'
                            )}`,
                            classes,
                            'buttonSpan'
                        )}
                    </Button>
                    {' '}
                    <br></br>
                    Resulting in
                    <br></br>
                    <Button
                        color="primary"
                        onClick={() => { }}
                        variant="contained"
                        size="small"
                        className={classes.escalatedButton}
                        component={RouterLink}
                        to={`/outcomes?${categoryUrlParams}`}
                    >
                        {applyBoldStyle(
                            `${isPlural(
                                escalatedCount,
                                category === 'Attack'
                                    ? 'finding'
                                    : category === 'Detect'
                                        ? 'case'
                                        : 'vuln'
                            )}`,
                            classes,
                            'buttonSpan'
                        )}
                    </Button>
                    {' '}
                    <br></br>
                    And
                    <br></br>

                    <Button
                        color="primary"
                        onClick={() => { }}
                        variant="contained"
                        size="small"
                        className={classes.outcomeButton}
                        component={RouterLink}
                        to={`/outcomes?${signalUrlParams}`}
                    >
                        {applyBoldStyle(
                            `${isPlural(
                                outcomesCount,
                                'outcome'
                            )}`,
                            classes,
                            'buttonSpan'
                        )}
                    </Button>


                </Fragment>
            )}
            {!isPopulatedCard && (
                <Fragment>
                    This card will be updated with any applicable {category === 'Attack'
                        ? 'findings'
                        : category === 'Detect'
                            ? 'cases'
                            : 'vulns'}.
                </Fragment>
            )}
        </Box>
    )
}

CategoryCardText.defaultProps = {
    findingsCount: 0,
    findingsColor: '#33d299',
    escalatedCount: 0,
    outcomesCount: 0,
    signalsColor: '#33d299',
    scanDate: '',
    categoryUrlParams: '',
    signalUrlParams: '',
}

CategoryCardText.propTypes = {
    findingsCount: PropTypes.number,
    findingsColor: PropTypes.string,
    escalatedCount: PropTypes.number,
    outcomesCount: PropTypes.number,
    signalsColor: PropTypes.string,
    category: PropTypes.string.isRequired,
    categoryUrlParams: PropTypes.string,
    signalUrlParams: PropTypes.string,
}

export default CategoryCardText
