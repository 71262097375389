import { useState, useEffect } from 'react'
import camelcaseKeys from 'camelcase-keys'
import { getOrganization, getCurrentUser } from './openapi'
import { useLocation } from 'react-router-dom'

export function useOrganization() {
    const [organization, setOrganization] = useState({})
    async function asyncGetOrganization() {
        try {
            const result = await getOrganization()
            setOrganization(camelcaseKeys(result))
        } catch (err) {
            console.log('getOrganization', err)
        }
    }
    useEffect(() => {
        asyncGetOrganization()
    }, [])
    return organization
}

export function useUserDetails() {
    const [userDetails, setUserDetails] = useState(-1)
    async function asyncFetchProfileSettings() {
        try {
            const results = await getCurrentUser()
            const { firstName, lastName, emailAddress, role } =
                camelcaseKeys(results)
            setUserDetails({
                firstName,
                lastName,
                emailAddress,
                role,
            })
        } catch (err) {
            console.log('getCurrentUser', err)
        }
    }
    useEffect(() => {
        asyncFetchProfileSettings()
    }, [])
    return userDetails
}

export function useQuery() {
    return new URLSearchParams(useLocation().search)
}
