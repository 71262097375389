import camelcaseKeys from 'camelcase-keys'
import { createContext, useEffect, useState } from 'react'
import { getCurrentUser } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
const UserContext = createContext()

export const roles = {
    ADMIN: 'ADMIN',
    PORTAL_ADMIN: 'PORTAL_ADMIN',
    PORTAL_USER: 'PORTAL_USER',
    PENTEST_ADMIN: 'PENTEST_ADMIN',
    PENTESTER: 'PENTESTER',
    ETL_SERVICE: 'ETL_SERVICE',
    SALES: 'SALES',
    MDR: 'MDR',
}

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const { sendToast } = useToastContext()
    async function asyncFetchProfileSettings() {
        try {
            const results = await getCurrentUser()
            const { firstName, lastName, emailAddress, role } =
                camelcaseKeys(results)
            setUser({
                firstName,
                lastName,
                emailAddress,
                role,
            })

        } catch (err) {
            console.log('getCurrentUser', err)
            sendToast(
                `Error getting user: ${err?.response?.data?.detail}`,
                'error'
            )
        }
    }


    useEffect(() => {
        asyncFetchProfileSettings()
    }, [])

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export default UserContext
