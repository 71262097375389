import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import QRCode from 'qrcode.react'
import {
    Box,
    Paper,
    Button,
    FormHelperText,
    TextField,
    CardContent,
    Typography,
    Divider,
} from '@material-ui/core'
import { ReactComponent as LogoDark } from 'src/theme/s2/Ultraviolet_Logo_Primary_RGB.svg'
import { useToastContext } from 'src/context/ToastContext'

// const useStyles = makeStyles((theme) => ({
//     registerDeviceLink: {
//         marginTop: theme.spacing(2),
//     },
// }))

// const generateQRCode = (email, code) => {
//     return `otpauth://totp/Warrior:${email}?secret=${code}&issuer=stage2sec`
// }

const formSchema = Yup.object().shape({
    verificationCode: Yup.string().required('Required'),
})

const MFAAddDevice = ({ user, email, dispatch }) => {
    // Kept as is for future use
    // eslint-disable-next-line
    const [qrCode, setQrCode] = useState("")
    const { sendToast } = useToastContext()

    // const setupTOTP = async () => {
    //     Auth.setupTOTP(user).then((code) => {
    //         setQrCode(generateQRCode(email, code))
    //     });
    // }

    const verifyTotpToken = (challengeAnswer) => {
        Auth.verifyTotpToken(user, challengeAnswer)
            .then(() => {
                Auth.setPreferredMFA(user, 'TOTP')
                sendToast('Device added successfully. Please sign in.')
                dispatch({
                    type: 'RESETPOSTADDDEVICE',
                })
            })
            .catch((e) => {
                sendToast(`Error: ${e}`, 'error')
            })
    }

    const handleSubmit = async (values) => {
        verifyTotpToken(values.verificationCode)
    }

    useEffect(() => {
        // setupTOTP()
    }, [])

    return (
        <Box maxWidth={480} margin="auto" marginTop="5em">
            <Paper>
                <CardContent>
                    <Box
                        pt={2}
                        pb={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <LogoDark width="75%" height="7em" />
                        <Typography
                            variant="h2"
                            color="textPrimary"
                            align="center"
                        >
                            Sign in
                        </Typography>
                    </Box>

                    <Divider />

                    <Box
                        mt={3}
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <QRCode value={qrCode} size={200} />
                        <p>
                            Scan the QR code and enter verification code to add
                            device
                        </p>
                    </Box>
                    <Box>
                        <Formik
                            initialValues={{
                                verificationCode: '',
                            }}
                            validationSchema={formSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                            }) => (
                                <Form>
                                    <TextField
                                        error={Boolean(
                                            touched.verificationCode &&
                                                errors.verificationCode
                                        )}
                                        fullWidth
                                        placeholder="Enter code received on authenticator app"
                                        label="Verfication Code"
                                        name="verificationCode"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                    {errors.verificationCode &&
                                    touched.verificationCode ? (
                                        <FormHelperText>
                                            {errors.verificationCode}
                                        </FormHelperText>
                                    ) : null}
                                    <Box
                                        mt={3}
                                        justifyContent="center"
                                        display="flex"
                                    >
                                        <Button
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            align="center"
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </CardContent>
            </Paper>
        </Box>
    )
}

export default MFAAddDevice
