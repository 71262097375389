import { roles } from 'src/context/UserContext'

const hasRole = (user, roles) => {
    return roles.includes(user.role?.role)
}

export const canChangeContact = (user) => {
    return hasRole(user, [
        roles.ADMIN,
        roles.PORTAL_ADMIN,
        roles.PENTEST_ADMIN,
        roles.PENTESTER,
        roles.SALES,
    ])
}

export const canUpdateOrganization = (user) => {
    return hasRole(user, [
        roles.ADMIN,
        roles.PORTAL_ADMIN,
        roles.PENTEST_ADMIN,
        roles.PENTESTER,
    ])
}

export const canSwitchOrganization = (user) => {
    return hasRole(user, [
        roles.ADMIN,
        roles.PENTEST_ADMIN,
        roles.PENTESTER,
        roles.SALES,
        roles.MDR,
    ])
}

export const canAccessAcount = (user) => {
    return hasRole(user, [
        roles.ADMIN,
        roles.PORTAL_ADMIN,
        roles.PENTEST_ADMIN,
        roles.PENTESTER,
        roles.SALES,
    ])
}

export const hasReportUploadAccess = (user) => {
    return hasRole(user, [
        roles.ADMIN,
        roles.PENTESTER,
        roles.PENTEST_ADMIN,
        roles.ETL_SERVICE,
        roles.SALES,
        roles.MDR,
    ])
}
