import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IndividualOutcome from './IndividualOutcome'
import { useToastContext } from 'src/context/ToastContext'

import { getIndividualFinding } from 'src/actions/openapi'
import camelcaseKeys from 'camelcase-keys'

const IndividualOutcomePath = ({ match }) => {
    const [finding, setIndividualFinding] = useState({})
    const { sendToast } = useToastContext()

    useEffect(() => {
        async function asyncGetIndividualFinding() {
            const finding = await getIndividualFinding(match.params.findingId)
            setIndividualFinding(camelcaseKeys(finding))
        }
        asyncGetIndividualFinding().catch((err) => {
            console.log('asyncGetIndividualFinding', err)
            sendToast(
                `Error getting finding: ${err?.response?.data?.detail}`,
                'error'
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.findingId])

    return <IndividualOutcome {...finding} onApiResponse={(response) =>
        setIndividualFinding(camelcaseKeys(response))
    } />
}

export default IndividualOutcomePath

IndividualOutcomePath.propTypes = {
    match: PropTypes.object,
}
