import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    colors,
    makeStyles,
} from '@material-ui/core'
import { ATTACK_SURFACES } from 'src/constants'

import { Link as RouterLink } from 'react-router-dom'
import { securityScoreValues } from './util'
import CategoryCardText from './CategoryCardTextV2'
import { ReactComponent as AttackSVG } from 'src/theme/s2/UVC_Icon_attack.svg'
import { ReactComponent as UVCDashboard } from 'src/theme/s2/UVC_Icon_dashboard.svg'
import { ReactComponent as DetectIcon } from 'src/theme/s2/UVC_Icon_Managed_Protection.svg'
import { ReactComponent as ValidateIcon } from 'src/theme/s2/UVC_Icon_broad-visibility.svg'

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        position: 'absolute',
    },
    card: {
        margin: '11px',
        width: '270px',
        minWidth: '180px',
        height: '300px',
        position: 'relative',
        borderRadius: '12px',
        border: `1px solid ${colors.blueGrey[50]}`,
    },
    cardHeading: {
        marginTop: '10px',
        marginBottom: '0',
        display: 'flex',
        justifyContent: 'center',
        // color: theme.palette.text.violet,
    },
    cardTextContainer: {
        marginTop: '5px',
    },
    viewButton: {
        position: 'absolute',
        bottom: '25px',
        left: 0,
        right: 0,
        margin: 'auto',
        width: '115px',
    },
}))

const categoryBorderMap = {
    Critical: '#ac0000',
    Poor: '#de5d28',
    Okay: '#C9DE0E',
    Good: '#6314ff',
};

const cardIconMap = {
    Validate: <ValidateIcon width="50px" />,
    Detect: <DetectIcon width="50px" />,
    Attack: <AttackSVG width="50px" />,
    OSINT: <UVCDashboard width="50px" />,
};

const getEscalatedUrlParams = (category) => {
    const urlParams = new URLSearchParams();
    urlParams.append('escalated', 'true');

    if (category === 'Attack') {
        const riskTypes = ['FINDING', 'LEAD', 'TTP', 'CIS', 'INSIGHTS'];
        riskTypes.forEach((risk) => urlParams.append('riskType', risk));
    } else if (category === 'Validate') {
        urlParams.append('riskType', 'VULNERABILITY');
    } else if (category === 'Detect') {
        urlParams.append('riskType', 'MDR');
    }

    return urlParams;
};

const getSignalUrlParams = (category) => {
    const urlParams = new URLSearchParams();

    if (category === 'Attack') {
        const riskTypes = ['FINDING', 'LEAD', 'TTP', 'CIS', 'INSIGHTS'];
        riskTypes.forEach((risk) => urlParams.append('riskType', risk));
    } else if (category === 'Validate') {
        urlParams.append('riskType', 'VULNERABILITY');
    } else if (category === 'Detect') {
        urlParams.append('riskType', 'MDR');
    }

    return urlParams;
};


const CategoryCard = ({
    category,
    illustration,
    totalFindings,
    totalEscalated,
    totalOutcomes,
    name,
    runTime,
    active,
}) => {
    const classes = useStyles();
    const [isMouseOver, setMouseOver] = useState(false);

    const categoryUrlParams = getEscalatedUrlParams(category);
    const signalUrlParams = getSignalUrlParams(category);

    return (
        <Card
            className={classes.card}
            style={{ border: `2px solid ${categoryBorderMap[name]}` }}
            onMouseOver={() => setMouseOver(true)}
            onMouseOut={() => setMouseOver(false)}
            raised={isMouseOver || active}
            elevation={isMouseOver || active ? 20 : 2}
        >
            <CardContent>
                {name !== 'neutral' ? (
                    <Box display="flex" alignItems="center">
                        <img src={illustration} alt={name} style={{ width: '20px' }} />
                        <Typography style={{ marginLeft: '4px', fontSize: '14px' }}>{name}</Typography>
                    </Box>
                ) : (
                    <Box height={'25px'}></Box>
                )}

                <Box>
                    <Box className={classes.cardHeading}>
                        <Typography variant="h3">{category.toUpperCase()}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        {cardIconMap[category]}
                    </Box>
                    <Box className={classes.cardTextContainer}>
                        <CategoryCardText
                            findingsCount={totalFindings}
                            escalatedCount={totalEscalated}
                            outcomesCount={totalOutcomes}
                            category={category}
                            scanDate={runTime}
                            categoryUrlParams={categoryUrlParams.toString()}
                            signalUrlParams={signalUrlParams.toString()}
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

CategoryCard.defaultProps = {
    scoreLabel: securityScoreValues['GOOD'],
    totalFindings: null,
    totalEscalated: null,
}
CategoryCard.propTypes = {
    category: PropTypes.string.isRequired,
    totalFindings: PropTypes.number,
    totalEscalated: PropTypes.number,
}

export default CategoryCard;