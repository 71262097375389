import moment from 'moment'

export function generateTimeSequence(
    category,
    countBack,
    format,
    { dayOffset = 0, reverseOrdering = true }
) {
    const date = []
    const dateStart = moment.utc().startOf(category).add(dayOffset, 'day')
    const dateEnd = moment().subtract(countBack, category)
    while (dateEnd.diff(dateStart, category) <= 0) {
        date.push({
            label: dateStart.format(format),
            value: dateStart.toISOString(),
        })
        dateStart.subtract(1, category)
    }
    return reverseOrdering ? date.reverse() : date
}

export function getPreviousMonths(monthsBack = 1, category) {
    const prevMonth = moment.utc().subtract(monthsBack, 'M')
    return moment().diff(prevMonth, category)
}

export function generateRandomColorValues(assessmentIndex = 8) {
    const colorBank = [
        [244, 123, 42], // orange
        [37, 108, 246], // blue
        [243, 188, 29], // yellow
        [155, 81, 224], // purple
        [95, 201, 123], // green
        [235, 87, 87], // red
        [86, 204, 242], // light blue
    ]

    const outOfBounds = assessmentIndex > colorBank.length

    const colorValue = outOfBounds
        ? colorBank[Math.floor(Math.random() * colorBank.length)]
        : colorBank[assessmentIndex]
    function generateOffsetNumber() {
        return (
            Math.floor(Math.random() * 15) *
            (Math.round(Math.random()) ? 1 : -1)
        )
    }

    function rgbToHex(r, g, b) {
        return (
            '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
        )
    }

    const rbgColor = colorValue.map((number) =>
        outOfBounds ? number + generateOffsetNumber() : number
    )

    return {
        rbgColor,
        hexColor: rgbToHex(...rbgColor),
    }
}
