import React from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Divider,
    TextField,
    Typography,
    makeStyles,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogTitle,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useLoader from 'src/hooks/useLoader'
import { REASON_LIST } from 'src/constants';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useToastContext } from 'src/context/ToastContext';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            maxWidth: '760px',
            margin: '0 auto',
        },
        modal: {
            // width: '675px',
            // height: `calc(825px + ${theme.spacing(6)}px + ${theme.spacing(
            //     4
            // )}px)`,
            // margin: `${theme.spacing(2)}px auto`,
        },
        modalContent: {
            backgroundColor: theme.palette.background.default,
            minWidth: '300px',
        },
        modalForm: {
            padding: theme.spacing(2),
        },
        modalHeader: {
            fontSize: '1.2146rem',
            fontWeight: '500'
        },
        textField: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(1),
        },
        userModalButton: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
        },
        closeIcon: {
            position: 'absolute',
            right: '15px',
            cursor: 'pointer',
            top: '14px'
        },
        // modelConente: {
        //     maxHeight: 'calc(100vh - 242px)',
        //     overflowY: 'auto',
        //     paddingRight: '5px'
        // }
    }
})

const CloseModal = ({ open, onCloseModal, onCloseSignal }) => {
    const classes = useStyles()
    const { sendToast } = useToastContext()
    const [loading, showLoader, hideLoader] = useLoader()
    const initialReasonDetails = {
        reason: '',
        reason_other: '',
    }
    const formValidationSchema = Yup.object().shape({
        reason: Yup.string().required('Required'),
        reason_other: Yup.string().when('reason', {
            is: 'OTHER',
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
        }),
    });
    return (
        <Dialog open={open} onCloseModal={onCloseModal} className={classes.modal}>

            <Formik
                initialValues={initialReasonDetails}
                validationSchema={formValidationSchema}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    await onCloseSignal(values)
                        .then((response) => {
                            setSubmitting(false)
                            resetForm()
                        }).catch((error) => {
                            sendToast(
                                error,
                                'error'
                            )
                        })
                        .finally(() => {
                            hideLoader()
                        })
                }}
            >

                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <Box
                        style={{ outline: 'none' }}
                        className={classes.modalContent}
                    >
                        <form onSubmit={handleSubmit}>

                            <DialogTitle >
                                <Typography
                                    color="textPrimary"
                                    width="100%"
                                    className={classes.modalHeader}
                                >Reason for Closing</Typography>
                                <IconButton className={classes.closeIcon} size='small' onClick={() => {
                                    onCloseModal(null)
                                }} >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>

                            <Divider width={'100%'} />
                            <Box className={classes.modalForm}>
                                <div className={classes.modelConent}>
                                    <Box>
                                        <FormControl className={classes.textField}
                                            variant="outlined" style={{ width: '95%' }}
                                            error={Boolean(
                                                touched.reason &&
                                                errors.reason
                                            )}
                                            fullWidth
                                            helpertext={
                                                touched.reason &&
                                                errors.reason
                                            }
                                        >
                                            <InputLabel htmlFor="reasontype">Reason</InputLabel>

                                            <Select
                                                id="reasontype"
                                                label="reason"
                                                name="reason"
                                                variant="outlined"
                                                placeholder="reason *"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.reason}
                                            >
                                                {REASON_LIST.map((_reason, index) =>
                                                    <MenuItem key={index} value={_reason.value}>{_reason.key}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {values.reason === "OTHER" ? (<Box style={{ width: '100%' }}>
                                        <TextField
                                            className={classes.textField}
                                            error={Boolean(
                                                touched.reason_other &&
                                                errors.reason_other
                                            )}
                                            fullWidth
                                            helpertext={
                                                touched.reason_other &&
                                                errors.reason_other
                                            }
                                            style={{ width: '95%' }}
                                            label="Other Reason"
                                            name="reason_other"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.reason_other}
                                            variant="outlined"
                                        />
                                    </Box>) : ('')}
                                </div>
                                <Box className={classes.userModalButton}>

                                    <Button
                                        color="secondary"
                                        onClick={() => {
                                            onCloseModal(null)
                                        }}
                                        variant="contained"
                                        size="small"
                                    >
                                        Cancel
                                    </Button> <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                    >
                                        Submit
                                    </Button>

                                </Box>
                            </Box>
                        </form>
                    </Box>
                )}
            </Formik>

        </Dialog>
    )
}
CloseModal.propTypes = {
    onSubmit: PropTypes.func,
    onCloseModal: PropTypes.func,
    open: PropTypes.bool,

}

CloseModal.defaultProps = {}

export default CloseModal