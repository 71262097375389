import moment from "moment";
import { truncate } from "lodash";


export const pluralize = (word, count) => {
    if (count === 1) {
        return word;
    }
    return word + 's';
}

export const formatDateString = (str, toFormat = "YYYY-MM-DD") => {
    return moment(str).format(toFormat)
}

export const truncateString = (str, num = 30) => {
    return truncate(str, { length: num })
}

export const extract_file_extension = (filename) => {
    if (filename === null){
        return ""
    }
    return filename.split('.').pop();
}

export const has_allowed_extension = (filename, allowed_extensions) => {
    const extension = extract_file_extension(filename);
    return allowed_extensions.includes(extension);
}