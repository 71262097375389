import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { useOrganization } from 'src/actions/customHooks'

import AdminAccount from './AdminAccount'

import camelcaseKeys from 'camelcase-keys'

import {
    getAllUsers,
    createUser,
    deleteUser,
    resendInvite,
} from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import useUser from 'src/hooks/useUser'
import useLoader from 'src/hooks/useLoader'

const useStyles = makeStyles((theme) => ({
    root: { margin: theme.spacing(3) },
    spacer: { padding: theme.spacing(2) },
}))

const General = ({ className, ...rest }) => {
    const classes = useStyles()
    const organization = useOrganization()
    const { role } = useUser()
    const [users, setUsersList] = useState([])
    const { sendToast } = useToastContext()
    const [loading, showLoader, hideLoader] = useLoader()

    async function handleUserCreate(userDetails) {
        return await createUser({
            ...userDetails,
            organizationId: organization.id,
        })
            .then((response) => {
                asyncFetchAccountUsers()
                return response
            })
            .catch((err) => {
                console.log('createUser', err)
                sendToast(
                    `Error creating user: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    async function handleUserDelete(userId) {
        return await deleteUser({
            id: userId,
            organizationId: organization.id,
        })
            .then(() => {
                setUsersList(users.filter((user) => user.id !== userId))
            })
            .catch((err) => {
                console.log('deleteUser', err)
                sendToast(
                    `Error deleting user: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }
    async function handleResendInvite(id, emailAddress) {
        return await resendInvite({
            userId: id,
            organizationId: organization.id,
        })
            .then(() => {
                sendToast(`Invite resent to ${emailAddress}`)
            })
            .catch((err) => {
                console.log('Error resending invite', err)
                const errorMessage = Array.isArray(err?.response?.data?.detail)
                    ? err.response.data.detail[0].msg
                    : err?.response?.data?.detail

                sendToast(`Error resending invite: ${errorMessage}`, 'error')
            })
    }

    async function asyncFetchAccountUsers() {
        showLoader()
        const results = await getAllUsers()
        const transformedUsers = results.map(camelcaseKeys)
        setUsersList(transformedUsers)
        hideLoader()
    }

    useEffect(() => {
        asyncFetchAccountUsers().catch((err) => {
            console.log('asyncFetchAccountUsers', err)
            sendToast(
                `Error getting users: ${err?.response?.data?.detail}`,
                'error'
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box className={clsx(classes.root, className)}>
            {loading}
            <AdminAccount
                userAccounts={users}
                onUserCreate={handleUserCreate}
                onUserDelete={handleUserDelete}
                onResendInvite={handleResendInvite}
                currentRole={role}
            />
        </Box>
    )
}

General.propTypes = {
    className: PropTypes.string,
}

export default General
