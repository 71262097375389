import {
    Box,
    Button,
    Divider,
    Radio,
    TextField,
    Typography,
    DialogTitle,
    makeStyles,
    Paper
} from '@material-ui/core'
import { capitalize } from '@material-ui/core'
import { useFormik } from 'formik'
import { createContact, updateContact } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import * as Yup from 'yup'

const FormSkeleton = ({
    title,
    values,
    errors,
    touched,
    onSubmit,
    onChange,
    onBlur,
}) => {
    const useStyles = makeStyles((theme) => ({
    
        paper: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
        },
        dailogContent:{
            maxHeight:'calc(100vh - 220px)',
            overflowY: 'auto'
        },
        contactFormInput:{
            width: '49%'
        },
        modalHeader: {
            fontSize: '1.2146rem',
            fontWeight: '500'
        },
    }))
    const classes = useStyles()
    return (
        <>
            <DialogTitle><Typography className={classes.modalHeader}>{title}</Typography></DialogTitle>
            <Divider />
            <Paper className={classes.paper}>
                <div className={classes.dailogContent}>
            <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                gridGap={10}
                maxWidth="700px"
            >
                <TextField
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    className={classes.contactFormInput}
                    value={values.firstName}
                    error={touched.firstName && errors.firstName}
                    helpertext={touched.firstName && errors.firstName}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                />
                <TextField
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    className={classes.contactFormInput}
                    value={values.lastName}
                    error={touched.lastName && errors.lastName}
                    helpertext={touched.lastName && errors.lastName}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                />
                <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    variant="outlined"
                    className={classes.contactFormInput}
                    value={values.phoneNumber}
                    error={touched.phoneNumber && errors.phoneNumber}
                    helpertext={touched.phoneNumber && errors.phoneNumber}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <TextField
                    name="email"
                    label="Email"
                    variant="outlined"
                    className={classes.contactFormInput}
                    value={values.email}
                    error={touched.email && errors.email}
                    helpertext={touched.email && errors.email}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                />
                <TextField
                    name="title"
                    label="Title"
                    variant="outlined"
                    className={classes.contactFormInput}
                    value={values.title}
                    error={touched.title && errors.title}
                    helpertext={touched.title && errors.title}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </Box>
            <Box
                mt={2}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                >
                    {['BUSINESS', 'TECHNICAL', 'EMERGENCY'].map((label) => {
                        return (
                            <Box display="flex" alignItems="center" key={label}>
                                <Radio
                                    name="type"
                                    checked={values.type === label}
                                    value={label}
                                    onChange={onChange}
                                />
                                <Typography color="textPrimary">
                                    {capitalize(label)}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box></Box>
                </div>
                <Box mt={2} alignSelf="end">
                    <Button
                        onClick={onSubmit}
                        size="small"
                        color="primary"
                        variant="contained"
                    >
                        Save Contact
                    </Button>
                </Box>
            
            </Paper>
        </>
    )
}

const Form = ({ values = {}, onSubmit, ...props }) => {
    const defaultValues = {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        title: '',
        type: 'business',
    }

    const handleSubmit = onSubmit
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const contactSchema = Yup.object().shape({
        firstName: Yup.string()
            .max(50, 'First name must be less than 50 characters')
            .required('Required'),
        lastName: Yup.string()
            .max(50, 'Last name must be less than 50 characters')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required')
            .nullable(),
        phoneNumber: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
        ),
        title: Yup.string().max(50, 'Title must be less than 50 characters'),
        type: Yup.string().oneOf(['BUSINESS', 'TECHNICAL', 'EMERGENCY']),
    })

    const formik = useFormik({
        initialValues: { ...defaultValues, ...values },
        validationSchema: contactSchema,
        onSubmit: handleSubmit,
    })

    return (
        <FormSkeleton
            {...props}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            onSubmit={formik.handleSubmit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
    )
}

export const AddContactForm = (props) => {
    const { sendToast } = useToastContext()
    const handleSubmit = (values) => {
        createContact(values)
            .then((data) => {
                sendToast('Contact added successfully')
            })
            .catch((err) => {
                sendToast(
                    `Error creating contact: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                props.postSubmit()
            })
    }

    return <Form title="Add Contact" onSubmit={handleSubmit} {...props} />
}

export const EditContactForm = (props) => {
    const { sendToast } = useToastContext()
    const handleSubmit = (values) => {
        updateContact(values)
            .then((data) => {
                sendToast('Contact updated successfully')
            })
            .catch((err) => {
                sendToast(
                    `Error updating contact: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                props.postSubmit()
            })
    }

    return <Form title="Edit Contact" onSubmit={handleSubmit} {...props} />
}
