import { Box, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { useState } from 'react'
import { addAssetsToEngagement, getAllAssets } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import useLoader from 'src/hooks/useLoader'

const AssetsListing = ({
    engagementId,
    postAddToEngagement,
    title = 'Assets',
    columns = [],
    options = {},
}) => {
    const [loading, showLoader, hideLoader] = useLoader()
    const [assets, setAssets] = useState([])
    const { sendToast } = useToastContext()

    const fetchAssets = () => {
        showLoader()
        getAllAssets()
            .then((response) => {
                setAssets(response)
            })
            .catch((err) => {
                console.log('getAllAssets', err)
                sendToast(
                    `Error getting assets: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                hideLoader()
            })
    }
    const handleTableInit = (action, tableState) => {
        fetchAssets()
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'filterChange':
            case 'changeRowsPerPage':
            case 'resetFilters':
            case 'search':
            case 'sort':
            case 'changePage': {
                fetchAssets()
            }
            /* falls through */  
            // no default   
        }
    }

    const handleAddAssetToEngagement = (assetId) => {
        const params = { engagementId, assets: [assetId] }
        addAssetsToEngagement(params)
            .then((response) => {
                sendToast('Asset added to engagement')
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    sendToast(error.response.data.detail, 'error')
                } else {
                    sendToast(
                        'Error occurred when adding asset to engagement. Please try again',
                        'error'
                    )
                }
            })
            .finally(() => {
                postAddToEngagement()
            })
    }
    const defaultOptions = {
        responsive: "standard",
        tableBodyHeight: 'calc(100vh - 312px)',
        print: false,
        download: false,
        filter: false,
        search: false,
        selectableRows: 'none',
        serverSide: true,
        customToolbar: () => {},
        onTableInit: handleTableInit,
        onTableChange: handleTableChange,
    }

    const defaultColumns = [
        {
            name: 'row_number',
            label: 'No.',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return <span>{dataIndex + 1}</span>
                },
            },
        },
        {
            name: 'asset_type',
            label: 'Type',
        },
        {
            name: 'identifier',
            label: 'Identifier',
        },
        {
            name: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => {
                                handleAddAssetToEngagement(assets[dataIndex].id)
                            }}
                            size="small"
                        >
                            <Add color="primary" fontSize="small"></Add>
                        </IconButton>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            {loading}
            <MUIDataTable
                title={title}
                data={assets}
                columns={[...defaultColumns, ...columns]}
                options={{ ...defaultOptions, ...options }}
            />
        </Box>
    )
}
export { AssetsListing as default }
