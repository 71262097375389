import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Box,
    Button,
    CardContent,
    makeStyles,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Dialog,
    DialogTitle,
    IconButton
} from '@material-ui/core'
import { getAllEngagements } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '572px',
        margin: '0 auto',
        width: '572px'
    },
    formControl: {
        width: '75%',
    },
    select: {
        maxHeight: 300,
    },
    modalTitle:{
        borderBottom: '1px solid transparent',
        borderBottomColor: theme.palette.background.darkGray,
    },
    closeIcon: {
        position:'absolute',
        right:'15px',
        cursor: 'pointer',
        top: '14px'
    },
    assetsModalContent : {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 380px)',
        paddingRight:'5px'
    },
    modalHeader: {
        fontSize: '1.2146rem',
        fontWeight: '500'
    }
}))

const AddToEngagement = ({
    modalHeading,
    closeModal,
    selectedAlias,
    addAlias,
    variation = 'assets',
    onAssetRefresh,
}) => {
    const classes = useStyles()
    const { sendToast } = useToastContext()
    const [engagements, setEngagements] = useState([])
    const [engagementId, setEngagementId] = useState('')
    const [error, setError] = useState(false)
    const handleSubmit = async () => {
        if (!engagementId) {
            setError(true)
            return
        }
        addAlias(engagementId, selectedAlias)
        closeModal()
        //sendToast(response.msg && "Asset Uploaded Succesfully")
    }
    const handleChange = (event) => {
        setEngagementId(event.target.value)
    }
    useEffect(() => {
        getAllEngagements()
            .then((response) => {
                setEngagements(response.data)
            })
            .catch((err) => {
                console.log('getAllEngagements', err)
                sendToast(
                    `Error getting engagements: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Dialog
            open={true}
            maxWidth="sm"
        >
            <DialogTitle className={classes.modalTitle}>
                <Typography className={classes.modalHeader} gutterBottom>Select Engagements</Typography>
                    <IconButton className={classes.closeIcon} size='small'  onClick={closeModal} >
                <CloseIcon />
                </IconButton>
                </DialogTitle>
            <Box
                className={clsx('CreateAsset', classes.root)}
            >
                <Paper>
                    <CardContent>
                        <div className={classes.assetsModalContent}>
                       
                        <Box pt={5} p={5}>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                error={error}
                            >
                                <InputLabel id="selectEngagementLabel">
                                    Engagements
                                </InputLabel>
                                <Select
                                    labelId="selectEngagementLabel"
                                    id="selectEngagement"
                                    size="medium"
                                    MenuProps={{
                                        classes: { paper: classes.select },
                                    }}
                                    //  value={age}
                                    onChange={handleChange}
                                    label="Age"
                                >
                                    {engagements?.map((_e) => (
                                        <MenuItem value={_e.id}>
                                            {_e.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {error && (
                                    <FormHelperText>*Required</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        </div>
                        <Box mt={5} justifyContent="right" display="flex">
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                align="center"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Box>
                    </CardContent>
                </Paper>
            </Box>
        </Dialog>
    )
}

AddToEngagement.propTypes = {
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    formFields: PropTypes.array,
    modalHeading: PropTypes.string,
    variation: PropTypes.string,
}

AddToEngagement.defaultProps = {}

export default AddToEngagement
