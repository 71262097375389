import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, colors, makeStyles } from '@material-ui/core'

import {
    RadialBarChart,
    RadialBar,
    PolarAngleAxis,
    Tooltip,
    Cell,
} from 'recharts'
import { CRITICALITY_COLORS } from 'src/constants'
const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        width: '210px',
        height: '210px',
        top: '80px',
        left: '85px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
    },
    chart: {
        display: 'flex',
        position: 'relative',
        width: '380px',
        height: '380px',
        borderRadius: '50%',
    },
    textState: {
        display: 'flex',
        fontSize: '29px',
        textTransform: 'lowercase',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '30px',
    },
    tooltip: {
        borderRadius: '12px',
        padding: '5px',
        backgroundColor: theme.palette.background.tooltip,
    },
    tooltipHeader: {
        textAlign: 'center',
        margin: '0 0 5px 0',
    },
}))

const DiscoverChart = ({
    minScoreLabel,
    labeledCategories,
    setActiveCategory,
}) => {
    const classes = useStyles()
    const [activeComponent, setActiveComponent] = useState({
        name: '',
        score: '',
    })

    useEffect(() => {
        setActiveCategory(activeComponent.name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeComponent])

    const [attackScoreValue, detectScoreValue, validateScoreValue] = labeledCategories
    const defaultGraphData = [{
        count: 0,
        criticality: "INFORMATIONAL"
    }];
    const data = [
        {
            name: 'VALIDATE',
            value: validateScoreValue.score || 0,
            fill: validateScoreValue.color,
            graphData: validateScoreValue.graphData || defaultGraphData,
        },
        {
            name: 'DETECT',
            value: detectScoreValue.score || 0,
            fill: detectScoreValue.color,
            graphData: detectScoreValue.graphData || defaultGraphData,
        },
        {
            name: 'ATTACK',
            value: attackScoreValue.score || 0,
            fill: attackScoreValue.color,
            graphData: attackScoreValue.graphData || defaultGraphData,
        },
    ].map((dataElement) => {
        const colorsForCategory = dataElement.graphData.flatMap((dataItem) => {
            const { count, criticality } = dataItem
            const foundColor =
                CRITICALITY_COLORS.find((color) => color.label === criticality)
                    ?.value || ''
            return { count: count, color: foundColor, label: criticality }
        })
        dataElement.countData = colorsForCategory
        return {
            ...dataElement,
            active: activeComponent.name === dataElement.name,
        }
    })


    const CustomTooltip = ({ active, payload }) => {
        {
            if (active && payload && payload.length) {
                const data = payload[0]?.payload
                return (
                    <div className={classes.tooltip}>
                        <p
                            className={classes.tooltipHeader}
                        >{`${data.name} : ${data.value}%`}</p>
                        {data.countData.map((data, index) => {
                            return (
                                data.count > 0 ?? <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            backgroundColor: data.color,
                                            marginRight: '5px',
                                            borderRadius: '50%',
                                        }}
                                    />
                                    <span>{`${data.label} : ${data.count}`}</span>
                                </div>
                            )
                        })}
                    </div>
                )
            }
        }
        return null
    }
    return (
        <Box className={classes.chart}>
            <svg style={{ position: 'absolute' }}>
                <defs>
                    <filter id="shadow">
                        <feDropShadow
                            dx="0"
                            dy="5"
                            stdDeviation="5"
                            floodColor={colors.blueGrey[500]}
                            floodOpacity="0.2"
                        >
                            <animate
                                attributeName="stdDeviation"
                                values="0;5;0"
                                dur="2s"
                            />
                        </feDropShadow>
                    </filter>
                </defs>
            </svg>
            <Box className={classes.icon}>
                <img
                    src={minScoreLabel.illustration}
                    alt={minScoreLabel.name}
                    style={{ width: '25px' }}
                />
                <Typography className={classes.textState}>
                    {minScoreLabel.name}
                </Typography>
            </Box>

            <RadialBarChart
                innerRadius={90}
                outerRadius={205}
                barSize={15}
                width={380}
                height={380}
                startAngle={90}
                endAngle={-270}
                data={data}
            >
                <RadialBar
                    dataKey="value"
                    cornerRadius={15}
                    animationDuration={1000}
                    animation={'ease-in'}
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            style={{ transition: 'fill 1s' }}
                        />
                    ))}
                </RadialBar>
                <PolarAngleAxis
                    domain={[0, 100]}
                    tickLine={false}
                    tick={false}
                    type={'number'}
                />
                <Tooltip
                    cursor={false}
                    animationDuration={500}
                    offset={10}
                    labelStyle={{ display: 'none' }}
                    content={<CustomTooltip />}
                />
            </RadialBarChart>
        </Box>
    )
}

DiscoverChart.defaultProps = {
    labeledCategories: [],
}

DiscoverChart.propTypes = {
    labeledCategories: PropTypes.array.isRequired,
    minScoreLabel: PropTypes.object,
}

export default DiscoverChart
