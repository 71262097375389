import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'
import { UserProvider } from 'src/context/UserContext'


const AuthGuard = ({ children }) => {
    const { isAuthenticated } = useAuth()

    if (!isAuthenticated) {
        return <Redirect to='/' />
    }
    return (
        <UserProvider>
            {children}
        </UserProvider>
    )
}

AuthGuard.propTypes = {
    children: PropTypes.node,
}

export default AuthGuard
