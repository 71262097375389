import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'

const GuestGuard = ({ children }) => {
    const { isAuthenticated } = useAuth()

    // ASDR-1308
    // if (isAuthenticated && (!isEulaAccepted)){
    //     return <Redirect to="/eula" />
    // }

    if (isAuthenticated) {
        return <Redirect to="/" />
    }

    return <>{children}</>
}

GuestGuard.propTypes = {
    children: PropTypes.node,
}

export default GuestGuard
