const { Tooltip, IconButton } = require("@material-ui/core")

export const TableButton = (props) => {
    return (
        <Tooltip title={props.tooltipTitle} className={props.className}>
            <IconButton onClick={props.onClick} size={props.size}>
                {props.children}
            </IconButton>
        </Tooltip>
    )
}