import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useToastContext } from '../context/ToastContext';

const useStyles = makeStyles(() => ({
    main: {
        position: "fixed",
        top: "100px",
        right: "10px",
        maxHeight: "90vh",
        zIndex: "9999",
    },
    alertContainer: {
        marginBottom: "15px",
    }
}));

const ToastContent = ({
    id,
    message,
    severity,
    autoHideDuration,
    onClose,
}) => {
    const styles = useStyles();
    const { removeToast } = useToastContext();

    useEffect(() => {
        if (autoHideDuration !== false) {
            const timer = setTimeout(() => {
                removeToast(id);
            }, autoHideDuration);
            return () => clearTimeout(timer);
        }
    }, [autoHideDuration, id, onClose, removeToast]);

    return (
        <div key={id} className={styles.alertContainer}>
            <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
                <span>{message}</span>
            </Alert>
        </div>
    );
};

export default function Toast({ toast }) {
    const styles = useStyles();

    return (
        <div className={styles.main}>
            {toast.map(t => {
                return (
                    <ToastContent
                        id={t.id}
                        key={t.id}
                        message={t.content.message}
                        severity={t.content.severity || "success"}
                        autoHideDuration={t.content.autoHideDuration}
                    />
                )
            })}
        </div>
    );
}
