import React, { useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Button, Collapse, ListItem, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '5px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px !important',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
        width: '35px',
    },
    title: {
        marginRight: 'auto',
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
}))

const NavItem = ({
    children,
    className,
    depth,
    disabled,
    href,
    target,
    icon: Icon,
    info: Info,
    open: openProp,
    title,
    external,
    onMobileClose,
    ...rest
}) => {
    const classes = useStyles()
    const [open, setOpen] = useState(openProp)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    let paddingLeft = 8

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth
    }

    const style = { paddingLeft }

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}
            >
                <Button
                    className={classes.button}
                    onClick={handleToggle}
                    style={style}
                >
                    {Icon && <Icon className={classes.icon} />}
                    <span className={classes.title}>{title}</span>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        )
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}
        >
            {external ? (
                <Button
                    activeclassname={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    style={style}
                    href={href}
                    rel="noopener"
                    target={target}
                    disabled={disabled}
                    onClick={onMobileClose}
                >
                    {Icon && <Icon className={classes.icon} />}
                    <span className={classes.title}>{title}</span>
                    {Info && <Info />}
                </Button>
            ) : (
                <Button
                    activeclassname={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    exact
                    style={style}
                    to={href}
                    disabled={disabled}
                    onClick={onMobileClose}
                >
                    {Icon && <Icon className={classes.icon} />}
                    <span className={classes.title}>{title}</span>
                    {Info && <Info />}
                </Button>
            )}
        </ListItem>
    )
}

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    external: PropTypes.bool,
    target: PropTypes.string
}

NavItem.defaultProps = {
    open: false,
    external: false,
}

export default NavItem
