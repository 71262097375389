import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, Paper, makeStyles } from '@material-ui/core'
import { minBy } from 'lodash'
import { NavLink } from 'react-router-dom'
import Heading from './Heading'
import DiscoverChart from './Chart'
import Card from './CategoryCard'
import { getScoreCategoryByValue } from './util'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '900px',
        height: 'calc(100vh - 100px)',
        overflow: 'auto',
        margin: '-10px 20px 0px 25px'

    },
    paper: {
        // height: '950px',
        minHeight: 'calc(100vh - 110px)',
    },
    daysLabelContainer: {
        position: 'absolute',
        top: '80px',
        right: '90px',
        height: '28px',
        width: '118px',
        display: 'flex',
    },
    heading: {
        paddingTop: '60px',
        minWidth: '450px',
    },
    scoreContainer: {
        height: '393px',
        minWidth: '400px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    chart: {
        margin: '0',
        minWidth: '400px',
    },
    cardContainer: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '0 min(20px, 86px)',
    },
}))

const RTaaS = ({ cloud, external, internal, loadingComplete }) => {
    const classes = useStyles()
    const [activeCategory, setActiveCategory] = useState('')

    const minimumScoredCategory = minBy(
        [cloud, external, internal],
        (category) => {
            return category.score
        }
    )
    const minScoreLabel = {
        ...minimumScoredCategory,
        ...getScoreCategoryByValue(
            minimumScoredCategory ? minimumScoredCategory.score : 100
        ),
    }
    const labeledCategories = [external, internal, cloud].map((category) => ({
        ...category,
        ...getScoreCategoryByValue(category.score),
    }))

    return (
        <Box className={classes.root}>
            {/* <div className={classes.daysLabelContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    component={NavLink}
                    exact
                    to={'/'}
                >
                    Status
                </Button>
                <Button

                    size="small"
                    component={NavLink}
                    exact
                    to={'Attack'}
                >
                    Attack
                </Button>
                <Button component={NavLink} size="small" exact to={'Detect'}>
                    Detect
                </Button>
            </div> */}
            <Paper className={classes.paper}>

                {loadingComplete && (
                    <>
                        <Box className={classes.scoreContainer}>
                            <Box className={classes.heading}>
                                <Heading
                                    securityScore={minScoreLabel.score}
                                    securityLevel={minScoreLabel.value}
                                    name={minScoreLabel.name}
                                />
                            </Box>
                            {
                                <Box className={classes.chart}>
                                    <DiscoverChart
                                        minScoreLabel={minScoreLabel}
                                        labeledCategories={labeledCategories}
                                        setActiveCategory={setActiveCategory}
                                    />
                                </Box>
                            }
                        </Box>
                        <Divider variant="middle" />
                        <Box className={classes.cardContainer}>
                            {labeledCategories.map((category, index) => {
                                return (
                                    <Card
                                        {...category}
                                        key={index}
                                        active={
                                            category.category?.toUpperCase() ===
                                            activeCategory.toUpperCase()
                                        }
                                    />
                                )
                            })}
                        </Box>
                    </>
                )}
            </Paper>
        </Box>
    )
}

RTaaS.defaultProps = {
    cloud: {},
    external: {},
    internal: {},
    loadingComplete: false,
}

RTaaS.propTypes = {
    cloud: PropTypes.object,
    external: PropTypes.object,
    internal: PropTypes.object,
    loadingComplete: PropTypes.bool,
}

export default RTaaS
