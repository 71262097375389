import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RTaaS from './RTaaS';
import camelcaseKeys from 'camelcase-keys';
import { groupBy, meanBy, sum } from 'lodash';
import { useToastContext } from 'src/context/ToastContext';
import { getDashboardFindings } from 'src/actions/openapi';
import { ATTACK_SURFACES } from '../../constants'

function invertScoreValue(score, range = 10) {
    return Math.abs((score - range) * 10);
}

const filterByType = (entries, type) => entries.filter((entry) => entry.type === type);

const filterByAttackSurfaceAndType = (entries) =>
    entries.filter(
        (entry) =>
            Object.keys(ATTACK_SURFACES).includes(entry.attackSurface) &&
            ['FINDING', 'LEAD', 'TTP', 'CIS', 'INSIGHTS'].includes(entry.type)
    );

const processItem = (item, allArr, newfindings) => {
    const graphData = [];
    const outcomeArr = [];
    let totalFindings = 0;
    let totalOutcomes = 0; // Initialize totalOutcomes
    let totalEscalated = 0;

    allArr[item].forEach((entry) => {
        if (entry.escalated && !entry.outcome) {
            totalEscalated += entry.count;
        } else if (!entry.outcome) {
            totalFindings += entry.count;
        } else {
            outcomeArr.push({ score: entry.score });
            graphData.push({ 'count': entry.count, 'criticality': entry.criticality });
            totalOutcomes += entry.count; // Update totalOutcomes
        }

    });

    const meanScore = meanBy(outcomeArr, 'score').toFixed(2);
    newfindings[item] = {
        score: invertScoreValue(meanScore),
        totalFindings,
        totalOutcomes,
        totalEscalated,
        totalSignals:
            item === 'Attack'
                ? sum(allArr[item].map((entry) => (entry.type !== 'FINDING' ? entry.count : 0)))
                : totalFindings,
        category: item,
        graphData: graphData
    };
};

const RTaaSPath = () => {
    const { sendToast } = useToastContext();
    const [findings, setRTaaS] = useState({
        Detect: { category: 'Detect' },
        Validate: { category: 'Validate' },
        Attack: { category: 'Attack' },
    });
    const [loadingComplete, setLoadingStatus] = useState(false);

    useEffect(() => {
        async function asyncFetchDashboard() {
            try {
                const results = await getDashboardFindings();
                const transformedResults = results.map(camelcaseKeys);
                const allArr = {
                    Detect: filterByType(transformedResults, 'MDR'),
                    Validate: filterByType(transformedResults, 'VULNERABILITY'),
                    Attack: filterByAttackSurfaceAndType(transformedResults),
                };
                let newfindings = {};

                Object.keys(allArr).forEach((item) => {
                    const grouped = groupBy(allArr[item], 'attackSurface');
                    Object.keys(grouped).forEach(() => {
                        processItem(item, allArr, newfindings);
                    });
                });

                setRTaaS({ ...findings, ...newfindings });
                setLoadingStatus(true);
            } catch (err) {
                console.log('asyncFetchDashboard', err);
                sendToast(`Error getting dashboard data: ${err?.response?.data?.detail}`, 'error');
            }
        }

        asyncFetchDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RTaaS
            cloud={findings.Validate}
            external={findings.Attack}
            internal={findings.Detect}
            loadingComplete={loadingComplete}
        />
    );
};



RTaaSPath.propTypes = {
    findings: PropTypes.array.isRequired,
};

RTaaSPath.defaultProps = {
    findings: [],
};

export default RTaaSPath;