import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import ReportsList from 'src/views/Reports/ReportsList'

const Results = ({ className, ...rest }) => {
    return (
        <Box p={2} style={{position:"relative", top:"-10px", padding:'0 24px 24px'}}>
            <ReportsList></ReportsList>
        </Box>
    )
}

Results.propTypes = {
    className: PropTypes.string,
    reports: PropTypes.array.isRequired,
}

Results.defaultProps = {
    reports: [],
}

export default Results
