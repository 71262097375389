import { Box, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { useState } from 'react'
import { addCredentialsToEngagement, getCredentials } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import useLoader from 'src/hooks/useLoader'

const CredentialListing = ({
    engagementId,
    postAddToEngagement,
    title = 'Credentials',
    columns = [],
    options = {},
}) => {
    const [loading, showLoader, hideLoader] = useLoader()
    const { sendToast } = useToastContext()

    const [credentials, setCredentials] = useState([])
    const fetchData = () => {
        showLoader()
        getCredentials()
            .then((response) => {
                setCredentials(response)
            })
            .catch((err) => {
                console.log('getCredentials', err)
                sendToast(
                    `Error getting credentials: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                hideLoader()
            })
    }
    const handleTableInit = (action, tableState) => {
        fetchData()
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'filterChange':
            case 'changeRowsPerPage':
            case 'resetFilters':
            case 'search':
            case 'sort':
            case 'changePage': {
                fetchData()
            }
            /* falls through */  
            // no default 
        }
    }

    const handleAddCredentialToEngagement = (credentialId) => {
        const params = { engagementId, credentials: [credentialId] }
        addCredentialsToEngagement(params)
            .then((response) => {
                sendToast('Credential added to engagement')
                postAddToEngagement()
            })
            .catch((err) => {
                sendToast(
                    `Error adding credentials to engagement: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    const defaultOptions = {
        print: false,
        download: false,
        filter: false,
        sort: false,
        search: false,
        selectableRows: 'none',
        serverSide: true,
        customToolbar: () => {},
        onTableInit: handleTableInit,
        onTableChange: handleTableChange,
    }

    const defaultColumns = [
        {
            name: 'row_number',
            label: 'No.',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return <span>{dataIndex + 1}</span>
                },
            },
        },
        {
            name: 'value__name',
            label: 'Alias',
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return credentials[dataIndex].value.name
                },
            },
        },
        {
            name: 'value__type',
            label: 'Cloud provider',
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return credentials[dataIndex].value.type
                },
            },
        },
        {
            name: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => {
                                handleAddCredentialToEngagement(
                                    credentials[dataIndex].key
                                )
                            }}
                        >
                            <Add color="primary" fontSize="small"></Add>
                        </IconButton>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            {loading}
            <MUIDataTable
                title={title}
                data={credentials}
                columns={[...defaultColumns, ...columns]}
                options={{ ...defaultOptions, ...options }}
            />
        </Box>
    )
}
export { CredentialListing as default }
