import axios from 'axios'

export const requestSignedURL = async (fileId) => {
    return axios({
        url: `/files/${fileId}`,
        method: 'GET',
    })
        .then((response) => {
            return response.data.url
        })
        .catch((e) => {
            throw e
        })
}

export const downloadFile = async (fileId, fileName) => {
    return await requestSignedURL(fileId).then((response) => {
        const link = document.createElement('a')
        link.href = response
        link.setAttribute('download', `${fileName}.pdf`)
        document.body.appendChild(link)
        link.click()
    })
}
