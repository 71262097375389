import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik} from 'formik'
import {
    Box,
    Button,
    CardContent,
    CircularProgress,
    FormHelperText,
    FormControl,
    makeStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Paper,
    TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '572px',
        margin: '0 auto',
        width: '572px'
    },
    radioGroup: {
        flexDirection: 'row',
    },
    assetsModalContent : {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 380px)',
        paddingRight:'5px'
    }
}))

const CreateModal = ({
    initialFormPayload,
    modalHeading,
    onFormPost,
    closeModal,
    variation = 'assets',
}) => {
    const classes = useStyles()
    const [isAlertVisible, setAlertVisible] = useState(false)

    const scopeOptions =
        variation === 'assets'
            ? Yup.mixed()
                  .oneOf(['IP_ADDRESS', 'FQDN', 'CIDR'])
                  .required('Required')
            : Yup.string().required('Required')

    const validationSchema = Yup.object().shape({
        alias: Yup.string().required('Required'),
        scopeOptions,
    })

    return (
        <Formik
            initialValues={{
                ...initialFormPayload,
            }}
            validationSchema={validationSchema}
            onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
                setSubmitting(true)
                await onFormPost(values)
                    .then(() => {
                        setStatus({ success: true })
                        setSubmitting(false)
                        // resetForm()
                        closeModal()
                    })
                    .catch((err) => {
                        console.error(err)
                        setStatus({ success: false })
                        setErrors({ submit: err.message })
                        setSubmitting(false)
                        setAlertVisible(true)
                    })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <Box className={clsx('CreateAsset', classes.root)}>
                    <Paper>
                        <CardContent>
                        <div className={classes.assetsModalContent}></div>
                            {isAlertVisible && (
                                <Box mb={3}>
                                    <Alert
                                        onClose={() => setAlertVisible(false)}
                                        severity="error"
                                    >
                                        Failed to submit data
                                    </Alert>
                                </Box>
                            )}
                            {isSubmitting ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    my={5}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Box mt={3}>
                                    <form onSubmit={handleSubmit}>
                                    <div className={classes.assetsModalContent}>
                                        <Box>
                                            <Box margin={1}>
                                                {variation === 'assets' && (
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">
                                                            Asset Type
                                                        </FormLabel>
                                                        <RadioGroup
                                                            className={
                                                                classes.radioGroup
                                                            }
                                                        >
                                                            {[
                                                                {
                                                                    label: 'IP Address',
                                                                    value: 'IP_ADDRESS',
                                                                },
                                                                {
                                                                    label: 'Domain',
                                                                    value: 'FQDN',
                                                                },
                                                                {
                                                                    label: 'CIDR',
                                                                    value: 'CIDR',
                                                                },
                                                            ].map(
                                                                ({
                                                                    label,
                                                                    value,
                                                                }) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            name="scopeOptions"
                                                                            control={
                                                                                <Radio />
                                                                            }
                                                                            onBlur={
                                                                                handleBlur
                                                                            }
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            key={
                                                                                value
                                                                            }
                                                                            value={
                                                                                value
                                                                            }
                                                                            label={
                                                                                label
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            )}
                                                        </RadioGroup>
                                                    </FormControl>
                                                )}
                                            </Box>
                                            <Box mt={3}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.alias &&
                                                            errors.alias
                                                    )}
                                                    fullWidth
                                                    helpertext={
                                                        touched.alias &&
                                                        errors.alias
                                                    }
                                                    label="Asset Identifier"
                                                    name="alias"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.alias}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Box>

                                        {Boolean(
                                            touched.policy && errors.policy
                                        ) && (
                                            <FormHelperText error>
                                                {errors.policy}
                                            </FormHelperText>
                                        )}
                                        </div>
                                        <Box
                                            mt={3}
                                            justifyContent="center"
                                            display="flex"
                                        >
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                align="center"
                                            >
                                                Create
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                            )}
                        </CardContent>
                    </Paper>
                </Box>
            )}
        </Formik>
    )
}

CreateModal.propTypes = {
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    formFields: PropTypes.array,
    modalHeading: PropTypes.string,
    variation: PropTypes.string,
}

CreateModal.defaultProps = {}

export default CreateModal
