import React, { useState, useEffect } from 'react'
import Phishing from './Phishing'
import { useToastContext } from 'src/context/ToastContext'

const PhishingPath = () => {
    const [loadingComplete, setLoadingStatus] = useState(false)
    const { sendToast } = useToastContext()

    useEffect(() => {
        async function asyncFetchPhishing() {
            // const results = await getAllMetrics()
            // const transformedResults = results.map(camelcaseKeys)
            // setOSINT(transformedResults)
            setLoadingStatus(true)
        }
        asyncFetchPhishing().catch((err) => {
            console.log('asyncFetchPhishing', err)
            sendToast(
                `Error getting phishing data: ${err?.response?.data?.detail}`,
                'error'
            )
        })
    }, [sendToast])

    return <Phishing loadingComplete={loadingComplete} />
}

export default PhishingPath

PhishingPath.propTypes = {}

PhishingPath.defaultProps = {}
