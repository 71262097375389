import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Paper,
    Typography,
    colors,
    makeStyles,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CircularProgressWithLabel from '../../components/Progress'
import { getScoreCategoryByValue } from '../RTaaS/util'
//import { deleteSocialCampaign } from 'src/actions/openapi'
//import { useToastContext } from 'src/context/ToastContext'
import useLoader from 'src/hooks/useLoader'

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {
        width: '100%',
        minWidth: '180px',
        // height: '170px',
        position: 'relative',
        borderRadius: '12px',
        border: `1px solid ${colors.blueGrey[50]}`,
        marginBottom: '20px',
    },
    cardHeading: {
        '& .MuiCardHeader-content': {
            display: 'flex',
        },
        '& .MuiCardHeader-title': {
            fontSize: '1.35rem',
            fontWeight: '300',
        },
        '& .MuiCardHeader-subheader': {
            marginLeft: '7px',
            marginTop: 'auto',
            fontSize: '1.25rem',
            color: theme.palette.primary.violet,
        },
        '& .MuiCardHeader-action': {
            display: 'contents',
        },
    },
    cardSubheading: {
        display: 'contents',
        fontWeight: '800',
    },
    cardTextContainer: {
        marginTop: '30px',
    },
    viewButton: {
        position: 'absolute',
        bottom: '25px',
        left: 0,
        right: 0,
        margin: 'auto',
        width: '115px',
    },
    statItem: {
        fontSize: '0.85rem',
        fontWeight: '500',
        padding: '10px',
        border:`2px solid ${theme.palette.background.lightGray}`
    },
    gridItem: {
        minHeight: '5vh',
    },
    cardContent:{
        marginTop: '-32px'
    }
}))

const riskBorderMap = {
    Critical: '#6314FF',
    Poor: '#8F71F8',
    Okay: '#C9DE0E',
    Good: '#1A4E62',
    // Critical: colors.red[500],
    // Poor: colors.orange[500],
    // Okay: colors.amber[500],
    // Good: colors.green[500],
}

const CampaignCard = ({ campaign, fetchCampaignData }) => {
    const classes = useStyles()
    const [loading, showLoader, hideLoader] = useLoader()
    const [isMouseOver, setMouseOver] = useState(false)
    //const bull = <span className={classes.bullet}>•</span>
    const scoreLabel = getScoreCategoryByValue(campaign?.score || 90)
    //const { sendToast } = useToastContext()

    async function deleteCampaign(e) {
        showLoader()
        // const response = await deleteSocialCampaign(
        //     e.target.parentElement.dataset.campaign
        // ).catch((err) => {
        //     console.log('deleteSocialCampaign', err)
        //     sendToast(
        //         `Error deleting campaign: ${err?.response?.data?.detail}`,
        //         'error'
        //     )
        // })
        fetchCampaignData()
        hideLoader()
    }

    return (
        <Box>
            {loading}
            <Card
                className={classes.card}
                style={{
                    border: `2px solid ${
                        riskBorderMap[scoreLabel.name || 'Good']
                    }`,
                }}
                onMouseOver={(e) => {
                    if (!isMouseOver) {
                        setMouseOver(true)
                    }
                }}
                onMouseOut={() => {
                    setMouseOver(false)
                }}
                raised={isMouseOver}
                elevation={isMouseOver ? 20 : 2}
            >
                <CardHeader
                    action={
                        <>
                            <Button
                                variant="outlined"
                                startIcon={<EditIcon />}
                                style={{
                                    marginRight: '15px',
                                }}
                                size="small"
                                onClick={deleteCampaign}
                                data-campaign={campaign.id}
                            >
                                Delete
                            </Button>
                            {scoreLabel.name !== 'neutral' ? (
                                <Box display="flex" alignItems="center">
                                    <img
                                        src={scoreLabel.illustration}
                                        alt={scoreLabel.name}
                                    />
                                    <Typography
                                        style={{
                                            marginLeft: '4px',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {scoreLabel.name}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box height={'25px'}></Box>
                            )}
                        </>
                    }
                    title={campaign.target_group}
                    subheader={campaign.started_on}
                    className={classes.cardHeading}
                />
                <CardContent>
                    <Grid container spacing={3} className={classes.cardContent}>
                        <Grid item xs >
                            <Paper className={classes.statItem} elevation={3}>
                                <Grid
                                    container
                                    item
                                    spacing={0}
                                    alignItems="center"
                                    className={classes.gridItem}
                                    direction="row"
                                >
                                    <Grid item xs={9}>
                                        Sent Emails
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <CircularProgressWithLabel
                                            variant="determinate"
                                            value={100}
                                            label={campaign?.sent_emails || 0}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.statItem} elevation={3}>
                                <Grid
                                    container
                                    item
                                    spacing={0}
                                    alignItems="center"
                                    className={classes.gridItem}
                                    
                                    direction="row"
                                >
                                    <Grid item xs={9}>
                                        Delivered Emails
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <CircularProgressWithLabel
                                            variant="determinate"
                                            value={
                                                (campaign?.delivered_emails /
                                                    campaign?.sent_emails) *
                                                100
                                            }
                                            label={
                                                campaign?.delivered_emails || 0
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.statItem} elevation={3}>
                                <Grid
                                    container
                                    item
                                    spacing={0}
                                    alignItems="center"
                                    className={classes.gridItem}                                    
                                    direction="row"
                                >
                                    <Grid item xs={9}>
                                        Opened Emails (1x1 Pixel Image Loaded)
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <CircularProgressWithLabel
                                            variant="determinate"
                                            value={
                                                (campaign?.opened_emails /
                                                    campaign?.sent_emails) *
                                                100
                                            }
                                            label={campaign?.opened_emails || 0}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.statItem} elevation={3}>
                                <Grid
                                    container
                                    item
                                    spacing={0}
                                    alignItems="center"
                                    className={classes.gridItem}                                    
                                    direction="row"
                                >
                                    <Grid item xs={9}>
                                        Clicked Link
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <CircularProgressWithLabel
                                            variant="determinate"
                                            value={
                                                (campaign?.clicked_link /
                                                    campaign?.sent_emails) *
                                                100
                                            }
                                            label={campaign?.clicked_link || 0}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.statItem} elevation={3}>
                                <Grid
                                    container
                                    item
                                    spacing={0}
                                    alignItems="center"
                                    className={classes.gridItem}                                    
                                    direction="row"
                                >
                                    <Grid item xs={9}>
                                        Compromised Credentials
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <CircularProgressWithLabel
                                            variant="determinate"
                                            value={
                                                (campaign?.credentials /
                                                    campaign?.sent_emails) *
                                                100
                                            }
                                            label={campaign?.credentials || 0}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}

CampaignCard.defaultProps = {}

CampaignCard.propTypes = {
    campaign: PropTypes.object.isRequired,
}

export default CampaignCard
