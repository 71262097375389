import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Box,
    Card,
    Divider,
    makeStyles,
    Button,
} from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { Delete } from '@material-ui/icons'
import AddToEngagement from './AddToEngagement'


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    deleteButton: {
        height: '30px',
        width: '30px',
        padding: 0,
        marginRight: '10px',
    },
    grey : {
        background : theme.palette.background.darkGray
    }
}))

const AssetsTable = ({
    columnNames,
    addToEngage,
    tableValues,
    onFindingSelect,
    onDeleteAction,
    onSubmit,
    className,
    ...rest
}) => {
    const classes = useStyles()
    const [deleteFlag, SetDeleteFlag] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState(new Set())
    const [selectedIndex, setSelectedIndex] = useState(new Set())
    const handleDeleteAsset = async (ids) => {
        const response = await onDeleteAction(Array.from(selectedRows)); 
        if(response?.status===200 && response?.data?.msg === "Task Submitted"){
            SetDeleteFlag(Array.from(selectedRows));
        }
        setSelectedRows(new Set()); 
        setSelectedIndex(new Set()) 
        
    }
    const CustomSelectToolbar = () => {
        return (
            <Box paddingRight={2}>
              { !addToEngage && <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    align="center"
                    //startIcon={<GetApp />}
                    onClick={() => setModalOpen(!modalOpen)}
                    style={{ float: "right" }}
                >
                    Add to Engagement
                </Button>
    }
                <Button
                    color="primary"
                    size="medium"
                    onClick={handleDeleteAsset}
                >
                    <Delete fontSize="medium"></Delete>
                </Button>
                {/* <IconButton >
                    <CloudDownload fontSize="medium"></CloudDownload>
                </IconButton> */}
            </Box>
        )
    }
    const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {

        if (allRowsSelected.length === tableValues.length) {
            allRowsSelected.forEach((_row, index) => { selectedRows.add(tableValues[_row.index]?.id); selectedIndex.add(index) })
        } else if (allRowsSelected.length === 0) {
            setSelectedRows(new Set())
            setSelectedIndex(new Set())
        }
        else {
            const aliasId = tableValues[currentRowsSelected[0]?.index]?.id
            if (aliasId) {
                if (selectedRows.has(aliasId)) {
                    selectedRows.delete(aliasId)
                    selectedIndex.delete(currentRowsSelected[0]?.index)
                } else {
                    selectedRows.add(aliasId)
                    selectedIndex.add(currentRowsSelected[0]?.index)
                }
            } else {
                setSelectedRows(new Set())
                setSelectedIndex(new Set())
            }
        }

    }
    const defaultOptions = {
        responsive: "standard",
        tableBodyHeight: 'calc(100vh - 312px)',
        print: false,
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
       // selectableRowsHideCheckboxes: addToEngage,
        customToolbarSelect: CustomSelectToolbar,
        onRowSelectionChange: handleRowSelectionChange,
    };
    const isRowDeleted = (dataIndex) => {
        let status = true;
        if(deleteFlag.length){
            let _filteredObj = tableValues[dataIndex];
            status= !deleteFlag.includes(_filteredObj.id)
        }
        return status;
        }
    const closeModal = () => {
        setModalOpen(false);
    }
    return (
        <Box className={clsx(classes.root, className)} {...rest}>
            {modalOpen &&
                <AddToEngagement
                    modalHeading={'Select Engagements'}
                    selectedAlias={selectedRows}
                    closeModal={closeModal}
                    addAlias={onSubmit}
                />}
            <Card>
                <Divider />
                <PerfectScrollbar>
                    <Box>

                        <MUIDataTable
                            data={tableValues}
                            columns={[...columnNames]}
                            options={{ ...defaultOptions, 
                                    rowsSelected: Array.from(selectedIndex), 
                                    isRowSelectable: isRowDeleted,
                                    setRowProps: (row, dataIndex, rowIndex) => {
                                        let _trow = tableValues[rowIndex]
                                        return {
                                          className: clsx({
                                            [classes.grey]: deleteFlag.includes(_trow.id),
                                          })
                                        };
                                      },
                                 }}
                        />
                    </Box>
                </PerfectScrollbar>
            </Card>
        </Box>
    )
}

AssetsTable.propTypes = {
    className: PropTypes.string,
    columnNames: PropTypes.array.isRequired,
    tableValues: PropTypes.array,
}

AssetsTable.defaultProps = {
    tableValues: [],
    onDeleteAction: () => { },
}

export default AssetsTable
