import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Box,
    CardContent,
    FormControl,
    makeStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from '@material-ui/core'

import AWSEntry from './AWS'
import AzureEntry from './Azure'
import GCPEntry from './GCP'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '760px',
        margin: '0 auto',
    },
    radioGroup: {
        flexDirection: 'row',
    },
    formTextField: {
        margin: `${theme.spacing(1)}px 0`,
    },
}))

const AWS = 'AWS'
const AZURE = 'AZURE'
const GCP = 'GCP'

const CreateModal = ({
    onFormPost,
    closeModal,
}) => {
    const classes = useStyles()
    //const [isAlertVisible, setAlertVisible] = useState(false)
    const [activeCloudPlatform, setActiveCloudPlatform] = useState(AWS)

    return (
        <Box className={clsx('CreateAsset', classes.root)}>
           
                <CardContent>
                    {/* <Box pt={6} pb={6}>
                        <Typography
                            variant="h2"
                            color="textPrimary"
                            align="center"
                        >
                            {modalHeading}
                        </Typography>
                    </Box>

                    <Divider /> */}
                    
                        <>
                            <Box >
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Cloud Platform
                                    </FormLabel>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        defaultValue={AWS}
                                    >
                                        {[
                                            {
                                                label: 'Amazon Web Services',
                                                value: AWS,
                                            },
                                            {
                                                label: 'Microsoft Azure',
                                                value: AZURE,
                                            },
                                            {
                                                label: 'Google Cloud Platform',
                                                value: GCP,
                                            },
                                        ].map(({ label, value, disabled }, index) => {
                                            return (
                                                <FormControlLabel
                                                    name="cloudPlatform"
                                                    disabled={disabled}
                                                    control={<Radio />}
                                                    onChange={(e) => {
                                                        setActiveCloudPlatform(
                                                            e.target.value
                                                        )
                                                    }}
                                                    // onChange={handleChange}
                                                    key={index}
                                                    value={value}
                                                    label={label}
                                                />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            {activeCloudPlatform === AWS && (
                                <AWSEntry
                                    onFormPost={onFormPost}
                                    closeModal={closeModal}
                                />
                            )}
                            {activeCloudPlatform === AZURE && (
                                <AzureEntry
                                    onFormPost={onFormPost}
                                    closeModal={closeModal}
                                />
                            )}
                            {activeCloudPlatform === GCP && (
                                <GCPEntry
                                    onFormPost={onFormPost}
                                    closeModal={closeModal}
                                />
                            )}
                        
                    </>
                </CardContent>
            
        </Box>
    )
}

CreateModal.propTypes = {
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    formFields: PropTypes.array,
    modalHeading: PropTypes.string,
}

CreateModal.defaultProps = {}

export default CreateModal
