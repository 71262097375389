import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Avatar,
    Box,
    Button,
    Paper,
    Typography,
    makeStyles,
} from '@material-ui/core'
import { Key as KeyIcon } from 'react-feather'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'
import { partition } from 'lodash'

import Credentials from './Credentials'
import Scopes from './Scopes'
import Emails from './Emails'
import { AlternateEmail } from '@material-ui/icons'

const useCustomStepIconStyles = makeStyles((theme) => ({
    root: { backgroundColor: theme.palette.primary.main },
    active: {
        boxShadow: theme.shadows[10],
    },
}))

const CustomStepIcon = ({ active, Icon }) => {
    const classes = useCustomStepIconStyles()

    return (
        <Avatar
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            <Icon size="20" />
        </Avatar>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3),
        minWidth: '900px',
        position: 'relative',
        top: '-10px',
        marginBottom: theme.spacing(1)
    },
    stepperContainer: {
        display: 'flex',
        textAlign: 'center',
        height: '50px',
        width: '200px',
        marginLeft: '-1px',
    },
    stepperContainerActive: {
        borderRadius: '6px 6px 0 0',
        border: '1px solid rgb(63 63 68 / 15%)',
        borderBottom: 'none',
    },
    stepperContainerInActive: {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        borderBottom: 'none',
    },

    buttonText: {
        textTransform: 'capitalize',
        alignSelf: 'center',
        fontWeight: '600'
    },
}))

const Assets = ({
    credentials,
    assets,
    onAssetDelete,
    onAssetCreate,
    onCredentialDelete,
    onCredentialCreate,
    onRefreshAsset,
    addAssetToEngage,
    addCredToEngage,
}) => {
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('credentials')

    const [emailAddresses, filteredAssets] = partition(assets, {
        assetType: 'EMAIL_ADDRESS',
    })
    const tableSectionButton = [
        { category: 'credentials', title: 'Credentials', icon: KeyIcon },
        { category: 'assets', title: 'Assets', icon: CloudQueueIcon },
        { category: 'emails', title: 'Email Addresses', icon: AlternateEmail },
    ]

    return (
        <Box className={classes.root}>
            {/* <Typography variant="h3">
                Manage Credentials, Assets, and Email Addresses
            </Typography> */}

            <Box display="flex" mt={3}>
                {tableSectionButton.map(({ category, title, icon }, index) => (
                    <Paper
                        className={clsx(classes.stepperContainer, {
                            [classes['stepperContainerActive']]:
                                activeTab === category,
                            [classes['stepperContainerInActive']]:
                                activeTab !== category,
                        })}
                        elevation={0}
                        key={index}
                    >
                        <Button
                            style={{ height: '100%', width: '100%' }}
                            onClick={() => {
                                setActiveTab(category)
                                if (
                                    category === 'credentials' &&
                                    activeTab !== 'credentials'
                                ) {
                                    onRefreshAsset()
                                }
                            }}
                            
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                style={{ width: '100%' }}
                                size="small"
                            >
                                <CustomStepIcon
                                    active={activeTab === category}
                                    Icon={icon}
                                    size="20"
                                />
                                <Typography className={classes.buttonText}>
                                    {title}
                                </Typography>
                            </Box>
                        </Button>
                    </Paper>
                ))}
            </Box>
            {activeTab === 'credentials' && (
                <Credentials
                    addToEngage={false}
                    credentials={credentials}
                    onAssetRefresh={onRefreshAsset}
                    onCredentialDelete={onCredentialDelete}
                    onCredentialCreate={onCredentialCreate}
                    onAddCredToEngage={addCredToEngage}
                />
            )}
            {activeTab === 'assets' && (
                <Scopes
                    addToEngage={false}
                    assets={filteredAssets}
                    onAssetDelete={onAssetDelete}
                    onAssetCreate={onAssetCreate}
                    onAssetRefresh={onRefreshAsset}
                    onAddAssetToEngage={addAssetToEngage}
                />
            )}
            {activeTab === 'emails' && (
                <Emails
                    addToEngage={true}
                    assets={emailAddresses}
                    onAssetDelete={onAssetDelete}
                    onAssetCreate={onAssetCreate}
                    onAssetRefresh={onRefreshAsset}
                />
            )}
        </Box>
    )
}

export default Assets

Assets.propTypes = {
    credentials: PropTypes.array.isRequired,
    onAssetDelete: PropTypes.func.isRequired,
    onAssetCreate: PropTypes.func.isRequired,
    onCredentialDelete: PropTypes.func.isRequired,
    onCredentialCreate: PropTypes.func.isRequired,
}

Assets.defaultProps = {
    credentials: [],
}
