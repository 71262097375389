import React, { Fragment, useState } from 'react'
import {
    Box,
    Button,
    Divider,
    Fade,
    Modal,
    TextField,
    Typography,
    makeStyles,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@material-ui/core'
import { REPORT_TYPES } from 'src/constants'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useToastContext } from 'src/context/ToastContext'
import { useOrganization } from 'src/actions/customHooks'
import { postReport } from 'src/actions/openapi'
import { has_allowed_extension } from 'src/utils/common'
import useLoader from 'src/hooks/useLoader'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: 'white',
        },
        modal: {
            width: '675px',
            height: `calc(825px + ${theme.spacing(6)}px + ${theme.spacing(
                4
            )}px)`,
            margin: `${theme.spacing(2)}px auto`,
        },
        modalContent: { backgroundColor: theme.palette.background.default },
        modalForm: {
            padding: theme.spacing(2),
        },
        modalHeader: {
            padding: theme.spacing(3),
            alignItems: 'centered',
        },
        textField: {
            margin: theme.spacing(2),
        },
        userModalButton: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
        },
        fileUpload: {
            '& input': {
                marginTop: '-1% !important',
            },
        },
    }
})

export default function UploadReport({
    fetchData,
    onClose,
    onUserCreate,
    active,
}) {
    const classes = useStyles()
    const [loading, showLoader, hideLoader] = useLoader()
    const { sendToast } = useToastContext()
    const organization = useOrganization()
    const allowed_formats = ['pdf', 'doc', 'docx', 'csv', 'jpg', 'png', 'zip']
    const initialUserDetails = {
        title: '',
        reviewed: '',
        type: '',
        file: '',
    }
    const formValidationSchema = Yup.object().shape({
        title: Yup.string().required('Required').max(255, 'Title must be at most 255 characters'),
        reviewed: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        file: Yup.mixed()
            .required('Required')
            .test(
                'checkFileExtension',
                `Only ${allowed_formats.join(', ')} formats are allowed`,
                function (value) {
                    if (value) {
                        return has_allowed_extension(
                            value?.name,
                            allowed_formats
                        )
                    }
                    return true
                }
            ),
    })

    const [titleLength, setTitleLength] = useState(0)

    const handleTitleChange = (event) => {
        setTitleLength(event.target.value.length)
    }

    return (
        <Fragment>
            {loading}
            <Modal className={classes.modal} open={active} closeAfterTransition>
                <Fade in={active} timeout={500}>
                    <Formik
                        initialValues={initialUserDetails}
                        validationSchema={formValidationSchema}
                        onSubmit={async (
                            values,
                            { resetForm, setErrors, setStatus, setSubmitting }
                        ) => {
                            setSubmitting(true)
                            showLoader()
                            await postReport(values, organization.id)
                                .then((response) => {
                                    resetForm()
                                    if (response.status === 200) {
                                        sendToast(
                                            'Report Uploaded Successfully'
                                        )
                                        onClose()
                                        fetchData()
                                    } else {
                                        sendToast(response.data.detail, 'error')
                                    }
                                })
                                .catch((error) => {
                                    if (error.response.status === 415) {
                                        sendToast(
                                            error.response.data.detail,
                                            'error'
                                        )
                                    } else {
                                        sendToast(
                                            `Error uploading report: ${error?.response?.data.detail}`,
                                            'error'
                                        )
                                    }
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                    hideLoader()
                                })
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            touched,
                            values,
                        }) => (
                            <Box
                                style={{ outline: 'none' }}
                                className={classes.modalContent}
                            >
                                <form onSubmit={handleSubmit}>
                                    <Box className={classes.modalHeader}>
                                        <Typography
                                            variant="h2"
                                            color="textPrimary"
                                            width="fit-content"
                                        >
                                            Upload Report
                                        </Typography>
                                    </Box>

                                    <Divider width={'96%'} />
                                    <Box className={classes.modalForm}>
                                        <Box display="flex">
                                            <TextField
                                                className={classes.textField}
                                                error={Boolean(
                                                    touched.title &&
                                                    errors.title
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.title &&
                                                    errors.title
                                                }
                                                label={`Title (${titleLength}/255)`}
                                                name="title"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleTitleChange(e)
                                                }}
                                                value={values.title}
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box display="flex">
                                            <FormControl
                                                className={classes.textField}
                                                variant="outlined"
                                                error={Boolean(
                                                    touched.type && errors.type
                                                )}
                                                fullWidth
                                            >
                                                <InputLabel id="reprtTitle">
                                                    Report Types
                                                </InputLabel>

                                                <Select
                                                    labelId="reprtTitle"
                                                    id="ReportType"
                                                    name="type"
                                                    // variant="outlined"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.type}
                                                    label={'Report Types'}
                                                >
                                                    {REPORT_TYPES.map(
                                                        (_type, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={_type}
                                                            >
                                                                {_type}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                                <FormHelperText>
                                                    {touched.type &&
                                                        errors.type}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl
                                                className={classes.textField}
                                                variant="outlined"
                                                error={Boolean(
                                                    touched.reviewed &&
                                                    errors.reviewed
                                                )}
                                                fullWidth
                                            >
                                                <InputLabel id="ReviewedTitle">
                                                    Reviewed
                                                </InputLabel>
                                                <Select
                                                    labelId="ReviewedTitle"
                                                    id="Reviewed"
                                                    name="reviewed"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.reviewed}
                                                    label="Reviewed"
                                                >
                                                    <MenuItem value={true}>
                                                        Yes
                                                    </MenuItem>
                                                    <MenuItem value={false}>
                                                        No
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    {touched.type &&
                                                        errors.type}
                                                </FormHelperText>
                                            </FormControl>
                                        </Box>
                                        <Box display={'flex'}>
                                            <FormControl
                                                className={classes.textField}
                                                variant="outlined"
                                                fullWidth
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    id="file"
                                                    name="file"
                                                    type="file"
                                                    className={
                                                        classes.fileUpload
                                                    }
                                                    error={Boolean(
                                                        touched.file &&
                                                        errors.file
                                                    )}
                                                    helperText={
                                                        touched.file &&
                                                        errors.file
                                                    }
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            'file',
                                                            event.currentTarget
                                                                .files[0]
                                                        )
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                        <Box
                                            className={classes.userModalButton}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    onClose(null)
                                                }}
                                                variant="contained"
                                                size="large"
                                            >
                                                Cancel
                                            </Button>{' '}
                                            <Button
                                                color="primary"
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        )}
                    </Formik>
                </Fade>
            </Modal>
        </Fragment>
    )
}
