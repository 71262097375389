import {
    Box,
    Divider,
    Paper,
    Typography,
    makeStyles,
    Modal,
    Button,
    Tabs,
    Tab,
    FormControl,
    RadioGroup,
    CardContent,
    FormLabel,
    Radio,
    FormControlLabel,
    IconButton,
} from '@material-ui/core'
import clsx from 'clsx'
import { Add, Delete } from '@material-ui/icons'

import CredentialListing from '../Credential/CredentialListing'
import { useContext, useState } from 'react'
import {
    createAWSCredential,
    createAZURECredential,
    createGCPCredential,
    removeCredentialsFromEngagement,
} from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import MUIDataTable from 'mui-datatables'
import AWSEntry from '../Assets/CreateCredentialModal/AWS'
import AzureEntry from '../Assets/CreateCredentialModal/Azure'
import GCPEntry from '../Assets/CreateCredentialModal/GCP'
import EngagementContext from './EngagementContext'
import { truncateString } from 'src/utils/common'

// const theme = createTheme({
//     overrides: {
//         MUIDataTableToolbar: {
//             titleText: {
//                 fontSize: '20px',
//             },
//         },
//     },
// })

const CreateCredential = ({
    initialFormPayload,
    modalHeading,
    onFormPost,
    closeModal,
}) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: '660px',
            // height: "500px",
            // overflow: "scroll",
            // margin: '50px auto 0',
        },
        radioGroup: {
            flexDirection: 'row',
        },
        formTextField: {
            margin: `${theme.spacing(1)}px 0`,
        },
    }))

    const AWS = 'AWS'
    const AZURE = 'AZURE'
    const GCP = 'GCP'

    const classes = useStyles()
    //const [isAlertVisible, setAlertVisible] = useState(false)
    const [activeCloudPlatform, setActiveCloudPlatform] = useState(AWS)

    return (
        <Box className={clsx('CreateAsset', classes.root)}>
            <Paper>
                <CardContent>
                    <Box mt={3}>
                        <Box>
                            <Box margin={1}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Cloud Platform
                                    </FormLabel>
                                    <RadioGroup
                                        className={classes.radioGroup}
                                        defaultValue={AWS}
                                    >
                                        {[
                                            {
                                                label: 'Amazon Web Services',
                                                value: AWS,
                                            },
                                            {
                                                label: 'Microsoft Azure',
                                                value: AZURE,
                                            },
                                            {
                                                label: 'Google Cloud Platform',
                                                value: GCP,
                                            },
                                        ].map(({ label, value, disabled }, index) => {
                                            return (
                                                <FormControlLabel
                                                    name="cloudPlatform"
                                                    disabled={disabled}
                                                    control={<Radio />}
                                                    onChange={(e) => {
                                                        setActiveCloudPlatform(
                                                            e.target.value
                                                        )
                                                    }}
                                                    // onChange={handleChange}
                                                    key={index}
                                                    value={value}
                                                    label={label}
                                                />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            {activeCloudPlatform === AWS && (
                                <AWSEntry
                                    onFormPost={onFormPost}
                                    closeModal={closeModal}
                                />
                            )}
                            {activeCloudPlatform === AZURE && (
                                <AzureEntry
                                    onFormPost={onFormPost}
                                    closeModal={closeModal}
                                />
                            )}
                            {activeCloudPlatform === GCP && (
                                <GCPEntry
                                    onFormPost={onFormPost}
                                    closeModal={closeModal}
                                />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Paper>
        </Box>
    )
}

const EngagementLinkedCredentials = ({
    title = 'Assigned Credentials',
    columns = [],
    options = {},
    data = [],
    engagementId,
    postRemoveCredentialFromEngagement = () => { },
}) => {
    const { sendToast } = useToastContext()
    const defaultOptions = {
        print: false,
        download: false,
        filter: false,
        search: false,
        selectableRows: 'none',
        serverSide: false,
        customToolbar: () => { },
    }

    const handleRemoveCredentialFromEngagement = (credentialId) => {
        const apiPayload = {
            engagementId,
            credentials: [credentialId],
        }
        removeCredentialsFromEngagement(apiPayload)
            .then(() => {
                sendToast('Credential removed')
                postRemoveCredentialFromEngagement()
            })
            .catch((err) => {
                sendToast(
                    `Error removing credential: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    const defaultColumns = [
        {
            name: 'row_number',
            label: 'No.',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return <span>{dataIndex + 1}</span>
                },
            },
        },
        {
            name: 'name',
            label: 'Alias',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <>{truncateString(data[dataIndex].name, 50)}</>
                },
            },
        },
        {
            name: 'type',
            label: 'Cloud Provider',
        },
        {
            name: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => {
                                handleRemoveCredentialFromEngagement(
                                    data[dataIndex].id
                                )
                            }}
                        >
                            <Delete color="primary" fontSize="small"></Delete>
                        </IconButton>
                    )
                },
            },
        },
    ]

    return (
        <MUIDataTable
            title={title}
            data={data}
            columns={[...defaultColumns, ...columns]}
            options={{ ...defaultOptions, ...options }}
        />
    )
}

const AddCredentialComponent = ({ open, setOpen }) => {
    const { sendToast } = useToastContext()
    const { engagement, asyncFetchEngagement } = useContext(EngagementContext)

    async function handleCreateCredential(payload) {
        let cloudCredentialFunction
        const cloudPlatform = payload['cloudPlatform']
        switch (cloudPlatform) {
            case 'AWS':
                cloudCredentialFunction = createAWSCredential
                break
            case 'AZURE':
                cloudCredentialFunction = createAZURECredential
                break
            case 'GCP':
                cloudCredentialFunction = createGCPCredential
                break
            default:
                return Promise.reject('Invalid Cloud Platform')
        }
        payload['engagementId'] = engagement.id
        return cloudCredentialFunction(payload)
            .then((response) => {
                setOpen(false)
                sendToast('Credential added successfully')
                asyncFetchEngagement()
            })
            .catch((err) => {
                console.log('cloudCredentialFunction', err)
                sendToast(
                    `Error creating credential: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    const useStyles = makeStyles((theme) => ({
        modal: {
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(3),
        },
        paper: {
            width: '650px',
        },
        tabContentContainer: {
            maxHeight: '500px',
            overflow: 'auto',
        },
        contentRoot: {
            backgroundColor: theme.palette.background.silver,
        },
    }))
    const classes = useStyles()

    const [tabIndex, setTabIndex] = useState(0)

    const handleChange = (event, newValue) => {
        setTabIndex(newValue)
    }

    return (
        <Box>
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="add-asset-modal"
                aria-describedby="Add assets"
                className={classes.modal}
            >
                <Paper className={classes.paper}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        aria-label="asset-add-tabs"
                        centered
                    >
                        <Tab label="Add New" />
                        <Tab label="Add Existing" />
                    </Tabs>
                    <Box className={classes.tabContentContainer}>
                        {tabIndex === 0 ? (
                            <CreateCredential
                                modalHeading="Create credential"
                                onFormPost={handleCreateCredential}
                            ></CreateCredential>
                        ) : (
                            <></>
                        )}
                        {tabIndex === 1 ? (
                            <CredentialListing
                                engagementId={engagement.id}
                                title="Available Cloud Credentials"
                                options={{ serverSide: false }}
                                postAddToEngagement={() => {
                                    asyncFetchEngagement()
                                }}
                            ></CredentialListing>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Paper>
            </Modal>
        </Box>
    )
}

const CredentialCard = () => {
    const { engagement, asyncFetchEngagement } = useContext(EngagementContext)
    const useStyles = makeStyles((theme) => ({
        root: {
            // width: "500px"
        },
        addButton: {
            marginRight: '1em',
        },
        cardHeading: {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
        },
    }))
    const classes = useStyles()
    const [showCreateCredential, setShowCreateCredential] = useState(false)

    return (
        <Paper className={classes.root} elevation={2}>
            <Typography variant="h3" className={classes.cardHeading}>
                CREDENTIALS
                <Button
                    color="primary"
                    onClick={() => {
                        setShowCreateCredential(true)
                    }}
                    type="submit"
                    endIcon={<Add />}
                    variant="contained"
                    align="center"
                    className={classes.addButton}
                >
                    Add
                </Button>
            </Typography>
            <Divider></Divider>
            <Box className={classes.contentRoot}>
                {/* <ThemeProvider theme={theme}> */}
                <EngagementLinkedCredentials
                    options={{ rowsPerPage: 5, rowsPerPageOptions: [5] }}
                    data={engagement.credentials}
                    engagementId={engagement.id}
                    postRemoveCredentialFromEngagement={() => {
                        asyncFetchEngagement()
                    }}
                ></EngagementLinkedCredentials>
                {/* </ThemeProvider> */}
            </Box>
            <AddCredentialComponent
                open={showCreateCredential}
                setOpen={setShowCreateCredential}
            ></AddCredentialComponent>
        </Paper>
    )
}

export default CredentialCard
