import { IconButton } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import React from "react";

const UploadReportIcon = ({ openModal }) => {
     return <React.Fragment>
          <IconButton  onClick={() => openModal(true)} >
            <CloudUpload />
          </IconButton>
      </React.Fragment>
}
export default UploadReportIcon
