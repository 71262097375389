import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Paper,
    Typography,
    makeStyles,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core'
import _ from 'lodash'

import CampaignCard from './CampaignCard'

import CampaignGraph from './CampaignGraph'
import {
    getSocialCampaignMonthlyAggregates,
    getSocialCampaigns,
} from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import useLoader from 'src/hooks/useLoader'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '900px',
        margin: `-10px ${theme.spacing(3)}px ${theme.spacing(
            3
        )}px`,
    },
    card: {
        marginBottom: theme.spacing(3),
    },
    cardInput: {
        maxWidth: '300px',
    },
    phishingContainer: {
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px 
        ${theme.spacing(3)}px 
        ${theme.spacing(3)}px`,
    },
    phishingHeading: {
        marginBottom: theme.spacing(3),
    },
    daysLabelContainer: {},
    topCardsContainer: {},
    topCards: {},
    topCardsLabel: {},
    topCardsLabelPercentage: {},
    graphSegments: {},
    graphsWrapper:{
        height: 'calc(100vh - 310px)',
        overflowY: 'auto',
        paddingRight: '10px'
    }
}))

const formattedDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleString('en-US', {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
    })
}

const Phishing = () => {
    const classes = useStyles()
    const [projects, setProjects] = useState([])
    const [selectedProjectId, setSelectedProjectId] = useState('all') // using 'all' as default
    const [campaignData, setCampaignData] = useState([])
    const [monthlyCompromisedEmails, setMonthlyCompromisedEmails] = useState({})
    const [loading, showLoader, hideLoader] = useLoader()
    const { sendToast } = useToastContext()

    async function fetchMonthlyPhishingCampaigns() {
        showLoader()
        const params = {}
        if (selectedProjectId !== 'all') {
            params['project_id'] = selectedProjectId
        }
        await getSocialCampaignMonthlyAggregates(params)
            .then((response) => setMonthlyCompromisedEmails(response.data))
            .catch((err) => {
                console.log('getSocialCampaignMonthlyAggregates', err)
                sendToast(
                    `Error getting monthly social data: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
        hideLoader()
    }

    async function fetchCampaignData() {
        showLoader()
        const params = {}
        let results = []
        if (selectedProjectId !== 'all') {
            params['project_id'] = selectedProjectId
        }
        await getSocialCampaigns(params)
            .then((response) => {
                results = response['results']
                const transformedCampaignData = results.map((campaign) => {
                    return {
                        id: campaign.id,
                        started_on: formattedDate(campaign.started_date),
                        target_group: campaign.name,
                        score: 60, //  TODO: Add dynamic score
                        sent_emails: campaign.sent_count,
                        delivered_emails: campaign.delivered_count,
                        opened_emails: campaign.opened_count,
                        clicked_link: campaign.clicked_count,
                        credentials: campaign.credentials_count,
                    }
                })
                // Set projects only on initial load
                if (projects.length === 0) {
                    const allProjects = results.map(
                        (campaign) => campaign.project
                    )
                    setProjects(_.uniqWith(allProjects, _.isEqual))
                }
                setCampaignData(transformedCampaignData)
                hideLoader()
            })
            .catch((err) => {
                console.log('getSocialCampaigns', err)
                sendToast(
                    `Error getting social campaigns: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    useEffect(() => {
        fetchCampaignData()
        fetchMonthlyPhishingCampaigns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchCampaignData()
        fetchMonthlyPhishingCampaigns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProjectId])

    const ProjectDropDown = () => {
        return (
            <FormControl
                variant="outlined"
                fullWidth
                className={classes.cardInput}
            >
                <InputLabel id="project-label">Projects</InputLabel>
                <Select
                    labelId="project-label"
                    id="project"
                    label="Project"
                    value={selectedProjectId}
                    onChange={(e) => {
                        setSelectedProjectId(e.target.value)
                    }}
                >
                    <MenuItem key="all" value="all">
                        All
                    </MenuItem>
                    {projects.map((project) => {
                        return (
                            <MenuItem key={project.id} value={project.id}>
                                {project.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    return (
        <Box className={classes.root}>
            {loading}
            <Typography variant="h3" className={classes.phishingHeading}>
                Overview of All Phishing Campaigns
            </Typography>
            <Paper className={classes.phishingContainer}>
                <ProjectDropDown></ProjectDropDown>
                <div className={classes.graphsWrapper}>
                <CampaignGraph
                    loadingComplete={true}
                    providerData={monthlyCompromisedEmails}
                />
                {campaignData.map((campaign, index) => {
                    return (
                        <CampaignCard
                            key={campaign?.id || index}
                            campaign={campaign}
                            fetchCampaignData={fetchCampaignData}
                        />
                    )
                })}</div>
            </Paper>
        </Box>
    )
}

Phishing.defaultProps = {}

Phishing.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.object),
}

export default Phishing
