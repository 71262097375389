import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDropzone } from 'react-dropzone'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '660px',
        margin: '0 auto',
    },
    fileUploadContainer: {
        padding: theme.spacing(3),
        border: `2px dashed ${theme.palette.divider}`,
        borderRadius: '8px',
        height: '120px',
        // width: '75%',
        // margin: 'auto',
        cursor: 'pointer',
    },
    radioGroup: {
        flexDirection: 'row',
    },
    formTextField: {
        margin: `${theme.spacing(1)}px 0`,
    },
    credentialPreview: {
        // width: '75%',
        // margin: 'auto',
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.divider}`,
        wordBreak: 'break-word',
    },
    assetsModalContent: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 380px)',
        paddingRight: '5px'
    },
    errorField: {
        borderColor: theme.palette.background.error
    }
}))

const CredentialDisplay = ({ gcpCredentials, className }) => {
    return (
        <Box className={className}>
            {[
                'type',
                'project_id',
                'private_key_id',
                'private_key',
                'client_email',
                'client_id',
                'auth_uri',
                'token_uri',
                'auth_provider_x509_cert_url',
                'client_x509_cert_url',
            ].map((credentialField, index) => {
                const sanitized = credentialField === 'private_key'
                return (
                    <Box mb={1} key={index}>
                        <Typography display="inline">
                            {credentialField}:{' '}
                        </Typography>
                        <Typography display="inline" color="textSecondary">
                            {sanitized
                                ? '***********'
                                : gcpCredentials[credentialField]}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

const GCPEntry = ({
    initialFormPayload,
    modalHeading,
    onFormPost,
    closeModal,
}) => {
    const classes = useStyles()
    const [isAlertVisible, setAlertVisible] = useState(false)
    const [jsonValidationError, setValidationError] = useState(false)
    const [createBtnDisable, setCreateBtnDisable] = useState(true)
    const [credentialPayload, setCredentialPayload] = useState({})

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const binaryStr = reader.result
                try {
                    const payload = JSON.parse(binaryStr)
                    setCredentialPayload(payload)
                    setCreateBtnDisable(false)
                } catch (e) {
                    setValidationError(true)
                }
            }
            reader.readAsText(file)
        })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/json',
    })

    return (
        <Formik
            initialValues={{
                ...initialFormPayload,
                cloudPlatform: 'GCP',
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Required'),

            })}
            validateOnMount
            onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
                setSubmitting(true)
                await onFormPost({
                    ...credentialPayload,
                    cloudPlatform: values['cloudPlatform'],
                    name: values['name'],
                })
                    .then(() => {
                        setStatus({ success: true })
                        setSubmitting(false)
                        closeModal()
                    })
                    .catch((err) => {
                        console.error(err)
                        setStatus({ success: false })
                        setErrors({ submit: err.message })
                        setSubmitting(false)
                        setAlertVisible(true)
                    })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                isValid
            }) => (
                <Box className={clsx('CreateAsset', classes.root)}>
                    {isAlertVisible && (
                        <Box mb={3}>
                            <Alert
                                onClose={() => setAlertVisible(false)}
                                severity="error"
                            >
                                Failed to submit data
                            </Alert>
                        </Box>
                    )}
                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={5}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box mt={1} width="75%" margin="auto">
                            <form onSubmit={handleSubmit}>
                                <div className={classes.assetsModalContent}>
                                    <TextField
                                        className={classes.formTextField}
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helpertext={touched.name && errors.name}
                                        label="Name*"
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Staging NYC-2"
                                        value={values.name}
                                        variant="outlined"
                                    />
                                    {_.isEmpty(credentialPayload) ? (
                                        <Box
                                            className={clsx(
                                                classes.fileUploadContainer,
                                                {
                                                    [classes.errorField]: jsonValidationError
                                                }
                                            )}

                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                                <Typography>
                                                    Drop the files here ...
                                                </Typography>
                                            ) : (
                                                <Typography>
                                                    Drag or click to select your
                                                    genderated credentials.json
                                                </Typography>
                                            )}
                                            {jsonValidationError && (
                                                <Typography>
                                                    Invalid or Malformed JSON
                                                </Typography>
                                            )}
                                        </Box>
                                    ) : (
                                        <CredentialDisplay
                                            gcpCredentials={credentialPayload}
                                            className={classes.credentialPreview}
                                        />
                                    )}
                                    {Boolean(touched.policy && errors.policy) && (
                                        <FormHelperText error>
                                            {errors.policy}
                                        </FormHelperText>
                                    )}
                                </div>
                                <Box
                                    mt={3}
                                    justifyContent="center"
                                    display="flex"
                                >
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting || createBtnDisable}
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        align="center"
                                    >
                                        Create
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    )}
                </Box>
            )}
        </Formik>
    )
}

GCPEntry.propTypes = {
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    formFields: PropTypes.array,
    modalHeading: PropTypes.string,
}

GCPEntry.defaultProps = {}

export default GCPEntry
