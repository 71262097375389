import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { forwardRef } from 'react'; // Import forwardRef from React

export const BlackOnWhiteTooltip = withStyles((theme) => ({
    tooltip: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.autoFill, // Access theme properties
        textAlign: "center",
        borderRadius: "4px",
        borderColor: "rgba(0, 0, 0, 0.12)",
        border: "solid",
        borderWidth: "1px",
        fontSize: "12px",
        maxWidth: "200px"
    },
}))(forwardRef((props, ref) => <Tooltip {...props} ref={ref} />));

