import CriticalIcon from 'src/theme/s2/UVC_finding_critical.svg';
import PoorIcon from 'src/theme/s2/UVC_finding_high.svg';
import OkayIcon from 'src/theme/s2/UVC_findings_medium.svg';
import GoodIcon from 'src/theme/s2/UVC_findings_low.svg';
import GreatIcon from 'src/theme/s2/UVC_findings_info.svg';

export const securityScoreValues = {
    CRITICAL: {
        value: 'CRITICAL',
        name: 'Critical',
        color: '#ac0000',
        illustration: CriticalIcon,
    },
    POOR: {
        value: 'POOR',
        name: 'Poor',
        color: '#de5d28',
        illustration: PoorIcon,
    },
    OKAY: {
        value: 'OKAY',
        name: 'Okay',
        color: '#C9DE0E',
        illustration: OkayIcon,
    },
    GOOD: {
        value: 'GOOD',
        name: 'Good',
        color: '#6314FF',
        illustration: GoodIcon,
    },
    NEUTRAL: {
        value: 'NEUTRAL',
        name: 'neutral',
        color: '#D1D1DE',
        illustration: GreatIcon,
    },
};

export function getScoreCategoryByValue(score) {
    const getCategory = (threshold, category) => (score >= threshold ? { score, ...category } : null);

    const categories = [
        getCategory(85, securityScoreValues.GOOD),
        getCategory(50, securityScoreValues.OKAY),
        getCategory(25, securityScoreValues.POOR),
    ].filter(Boolean);

    let resultCategory;

    if (score === 0) {
        resultCategory = { score: 15, ...securityScoreValues.CRITICAL };
    } else {
        resultCategory = categories.length > 0 ? categories[0] : { score: 100, ...securityScoreValues.GOOD };
    }

    return resultCategory;
}
