import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Card,
    Typography,
    makeStyles,
    Divider,
    IconButton,
    Dialog,
    DialogTitle
} from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync';
import CloseIcon from '@material-ui/icons/Close';
import AssetsTable from './AssetsTable'

import CreateModal from './CreateCredentialModal'

const useStyles = makeStyles((theme) => ({
    root: { borderRadius: '0 0 6px 6px' },
    tableHeadingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2),
        alignItems: 'center',
    },
    tableHeading: {
        fontSize:'20px'
    },
    defaultTextContainer: {
        margin: theme.spacing(6),
        textAlign: 'center',
    },
    defaultTextHeader: {
        fontWeight: 'bold',
    },
    modalTitle:{
        borderBottom: '1px solid transparent',
        borderBottomColor: theme.palette.background.darkGray,
    },
    closeIcon: {
        position:'absolute',
        right:'15px',
        cursor: 'pointer',
        top: '14px'
    },
    modalHeader: {
        fontSize: '1.2146rem',
        fontWeight: '500'
    },
}))

const Credentials = ({
    addToEngage,
    credentials,
    onAssetRefresh,
    onCredentialDelete,
    onCredentialCreate,
    onAddCredToEngage
}) => {
    const classes = useStyles()
    const [modelOpen, setModalOpen] = useState(false)
    const [deleteFlag, setDeleteFlag] = useState(false)
    return (
        <Card className={classes.root}>
            <Dialog
                open={modelOpen}                      
                maxWidth="md"
            >
                <DialogTitle>
                <Typography className={classes.modalHeader} >Add Cloud Credential</Typography>
                    <IconButton className={classes.closeIcon} size='small' onClick={() => {
                    setModalOpen(!modelOpen)
                }}  >
                <CloseIcon />
                </IconButton>
                </DialogTitle>
                <CreateModal                    
                    onFormPost={onCredentialCreate}
                    closeModal={setModalOpen.bind(this, false)}
                />
            </Dialog>
            <Box className={classes.tableHeadingContainer}>
                <Typography variant="h4" className={classes.tableHeading}>Assigned Cloud Credentials</Typography>
                <Box>
                    {deleteFlag && <IconButton
                        title='Refresh Credentials'
                        color="primary"
                        size="medium"
                        style={{ height: '42px', alignSelf: 'center', marginRight: "10px" }}
                        variant="contained"
                        onClick={() => {
                            onAssetRefresh();
                            setDeleteFlag(false)
                        }}
                    >
                        <SyncIcon fontSize="medium"></SyncIcon>
                    </IconButton>}
                    <Button
                        color="primary"
                        onClick={() => {
                            setModalOpen(true)
                        }}
                        
                        variant="contained"
                        size="small"
                    >
                        Create Credential
                    </Button></Box>
            </Box>

            {credentials.length <= 0 ? (
                <><Divider />
                    <Box className={classes.defaultTextContainer}>
                        <Typography className={classes.defaultTextHeader}>
                            Add Cloud Credentials
                        </Typography>
                        <Typography>
                            You can add AWS, Azure, or Google Cloud Platform
                        </Typography>
                    </Box></>
            ) :
                <AssetsTable
                    columnNames={[
                        { label: 'Alias', name: 'name' },
                        { label: 'Cloud Provider', name: 'type' },
                    ]}
                    addToEngage={addToEngage}
                    tableValues={credentials}
                    onDeleteAction={async (id) => {
                        setDeleteFlag(true)
                        return await onCredentialDelete(id)
                    }}
                    onSubmit={onAddCredToEngage}
                />
            }
        </Card>
    )
}

export default Credentials

Credentials.propTypes = {
    credentials: PropTypes.array,
    onCredentialDelete: PropTypes.func.isRequired,
    onCredentialCreate: PropTypes.func.isRequired,
}

Credentials.defaultProps = {
    credentials: [],
}
