import React, { Fragment, useState } from 'react'
import { Grid, makeStyles, Link } from '@material-ui/core'
import useLoader from 'src/hooks/useLoader'
import { useToastContext } from 'src/context/ToastContext'
import { deleteReportById, getAllReports } from 'src/actions/openapi'
import MUIDataTable from 'mui-datatables'
import { Link as RouterLink } from 'react-router-dom'
import { TableButton } from 'src/components/Button'
import { CloudDownload, Delete } from '@material-ui/icons'
import { downloadFile, requestSignedURL } from 'src/components/PDFjs/utils'
import PdfViewer from 'src/components/PDFjs'
import { formatDateString, has_allowed_extension } from 'src/utils/common'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import UploadReportIcon from './UploadReportIcon'
import UploadReport from './UploadReport'
import { hasReportUploadAccess } from 'src/utils/permission'
import useUser from 'src/hooks/useUser'
import {
    getColumnFilters,
    getSortValue,
    renameKeys,
} from 'src/utils/tableUtils'

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.link.main,
        '&:visited': {
            color: theme.palette.link.active,
            // textDecoration: 'underline',
        },
        '&:hover': {
            color: theme.palette.link.hover,
            textDecoration: 'underline',
        },
    },
    button: {
        fontSize: 'smaller',
        position: 'absolute',
        color: theme.palette.background.iconColor,
        '&:hover': {
            color: `rgb(${theme.palette.text.purple})`,
        },
    },
    deleteButton: {
        marginLeft: '8px',
    },
}))

export default function ReportsList({
    title = 'Reports',
    columns = [],
    options = {},
    pagination = {
        page: 0,
        rowsPerPage: 10,
    },
}) {
    const classes = useStyles()
    const userDetails = useUser()
    const { sendToast } = useToastContext()
    const [loading, showLoader, hideLoader] = useLoader()

    const [data, setData] = useState({
        count: 0,
        results: [],
    })
    const [page, setPage] = useState(pagination.page)
    const [rowsPerPage, setRowsPerPage] = useState(pagination.rowsPerPage)
    const [pdfPreview, setPdfPreview] = useState(null)
    const [openUpload, setOpenUplaod] = useState(false)

    const fetchData = (params) => {
        params['page'] += 1
        showLoader()
        getAllReports(params)
            .then((response) => {
                setData(response)
            })
            .catch((err) => {
                console.log('getAllReports', err)
                sendToast(
                    `Error getting reports: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                hideLoader()
            })
    }

    const handleReportDownload = async (dataIndex) => {
        const reportData = data.results[dataIndex]
        showLoader()
        downloadFile(reportData.file_id.id, reportData.id).finally(() => {
            hideLoader()
        })
    }

    const handleReportDelete = async (dataIndex) => {
        const reportDetail = data.results[dataIndex]
        const title = reportDetail.title
        showLoader()
        deleteReportById(reportDetail.id)
            .then((response) => {
                if (response.data?.deleted) {
                    sendToast(title + ' Deleted Successfully ')
                    setPage(0)
                    fetchData({
                        page: 0,
                        limit: rowsPerPage,
                    })
                }
            })
            .catch((err) => {
                console.log('deleteReportById', err)
                sendToast(
                    `Error deleting report: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    const handlePageChange = (currentPage) => {
        setPage(currentPage)
    }

    const handleRowsPerPageChange = (numberOfRows) => {
        setRowsPerPage(numberOfRows)
    }

    const handleTableInit = (action, tableState) => {
        fetchData({
            page: tableState.page,
            limit: tableState.rowsPerPage,
        })
    }

    const makeRequestData = (tableState) => {
        const columnFilters = getColumnFilters(tableState)
        var updatedFilters = renameKeys(columnFilters, {
            project__id: 'project',
        })
        if (tableState.searchText) {
            updatedFilters['search'] = tableState.searchText
        }
        if (updatedFilters?.created_date) {
            if (
                updatedFilters.created_date[0] !== undefined &&
                updatedFilters.created_date[0] !== 'Invalid date'
            ) {
                updatedFilters['created_date__gte'] =
                    updatedFilters.created_date[0]
            }
            if (
                updatedFilters.created_date[1] !== undefined &&
                updatedFilters.created_date[1] !== 'Invalid date'
            ) {
                updatedFilters['created_date__lt'] =
                    updatedFilters.created_date[1]
            }
            delete updatedFilters['created_date']
        }
        updatedFilters['ordering'] = getSortValue(
            tableState.sortOrder.name,
            tableState.sortOrder.direction
        )
        return {
            page: tableState.page,
            limit: tableState.rowsPerPage,
            ...updatedFilters,
        }
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'filterChange':
            case 'changeRowsPerPage':
            case 'resetFilters':
            case 'search':
            case 'sort':
            case 'changePage': {
                setPage(tableState.page)
                setRowsPerPage(tableState.rowsPerPage)
                fetchData(makeRequestData(tableState))
            }
            /* falls through */
            // no default
        }
    }

    async function handleLinkClick(report, dataIndex) {
        if (has_allowed_extension(report.filename, ['pdf'])) {
            const signedS3Link = await requestSignedURL(report.file_id.id)
            setPdfPreview({
                assetName: `Report ${report.title}`,
                signedS3Link,
            })
        } else {
            handleReportDownload(dataIndex)
        }
    }

    const defaultOptions = {
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 300px)',
        print: false,
        download: false,
        filterType: 'multiselect',
        search: false,
        selectableRows: 'none',
        serverSide: true,
        count: data.count,
        pagination: true,
        page: page,
        rowsPerPage: rowsPerPage,
        onTableInit: handleTableInit,
        onTableChange: handleTableChange,
        onChangePage: handlePageChange,
        onChangeRowsPerPage: handleRowsPerPageChange,
        enableNestedDataAccess: '__',
        customToolbar: () => {
            return (
                hasReportUploadAccess(userDetails) && (
                    <UploadReportIcon openModal={setOpenUplaod} />
                )
            )
        },
    }

    const defaultColumns = [
        {
            name: 'row_number',
            label: 'No.',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return <span>{page * rowsPerPage + dataIndex + 1}</span>
                },
            },
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const report = data.results[dataIndex]
                    return (
                        <Link
                            to="#"
                            className={classes.link}
                            component={RouterLink}
                            onClick={async (event) => {
                                event.preventDefault()
                                await handleLinkClick(report, dataIndex)
                            }}
                        >
                            {report.title}
                        </Link>
                    )
                },
                filter: false,
                filterOptions: {},
            },
        },
        {
            name: 'version',
            label: 'Version',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'version_id',
            label: 'Version ID',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'reviewed',
            label: 'Reviewed',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'file_id__id',
            label: 'File ID',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span>{data.results[dataIndex].file_id.id}</span>
                },
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'user__id',
            label: 'User ID',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span>{data.results[dataIndex].user.id}</span>
                },
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'project__id',
            label: 'Project ID',
            options: {
                display: 'excluded',
                filter: true,
                filterOptions: {
                    renderValue: val => {
                        if (val === "" || val === null || val === undefined) {
                            return <span className='null-value'>No Project Assigned</span>;
                        }
                        return val;
                    }
                }
            },
        },
        {
            name: 'organization__id',
            label: 'Organization ID',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>{data.results[dataIndex].organization.id}</span>
                    )
                },
                display: 'excluded',
                filter: false,
            },
        },
        // {
        //     name: 'type',
        //     label: 'Report Type',
        //     options: {
        //         filter: true,
        //     },
        // },
        {
            name: 'created_date',
            label: 'Date',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            {formatDateString(
                                data.results[dataIndex].created_date
                            )}
                        </span>
                    )
                },
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (v) => {
                        if (v[0] && v[1]) {
                            return `${v[0]} - ${v[1]}`
                        }
                        return []
                    },
                },
                filterOptions: {
                    names: [],
                    logic(created_date, filters) {
                        if (filters[0] && filters[1]) {
                            return (
                                moment(created_date) < moment(filters[0]) ||
                                moment(created_date) > moment(filters[1])
                            )
                        }
                        return false
                    },
                    fullWidth: true,
                    display: (filterList, onChange, index, column) => (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    style={{ width: '100%' }}
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-DD"
                                    margin="normal"
                                    id="startDate"
                                    label="Start Date"
                                    value={
                                        filterList[index][0]
                                            ? filterList[index][0]
                                            : null
                                    }
                                    onChange={(date) => {
                                        filterList[index][0] =
                                            moment(date).format('yyyy-MM-DD')
                                        onChange(
                                            filterList[index],
                                            index,
                                            column
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    disableToolbar
                                    style={{ width: '100%' }}
                                    variant="inline"
                                    format="yyyy-MM-DD"
                                    margin="normal"
                                    id="endDate"
                                    label="End Date"
                                    value={
                                        filterList[index][1]
                                            ? filterList[index][1]
                                            : null
                                    }
                                    onChange={(date) => {
                                        filterList[index][1] =
                                            moment(date).format('yyyy-MM-DD')
                                        onChange(
                                            filterList[index],
                                            index,
                                            column
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ),
                },
                print: false,
            },
        },
        {
            name: 'updated_date',
            label: 'Updated Date',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <TableButton
                                tooltipTitle="Download"
                                onClick={() => {
                                    handleReportDownload(dataIndex)
                                }}
                            >
                                <CloudDownload
                                    className={classes.button}
                                ></CloudDownload>
                            </TableButton>
                            <TableButton
                                tooltipTitle="Delete"
                                onClick={() => {
                                    handleReportDelete(dataIndex)
                                }}
                                className={classes.deleteButton}
                            >
                                <Delete className={classes.button}></Delete>
                            </TableButton>
                        </>
                    )
                },
            },
        },
    ]

    const postReportUpload = () => {
        setPage(0)
        fetchData({
            page: page,
            limit: rowsPerPage,
        })
    }

    return (
        <Fragment>
            {loading}
            {openUpload && (
                <UploadReport
                    fetchData={postReportUpload}
                    onClose={() => setOpenUplaod(false)}
                    onUserCreate={() => { }}
                    active={openUpload}
                />
            )}
            {pdfPreview && (
                <PdfViewer
                    signedS3Link={pdfPreview.signedS3Link}
                    assetName={pdfPreview.assetName}
                    onClose={setPdfPreview}
                    active={!!pdfPreview}
                />
            )}

            <MUIDataTable
                title={title}
                data={data.results}
                columns={[...defaultColumns, ...columns]}
                options={{ ...defaultOptions, ...options }}
            />
        </Fragment>
    )
}
