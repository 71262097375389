import { useContext, useState } from 'react'
import {
    Box,
    Button,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    makeStyles,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Modal,
    Tabs,
    Tab,
    Divider,
    FormHelperText,
    CircularProgress,
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { useToastContext } from 'src/context/ToastContext'

import { createAsset, removeAssetsFromEngagement } from 'src/actions/openapi'
import AssetsListing from '../Assets/AssetsListing'
import MUIDataTable from 'mui-datatables'
import EngagementContext from './EngagementContext'
import { truncateString } from 'src/utils/common'

// const theme = createTheme({
//     overrides: {
//         MUIDataTableToolbar: {
//             titleText: {
//                 fontSize: '20px',
//             },
//         },
//     },
// })

const NewAssetListing = () => {
    const { engagement, asyncFetchEngagement } = useContext(EngagementContext)

    const useStyles = makeStyles(() => ({
        root: {
            overflow: 'auto',
            maxHeight: '500px',
        },
    }))

    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <AssetsListing
                title="Available Assets"
                options={{ serverSide: false }}
                engagementId={engagement.id}
                postAddToEngagement={() => {
                    asyncFetchEngagement()
                }}
            />
        </Box>
    )
}

const CreateAsset = ({ setOpen }) => {
    const { sendToast } = useToastContext()
    const { engagement, asyncFetchEngagement } = useContext(EngagementContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(3),
        },
        radioGroup: {
            flexDirection: 'row',
        },
    }))
    const classes = useStyles()

    const validationSchema = Yup.object().shape({
        alias: Yup.string().required('Required'),
        scopeOptions: Yup.mixed()
            .oneOf(['IP_ADDRESS', 'FQDN', 'CIDR'])
            .required('Required'),
    })

    return (
        <Formik
            initialValues={{
                alias: '',
                scopeOptions: 'IP_ADDRESS',
            }}
            validationSchema={validationSchema}
            onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
                setSubmitting(true)
                const apiPayload = {
                    assetType: values.scopeOptions,
                    identifier: values.alias,
                    engagementId: engagement.id,
                }
                return createAsset(apiPayload)
                    .then((assetResponse) => {
                        sendToast('Asset created')
                        setOpen(false)
                        asyncFetchEngagement()
                    })
                    .catch((error) => {
                        if (error?.response?.status === 400) {
                            sendToast('Asset already exists', 'error')
                        } else {
                            sendToast('Error creating asset', 'error')
                        }
                    })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <Box>
                    <Paper>
                        <CardContent>
                            {isSubmitting ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    my={5}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Box mt={3}>
                                    <form onSubmit={handleSubmit}>
                                        <Box>
                                            <Box margin={1}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">
                                                        Asset Type
                                                    </FormLabel>
                                                    <RadioGroup
                                                        className={
                                                            classes.radioGroup
                                                        }
                                                    >
                                                        {[
                                                            {
                                                                label: 'IP Address',
                                                                value: 'IP_ADDRESS',
                                                            },
                                                            {
                                                                label: 'Domain',
                                                                value: 'FQDN',
                                                            },
                                                            {
                                                                label: 'CIDR',
                                                                value: 'CIDR',
                                                            },
                                                        ].map(
                                                            ({
                                                                label,
                                                                value,
                                                            }) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        name="scopeOptions"
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        key={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                        label={
                                                                            label
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                            <Box mt={3}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.alias &&
                                                        errors.alias
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.alias &&
                                                        errors.alias
                                                    }
                                                    label="Asset Identifier"
                                                    name="alias"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.alias}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Box>

                                        {Boolean(
                                            touched.policy && errors.policy
                                        ) && (
                                                <FormHelperText error>
                                                    {errors.policy}
                                                </FormHelperText>
                                            )}
                                        <Box
                                            mt={3}
                                            justifyContent="center"
                                            display="flex"
                                        >
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                align="center"
                                            >
                                                Create
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                            )}
                        </CardContent>
                    </Paper>
                </Box>
            )}
        </Formik>
    )
}

const AddAssetComponent = ({ open, setOpen, engagementId }) => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(3),
        },
        paper: {
            maxHeight: '600px',
            width: '600px',
        },
    }))
    const classes = useStyles()

    const [tabIndex, setTabIndex] = useState(0)

    const handleChange = (event, newValue) => {
        setTabIndex(newValue)
    }

    return (
        <Box>
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="add-asset-modal"
                aria-describedby="Add assets"
                className={classes.modal}
            >
                <Paper className={classes.paper}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        aria-label="asset-add-tabs"
                        centered
                    >
                        <Tab label="Add New" />
                        <Tab label="Add Existing" />
                    </Tabs>
                    {tabIndex === 0 ? <CreateAsset setOpen={setOpen} /> : <></>}
                    {tabIndex === 1 ? <NewAssetListing /> : <></>}
                </Paper>
            </Modal>
        </Box>
    )
}

const EngagementLinkedAssets = ({
    title = 'Assigned Assets',
    columns = [],
    options = {},
    data = [],
    engagementId,
    postRemoveAsssetFromEngagement = () => { },
}) => {
    const { sendToast } = useToastContext()

    const defaultOptions = {
        print: false,
        download: false,
        filter: false,
        search: false,
        selectableRows: 'none',
        serverSide: false,
        customToolbar: () => { },
    }

    const handleRemoveAssetFromEngagement = (assetId) => {
        const apiPayload = {
            engagementId,
            assets: [assetId],
        }
        removeAssetsFromEngagement(apiPayload)
            .then(() => {
                sendToast('Asset removed')
                postRemoveAsssetFromEngagement()
            })
            .catch((err) => {
                sendToast(
                    `Error removing asset: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
    }

    const defaultColumns = [
        {
            name: 'row_number',
            label: 'No.',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return <span>{dataIndex + 1}</span>
                },
            },
        },
        {
            name: 'asset_type',
            label: 'Type',
        },
        {
            name: 'identifier',
            label: 'Identifier',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <>{truncateString(data[dataIndex].identifier, 50)}</>
                },
            },
        },
        {
            name: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => {
                                handleRemoveAssetFromEngagement(
                                    data[dataIndex].id
                                )
                            }}
                        >
                            <Delete color="primary" fontSize="small"></Delete>
                        </IconButton>
                    )
                },
            },
        },
    ]

    return (
        <MUIDataTable
            title={title}
            data={data}
            columns={[...defaultColumns, ...columns]}
            options={{ ...defaultOptions, ...options }}
        />
    )
}

const EngagementAssetsCard = () => {
    const [showAddAsset, setShowAddAsset] = useState(false)
    const { engagement, asyncFetchEngagement } = useContext(EngagementContext)

    const useStyles = makeStyles((theme) => ({
        root: {},
        addButton: {
            marginRight: '1em',
        },
        cardHeading: {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
        },
    }))
    const classes = useStyles()

    return (
        <Paper className={classes.root} elevation={1}>
            <Typography variant="h3" className={classes.cardHeading}>
                ASSETS
                <Button
                    color="primary"
                    onClick={() => {
                        setShowAddAsset(true)
                    }}
                    type="submit"
                    endIcon={<Add />}
                    variant="contained"
                    align="center"
                    className={classes.addButton}
                >
                    Add
                </Button>
            </Typography>
            <Divider></Divider>
            <Box className={classes.contentRoot}>
                {/* <ThemeProvider theme={theme}> */}
                <EngagementLinkedAssets
                    options={{ rowsPerPage: 5, rowsPerPageOptions: [5] }}
                    data={engagement.scope}
                    engagementId={engagement.id}
                    postRemoveAsssetFromEngagement={() => {
                        asyncFetchEngagement()
                    }}
                ></EngagementLinkedAssets>
                {/* </ThemeProvider> */}
            </Box>
            <AddAssetComponent
                open={showAddAsset}
                setOpen={setShowAddAsset}
            ></AddAssetComponent>
        </Paper>
    )
}

export default EngagementAssetsCard
