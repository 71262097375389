import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
    Box,
    Button,
    CardContent,
    CircularProgress,
    Divider,
    FormHelperText,
    FormControl,
    InputAdornment,
    InputLabel,
    IconButton,
    makeStyles,
    OutlinedInput,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import useAuth from 'src/hooks/useAuth'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '480px',
        margin : '0 auto',
        marginTop: theme.spacing(10),
    },
    resetPasswordText: {
        marginTop: theme.spacing(3),
        width: '90%',
        margin: `0 auto 0`,
    },
}))

const Verification = () => {
    const classes = useStyles()
    const context = useAuth()
    const history = useHistory()
    const [isAlertVisible, setAlertVisible] = useState(false)
    const [displayPassword, setPasswordVisibility] = useState(false)

    return (
        <Formik
            initialValues={{
                email: context.user || '',
                password: '',
                verificationCode: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().required('Required'),
                password: Yup.string()
                .min(7, 'Must be at least 7 characters')
                .max(255)
                .required('Required'),
                verificationCode: Yup.string().required('Required'),
            })}
            onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
             //   setSubmitting(true)
                await context
                    .setForgottenPassword(
                        values.email,
                        values.password,
                        values.verificationCode
                    )
                    .then((result) => {
                        if(!result){
                        setStatus({ success: true })
                        setSubmitting(false)
                        resetForm()
                        history.push('/')
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                        setStatus({ success: false })
                        setErrors({ submit: err.message })
                        setSubmitting(false)
                        setAlertVisible(true)
                    })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <Box className={clsx('Signup', classes.root)} maxWidth={480}>
                    <Paper>
                        <CardContent>
                            <Box pt={6} pb={6}>
                                <Typography
                                    variant='h3'
                                    color='textPrimary'
                                    align='center'
                                >
                                    Set New Password
                                </Typography>
                                <Typography
                                    color='textSecondary'
                                    className={classes.resetPasswordText}
                                >
                                    Enter the verification code sent to the
                                    provided email address.
                                </Typography>
                            </Box>

                            <Divider />
                            {isAlertVisible && (
                                <Box mb={3}>
                                    <Alert
                                        onClose={() => setAlertVisible(false)}
                                        severity='error'
                                    >
                                        Failed to reset password
                                    </Alert>
                                </Box>
                            )}
                            {isSubmitting ? (
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    my={5}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Box mt={3}>
                                    <form onSubmit={handleSubmit}>
                                        <Box mt={3}>
                                            <TextField
                                                error={Boolean(
                                                    touched.email &&
                                                        errors.email
                                                )}
                                                fullWidth
                                                helpertext={
                                                    touched.email &&
                                                    errors.email
                                                }
                                                label='Email Address'
                                                name='email'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type='email'
                                                value={values.email}
                                                variant='outlined'
                                            />
                                        </Box>
                                        <Box mt={3}>
                                            <FormControl
                                           error={Boolean(
                                            touched.password &&
                                                errors.password
                                        )}
                                                variant='outlined'
                                                fullWidth
                                            >
                                                <InputLabel htmlFor='outlined-adornment-password'>
                                                    New Password
                                                </InputLabel>
                                                <OutlinedInput
                                                    id='outlined-adornment-password'
                                                    
                                                    fullWidth
                                                   
                                                    type={
                                                        displayPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    label='Password'
                                                    name='password'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    variant='outlined'
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={() => {
                                                                    setPasswordVisibility(
                                                                        !displayPassword
                                                                    )
                                                                }}
                                                                onMouseDown={(
                                                                    e
                                                                ) =>
                                                                    e.preventDefault
                                                                }
                                                            >
                                                                {displayPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText id="component-error-text">{touched.password && errors.password}</FormHelperText>
                                            </FormControl>
                                        </Box>

                                        <Box mt={3}>
                                            <TextField
                                                error={Boolean(
                                                    touched.verificationCode &&
                                                        errors.verificationCode
                                                )}
                                                fullWidth
                                                helpertext={
                                                    touched.verificationCode &&
                                                    errors.verificationCode
                                                }
                                                label='Verification Code'
                                                name='verificationCode'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.verificationCode}
                                                variant='outlined'
                                            />
                                        </Box>
                                        {Boolean(
                                            touched.policy && errors.policy
                                        ) && (
                                            <FormHelperText error>
                                                {errors.policy}
                                            </FormHelperText>
                                        )}
                                        <Box
                                            mt={3}
                                            justifyContent='center'
                                            display='flex'
                                        >
                                            <Button
                                                color='primary'
                                                disabled={isSubmitting}
                                                size='large'
                                                type='submit'
                                                variant='contained'
                                                align='center'
                                            >
                                                Change Password
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                            )}
                        </CardContent>
                    </Paper>
                </Box>
            )}
        </Formik>
    )
}

Verification.propTypes = {
    onSubmit: PropTypes.func,
}

Verification.defaultProps = {}

export default Verification
