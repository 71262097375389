import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik} from 'formik'
import {
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    makeStyles,
    TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '660px',
        margin: '0 auto',
    },
    radioGroup: {
        flexDirection: 'row',
    },
    formTextField: {
        margin: `${theme.spacing(1)}px 0`,
    },
    assetsModalContent : {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 380px)',
        paddingRight:'5px'
    }
}))

const AWSEntry = ({ initialFormPayload, onFormPost, closeModal }) => {
    const classes = useStyles()
    const [isAlertVisible, setAlertVisible] = useState(false)

    return (
        <Formik
            initialValues={{
                ...initialFormPayload,
                cloudPlatform: 'AWS',
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Required'),
                //roleArn: Yup.string(),
                accessKey: Yup.string().required('Required'),
                secretKey: Yup.string().required('Required'),
                sessionToken: Yup.string(),
                
            })            
            }
            validateOnMount
            onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
                setSubmitting(true)
                await onFormPost(values)
                    .then(() => {
                        setStatus({ success: true })
                        setSubmitting(false)
                        closeModal()
                    })
                    .catch((err) => {
                        console.error(err)
                        setStatus({ success: false })
                        setErrors({ submit: err.message })
                        setSubmitting(false)
                        setAlertVisible(true)
                    })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                isValid
            }) => (
                <Box className={clsx('CreateAsset', classes.root)}>
                    {isAlertVisible && (
                        <Box mb={3}>
                            <Alert
                                onClose={() => setAlertVisible(false)}
                                severity="error"
                            >
                                Failed to submit data
                            </Alert>
                        </Box>
                    )}
                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={5}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box mt={1}>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.assetsModalContent}>
                                <Box>
                                    <TextField
                                        className={classes.formTextField}
                                        error={Boolean(
                                            touched.name && errors.name
                                        )}
                                        fullWidth
                                        helpertext={touched.name && errors.name}
                                        label="Name*"
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Staging NYC-2"
                                        value={values.name}
                                        variant="outlined"
                                    />

                                    {/*<TextField
                                        className={classes.formTextField}
                                        error={Boolean(
                                            touched.roleArn && errors.roleArn
                                        )}
                                        fullWidth
                                        helpertext={
                                            touched.roleArn && errors.roleArn
                                        }
                                        label="Role ARN"
                                        name="roleArn"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.roleArn}
                                        variant="outlined"
                                    />
                                    <Box margin={'10px 0'} textAlign="center">
                                        <Typography>- OR -</Typography>
                                    </Box>*/}
                                    {[
                                        {
                                            label: 'Access Key*',
                                            value: 'accessKey',
                                            placeholder: 'AKIAIOSFODNN7EXAMPLE',
                                        },
                                        {
                                            label: 'Secret Key*',
                                            value: 'secretKey',
                                            placeholder:
                                                'wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY',
                                        },
                                        {
                                            label: 'Session Token',
                                            value: 'sessionToken',
                                        },
                                    ].map(({ label, value, placeholder, index }) => {
                                        return (
                                            <TextField
                                                className={
                                                    classes.formTextField
                                                }
                                                error={Boolean(
                                                    touched[value] &&
                                                        errors[value]
                                                )}
                                                fullWidth
                                                helpertext={
                                                    touched[value] &&
                                                    errors[value]
                                                }
                                                label={label}
                                                name={`${value}`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder={placeholder}
                                                value={values[value]}
                                                variant="outlined"
                                                key={index}
                                            />
                                        )
                                    })}
                                </Box>

                                {Boolean(touched.policy && errors.policy) && (
                                    <FormHelperText error>
                                        {errors.policy}
                                    </FormHelperText>
                                )}</div>
                                <Box
                                    mt={3}
                                    justifyContent="center"
                                    display="flex"
                                >
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting || !isValid}
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        align="center"
                                    >
                                        Create
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    )}
                </Box>
            )}
        </Formik>
    )
}

AWSEntry.propTypes = {
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    formFields: PropTypes.array,
    modalHeading: PropTypes.string,
}

AWSEntry.defaultProps = {}

export default AWSEntry
