import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormHelperText,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    makeStyles,
    Modal,
    Paper,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons/'

import useLoader from 'src/hooks/useLoader'
import { useToastContext } from 'src/context/ToastContext'
import { AlreadyRegistered as DisableMFAForm, QRCodeForm } from "src/views/Profile/MFASettings";
import useAuth from 'src/hooks/useAuth'

const useStyles = makeStyles(() => ({
    root: {},
    cardInput: {
        width: '49%',
    },
    passwordButton: {
        width: '184px',
    },
    mfaQrModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cartHeading: {
        fontSize: '20px'
    }
}))

const isMFAEnabled = (mfaType) => {
    if (mfaType === "SOFTWARE_TOKEN_MFA") {
        return true
    }
    return false
}


const Password = ({ className, ...rest }) => {
    const classes = useStyles()
    const [showMFAQRCode, setShowMFAQRCode] = useState(false)
    const [showDisableMFAModel, setShowDisableMFAModel] = useState(false)
    const [showCurrentPassword, toggleCurrentPassword] = useState(false)
    const [showNewPassword, toggleNewPassword] = useState(false)
    const { sendToast } = useToastContext()
    const [loading, showLoader, hideLoader] = useLoader()
    const [qrCode, setQrCode] = useState("")
    const authContext = useAuth()
    const [mfaType, setMFAType] = useState()

    const EnableDisableMFAButton = ({ isMFAEnabled }) => {
        if (isMFAEnabled) {
            return (<Button
                color='primary'
                size='small'
                variant='contained'
                align='center'
                onClick={async () => {
                    showLoader()
                    setShowDisableMFAModel(true)
                    hideLoader()
                }}
            >
                Disable Multi-Factor Authentication
            </Button>)
        }
        return (<Button
            color='primary'
            size='small'
            variant='contained'
            align='center'
            onClick={async () => {
                showLoader()
                setShowMFAQRCode(true)
                await setupTOTP()
                hideLoader()
            }}
        >
            Enable Multi-Factor Authentication
        </Button>)
    }

    const setupTOTP = async () => {
        await authContext.setupTOTP().then((res) => {
            setQrCode(res)
        })
    }

    const handleEnableSubmit = async (values) => {
        showLoader()
        await authContext.verifyTotpToken(values.verificationCode).then(() => {
            setShowMFAQRCode(false)
            sendToast("Multi-Factor Authentication enabled. Please login again.")
        }).catch(err => {
            if (err.code === "EnableSoftwareTokenMFAException") {
                sendToast("Invalid verification code.", "error")
            } else {
                sendToast("Error verifying token.", "error")
                setShowMFAQRCode(false)
            }
        }).finally(() => {
            hideLoader()
        })
    }

    const handleDisableMFA = async (values) => {
        showLoader()
        await getCurrentPreferredMFAType()
        await authContext.disableMFA(values.verificationCode).then(() => {
            sendToast("Multi-Factor Authentication disabled. Please login again.")
            setShowDisableMFAModel(false)
        }).catch((err) => {
            if (err.code === "EnableSoftwareTokenMFAException") {
                sendToast("Invalid verification code.", "error")
            } else {
                sendToast("Error disabling MFA.", "error")
                setShowDisableMFAModel(false)
            }
        }).finally(() => {
            hideLoader()
        })

    }

    const getCurrentPreferredMFAType = async () => {
        await authContext.currentPreferredMFAType().then((data) => {
            setMFAType(data)
        })
    }
    useEffect(() => {
        getCurrentPreferredMFAType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mfaType])

    return (
        <Grid item md={7} xs={12}>
            {loading}
            <Formik
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    submit: null,
                }}
                validationSchema={Yup.object().shape({
                    currentPassword: Yup.string()
                        .min(7, 'Must be at least 7 characters')
                        .max(255)
                        .required('Required'),
                    newPassword: Yup.string()
                        .min(7, 'Must be at least 7 characters')
                        .max(255)
                        .required('Required'),
                })}
                onSubmit={async (
                    values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        setSubmitting(true)
                        authContext.updateUserPassword({
                            oldPassword: values.currentPassword,
                            newPassword: values.newPassword,
                        })
                        setStatus({ success: true })
                        sendToast("Password updated successfully. Please login again.")
                    } catch (err) {
                        console.error(err)
                        setStatus({ success: false })
                        setErrors({ submit: err.message })
                        sendToast("Error updating password.", "error")
                        setSubmitting(false)
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Card
                                className={clsx(classes.root, className)}
                                {...rest}
                            >
                                <CardHeader titleTypographyProps={{ variant: 'h4' }} title='Password' />
                                <Divider />
                                <CardContent>
                                    <Box
                                        display={'flex'}
                                        width='100%'
                                        justifyContent='space-between'
                                    >
                                        <FormControl
                                            variant='outlined'
                                            fullWidth
                                            className={classes.cardInput}
                                        >
                                            <InputLabel htmlFor='outlined-current-password'>
                                                Current Password
                                            </InputLabel>
                                            <OutlinedInput
                                                id='outlined-current-password'
                                                error={Boolean(
                                                    touched.currentPassword &&
                                                    errors.currentPassword
                                                )}
                                                helpertext={
                                                    touched.currentPassword &&
                                                    errors.currentPassword
                                                }
                                                label='Current Password'
                                                name='currentPassword'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type={
                                                    showCurrentPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                value={values.currentPassword}
                                                variant='outlined'
                                                endAdornment={
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={() => {
                                                                toggleCurrentPassword(
                                                                    !showCurrentPassword
                                                                )
                                                            }}
                                                            onMouseDown={(e) =>
                                                                e.preventDefault
                                                            }
                                                            edge='end'
                                                        >
                                                            {showCurrentPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant='outlined'
                                            fullWidth
                                            className={classes.cardInput}
                                        >
                                            <InputLabel htmlFor='outlined-current-password'>
                                                New Password
                                            </InputLabel>
                                            <OutlinedInput
                                                error={Boolean(
                                                    touched.newPassword &&
                                                    errors.newPassword
                                                )}
                                                helpertext={
                                                    touched.newPassword &&
                                                    errors.newPassword
                                                }
                                                label='New Password'
                                                name='newPassword'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type={
                                                    showNewPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                value={values.newPassword}
                                                variant='outlined'
                                                endAdornment={
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={() => {
                                                                toggleNewPassword(
                                                                    !showNewPassword
                                                                )
                                                            }}
                                                            onMouseDown={(e) =>
                                                                e.preventDefault
                                                            }
                                                            edge='end'
                                                        >
                                                            {showNewPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                    {errors.submit && (
                                        <Box mt={3}>
                                            <FormHelperText error>
                                                {errors.submit}
                                            </FormHelperText>
                                        </Box>
                                    )}
                                </CardContent>
                                <Divider />
                                <Box
                                    p={2}
                                    display='flex'
                                    justifyContent='space-between'
                                >
                                    <Button
                                        color='primary'
                                        className={classes.passwordButton}
                                        disabled={isSubmitting}
                                        type='submit'
                                        variant='contained'
                                        size='small'
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress
                                                size={24}
                                                color='white'
                                            />
                                        ) : (
                                            'Change Password'
                                        )}
                                    </Button>
                                    <EnableDisableMFAButton isMFAEnabled={isMFAEnabled(mfaType)}></EnableDisableMFAButton>
                                </Box>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
            <Modal
                open={showMFAQRCode}
                onClose={(e) => { setShowMFAQRCode(false) }}
                className={classes.mfaQrModal}
            >
                <Paper>
                    <Box padding={8}>
                        <QRCodeForm onSubmit={handleEnableSubmit} qrCode={qrCode}></QRCodeForm>
                    </Box>
                </Paper>
            </Modal>
            <Modal
                open={showDisableMFAModel}
                onClose={(e) => { setShowDisableMFAModel(false) }}
                className={classes.mfaQrModal}
            >
                <Paper>
                    <Box padding={3}>
                        <DisableMFAForm onSubmit={handleDisableMFA}></DisableMFAForm>
                    </Box>
                </Paper>
            </Modal>
        </Grid >

    )
}

Password.propTypes = {
    className: PropTypes.string,
}

export default Password
