
import { useState, useEffect } from 'react';

let globalState = {
  serviceEnabled: []
};

let listeners = [];

export const useGlobalState = () => {
  const [, setState] = useState(globalState);

  useEffect(() => {
    listeners.push(setState);
    return () => {
      listeners = listeners.filter((li) => li !== setState);
    };
  }, []);

  const setServiceEnabled = (data) => {
    globalState = { ...globalState, serviceEnabled: data };
    listeners.forEach((listener) => listener(globalState));
  };

  return { serviceEnabled: globalState.serviceEnabled, setServiceEnabled };
};
