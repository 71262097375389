import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, makeStyles } from '@material-ui/core'

const headingText = {
    GREAT:
        "This security posture represents little to no risk to your organization's environment.",

    GOOD:
        "This security posture represents a minimal risk to your organization's environment. Potential threats are often theoretical in nature or might require unique circumstances to leverage.",

    OKAY:
        "This security posture represents a moderate risk to your organization's environment and should be fixed as soon as time allows.",

    POOR:
        "This security posture poses a significant risk to your organization's environment and should be fixed as soon as possible.",

    CRITICAL:
        "This security posture poses a serious risk to your organization's environment and should be resolved immediately.",

    NEUTRAL:
        'When security posture information has been populated, your IMMINENT RISK posture will appear here.',
}

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        margin: `24px 0`,
        marginLeft: theme.spacing(3),
        minWidth: '400px',
        maxWidth: '550px',
        fontSize: '53px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '57px',
        letterSpacing: '0em',
        textAlign: 'left',
    },
    textcontainer: {
        fontSize: '16px',
        marginLeft: theme.spacing(3),
        marginBottom: '26px',
        width: '400px',
    },
}))

const criticalColorStyle = (isCritical) =>
    isCritical ? { color: '#ac0000' } : {}

const DiscoverHeading = ({ securityLevel, securityScore, name }) => {
    const classes = useStyles()
    const isNeutral = securityLevel === 'NEUTRAL' || securityScore === 101
    const effectiveSecurityLabel = isNeutral ? 'NEUTRAL' : securityLevel
    return (
        <Box className={classes.root}>
            <Typography variant='h1' className={classes.heading}>
                {isNeutral
                    ? 'Welcome to Ultraviolet.'
                    : `Your security 
              posture is
              currently `}
                {!isNeutral && (
                    <span
                        style={criticalColorStyle(
                            effectiveSecurityLabel === 'CRITICAL'
                        )}
                    >
                        {name.toLowerCase()}
                    </span>
                )}
            </Typography>
            <Box>
                <Typography className={classes.textcontainer}>
                    {headingText[effectiveSecurityLabel]}
                </Typography>
            </Box>
        </Box>
    )
}

DiscoverHeading.defaultProps = {
    securityLevel: 'NEUTRAL',
}

DiscoverHeading.propTypes = {
    securityLevel: PropTypes.string,
    securityScore: PropTypes.number,
    name: PropTypes.string,
}

export default DiscoverHeading
