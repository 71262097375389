import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    AppBar,
    IconButton,
    Hidden,
    Toolbar,
    makeStyles,
    Box,
} from '@material-ui/core'
import { ReactComponent as S2Svg } from 'src/theme/s2/S2_Logo_ForWeb-RGB_FULL_White.svg'
import Account from './Account'
import { THEMES } from 'src/constants'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.S2
            ? {
                boxShadow: 'none',
                backgroundColor: theme.palette.primary.main,
            }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                backgroundColor: theme.palette.background.dark,
            }
            : {}),
        display: 'flex',
        height: '68px',
    },
    toolbar: {
        minHeight: 64,
        alignSelf: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    headerButton: {
        height: '50px',
        width: '50px',
        margin: '0 10px'
    },
    hamburgerbutton: {},
    hamburger: {
        color: 'white',
    },
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles()

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Box style={{ marginLeft: '25px' }}>
                    <Hidden lgUp>
                        <IconButton
                            onClick={onMobileNavOpen}
                            className={classes.hamburgerbutton}
                        >
                            <MenuRoundedIcon className={classes.hamburger} />
                        </IconButton>
                    </Hidden>
                    <IconButton
                        to='/'
                        component={RouterLink}
                        className={classes.headerButton}
                    >
                        <S2Svg />
                    </IconButton>
                </Box>
                <Account className={classes.headerButton} />
            </Toolbar>
        </AppBar>
    )
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
}

TopBar.defaultProps = {
    onMobileNavOpen: () => { },
}

export default TopBar
