export const APP_VERSION = '3.1.0'

export const ENABLE_REDUX_DEV_TOOLS = true

export const CRITICALITY_ORDER = [
    'CRITICAL',
    'HIGH',
    'MEDIUM',
    'LOW',
    'INFORMATIONAL',
]

export const CHART_COLORS = [
    '#10b981',
    '#f43f5e',
    '#06b6d4',
    '#ef4444',
    '#f97316',
    '#6366f1',
    '#EC4899',
    // '#E6F600',
    '#10B981',
    '#64748b',
    '#ec4899',
]
export const CRITICALITY_COLORS = [
    { label: 'INFORMATIONAL', value: '#6314FF' },
    { label: 'LOW', value: '#e6f600' },
    { label: 'MEDIUM', value: '#f6960c' },
    { label: 'HIGH', value: '#ba2e00' },
    { label: 'CRITICAL', value: '#ac0000' },
]
export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN',
    S2: 'S2',
}

export const USER_PERMISSION = [
    { id: 2, role: 'PORTAL ADMIN', value: 'PORTAL_ADMIN' },
    { id: 3, role: 'PORTAL USER', value: 'PORTAL_USER' },
]
export const REASON_LIST = [
    { key: 'FALSE POSITIVE', value: 'FALSE_POSITIVE' },
    { key: 'DUPLICATE', value: 'DUPLICATE' },
    { key: 'OTHER', value: 'OTHER' },
]

export const REPORT_TYPES = [
    'PEN_TEST',
    'OSINT',
    'CSPM',
    'VULNERABILITY',
    'MISCELLANEOUS',
]

export const SUBMENU_CAPITAL = ['Attack', 'Detect']

// we should probably pull these from API so things are just magic
export const ATTACK_SURFACES = {
    OSINT: "OSINT",
    INTERNAL: "Internal",
    EXTERNAL: "External",
    PHISHING: "Phishing",
    CLOUD: "Cloud",
    MOBILE_APP: "Mobile Application",
    WEB_APP: "Web Application",
    DESKTOP_APP: "Desktop Application",
    WIRELESS: "Wireless",
    CODE_REVIEW: "Code Review",
    HARDWARE: "Hardware",
    PHYSICAL: "Physical",
    EMPLOYEE: "Employee",
}