import React from 'react'

import {
    Box,
    Button,
    Divider,
    TextField,
    Typography,
    makeStyles,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogTitle,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { USER_PERMISSION } from 'src/constants';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useToastContext } from 'src/context/ToastContext';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: 'white',
        },
        modal: {
            // width: '675px',
            // height: `calc(825px + ${theme.spacing(6)}px + ${theme.spacing(
            //     4
            // )}px)`,
            // margin: `${theme.spacing(2)}px auto`,
        },
        modalContent: { backgroundColor: theme.palette.background.default },
        modalForm: {
            padding: theme.spacing(2),
        },
        modalHeader: {
            fontSize: '1.2146rem',
            fontWeight: '500'
        },
        textField: {
            margin: theme.spacing(2),
        },
        userModalButton: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
        },
        closeIcon: {
            position:'absolute',
            right:'15px',
            cursor: 'pointer',
            top: '14px'
        },
        modelConente:{
            maxHeight: 'calc(100vh - 242px)',
            overflowY: 'auto',
            paddingRight: '5px'
        }
    }
})

export default function UserCreateModal({ onClose, onUserCreate, active }) {
    const classes = useStyles()
    const { sendToast } = useToastContext()
    const initialUserDetails = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        role: ''
    }
    const formValidationSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        emailAddress: Yup.string().email().required('Required'),
        role: Yup.string().required('Required'),
    })
    return (
        <Dialog className={classes.modal} open={active} >

            <Formik
                initialValues={initialUserDetails}
                validationSchema={formValidationSchema}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    await onUserCreate(values)
                        .then((response) => {
                            setSubmitting(false)
                            resetForm()
                            if (response.status === 200) {
                                sendToast("User Created Successfully");
                                onClose()
                            } else if (response.status === 422) {
                                sendToast("Email Address Already Exists", "warning");
                            } else {
                                sendToast(response.data.detail, "error");
                            }

                        })
                }}
            >

                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <Box
                        style={{ outline: 'none' }}
                        className={classes.modalContent}
                    >
                        <form onSubmit={handleSubmit}>
                            
                            <DialogTitle >
                                <Typography 
                                    color="textPrimary"
                                    width="fit-content"
                                    className={classes.modalHeader}
                                    >Create User Account </Typography>
                                <IconButton className={classes.closeIcon} size='small' onClick={() => {
                                            onClose(null)
                                        }} >
                                    <CloseIcon  />
                                </IconButton>
                            </DialogTitle>

                            <Divider width={'100%'} />
                            <Box className={classes.modalForm}>
                                <div className={classes.modelConente}>
                                <Box display="flex">
                                    <TextField
                                        className={classes.textField}
                                        error={Boolean(
                                            touched.firstName &&
                                            errors.firstName
                                        )}
                                        fullWidth
                                        helpertext={
                                            touched.firstName &&
                                            errors.firstName
                                        }
                                        label="First Name"
                                        name="firstName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstName}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        error={Boolean(
                                            touched.lastName &&
                                            errors.lastName
                                        )}
                                        fullWidth
                                        helpertext={
                                            touched.lastName &&
                                            errors.lastName
                                        }
                                        label="Last Name"
                                        name="lastName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.lastName}

                                        variant="outlined"
                                    />
                                </Box>
                                <Box>
                                    <FormControl className={classes.textField}
                                        variant="outlined" style={{ width: '95%' }}
                                        error={Boolean(
                                            touched.role &&
                                            errors.role
                                        )}
                                        fullWidth
                                        helpertext={
                                            touched.role &&
                                            errors.role
                                        }
                                    >
                                        <InputLabel htmlFor="Roletype">Role</InputLabel>

                                        <Select
                                            id="Roletype"
                                            label="Role "
                                            name="role"
                                            variant="outlined"
                                            placeholder="Role *"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.role}
                                        >
                                            {USER_PERMISSION.map((_role, index) => 
                                            <MenuItem key={index} value={_role.value}>{_role.role}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <TextField
                                        className={classes.textField}
                                        error={Boolean(
                                            touched.emailAddress &&
                                            errors.emailAddress
                                        )}
                                        fullWidth
                                        helpertext={
                                            touched.emailAddress &&
                                            errors.emailAddress
                                        }
                                        style={{ width: '95%' }}
                                        label="Email Address"
                                        name="emailAddress"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.emailAddress}
                                        variant="outlined"
                                    />
                                </Box>
                                </div>
                                <Box className={classes.userModalButton}>

                                    <Button
                                        color="secondary"
                                        onClick={() => {
                                            onClose(null)
                                        }}
                                        variant="contained"
                                        size="small"
                                    >
                                        Cancel
                                    </Button> <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                    >
                                        Create
                                    </Button>

                                </Box>
                            </Box>
                        </form>
                    </Box>
                )}
            </Formik>

        </Dialog>
    )
}
