import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Box,
    Button,
    CardContent,
    makeStyles,
    Paper,
    Typography,
    Input,
} from '@material-ui/core'
import { CloudUpload, GetApp } from '@material-ui/icons'
import { uploadAsset } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
import SampleAsset from 'src/assets/SampleAssets.csv'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '572px',
        margin: '0 auto',
        width: '572px'
    },
    assetsModalContent : {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 380px)',
        paddingRight:'5px'
    }
}))

const UploadModal = ({
    modalHeading,
    closeModal,
    variation = 'assets',
    onAssetRefresh,
}) => {
    const classes = useStyles()
    const [assetFormData, setAssetFormData] = useState(new FormData())
    const [fileName, setFileName] = useState('')

    const { sendToast } = useToastContext()
    const handleSubmit = async () => {
        await uploadAsset(assetFormData)
            .then(async (response) => {
                await onAssetRefresh()
            })
            .catch((err) => {
                console.log('uploadAsset', err)
                sendToast(
                    `Error uploading asset: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => closeModal())
        //sendToast(response.msg && "Asset Uploaded Succesfully")
    }
    const handleChange = (event) => {
        let file = event.target.files[0]
        if (file.name.lastIndexOf('.csv') !== -1) {
            assetFormData.append('upload_file', file)
            setAssetFormData(assetFormData)
            setFileName(file.name)
        } else {
            sendToast('Only CSVs are allowed', 'warning')
        }
    }
    return (
        <Box
            className={clsx('CreateAsset', classes.root)}
        >
            <Paper>
                <CardContent>
                <div className={classes.assetsModalContent}>
                    <Box mt={2}>
                        <a href={SampleAsset} download={'SampleAsset.csv'}>
                            <Button
                                color="primary"
                                size="medium"
                                variant="contained"
                                align="center"
                                startIcon={<GetApp />}
                                style={{ float: 'right' }}
                            >
                                Sample Asset CSV
                            </Button>
                        </a>
                    </Box>

                    <Box justifyContent="center" display="flex" mt={12}>
                        <label htmlFor="assetUpload">
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                align="center"
                            >
                                {fileName}
                            </Typography>
                            <Input
                                onChange={handleChange}
                                style={{ display: 'none' }}
                                accept="csv/*"
                                id="assetUpload"
                                type="file"
                            />
                            <Button
                                align="center"
                                variant="contained"
                                startIcon={<CloudUpload />}
                                color="default"
                                aria-label="upload picture"
                                component="span"
                            >
                                Upload
                            </Button>
                        </label>
                    </Box>
                    </div>
                    <Box mt={5} justifyContent="right" display="flex">
                        <Button
                            color="primary"
                            disabled={fileName.length ? false : true}
                            size="small"
                            variant="contained"
                            align="center"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </CardContent>
            </Paper>
        </Box>
    )
}

UploadModal.propTypes = {
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    formFields: PropTypes.array,
    modalHeading: PropTypes.string,
    variation: PropTypes.string,
}

UploadModal.defaultProps = {}

export default UploadModal
