import {
    Box,
    makeStyles,
    Paper,
    Divider,
    CardContent,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { pdfjs } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import { acceptEula } from 'src/actions/openapi'
import { ReactComponent as LogoDark } from 'src/theme/s2/Ultraviolet_Logo_Primary_RGB.svg'
import EULAAgreement from 'src/assets/EULAAgreement.pdf'
import { useToastContext } from 'src/context/ToastContext'
import useLoader from 'src/hooks/useLoader'
import useAuth from 'src/hooks/useAuth'
import { Redirect } from 'react-router'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const useStyles = makeStyles((theme) => ({
    actions: {
        margin: '2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    confirmActions: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttons: {
        margin: '5px',
    },
    documentContainer: {
        display: 'flex',
        justifyContent: 'center',
        maxHeight: '400px',
        overflow: 'auto',
    },
}))

const ConfirmDisagreeDialog = ({ open, setOpen }) => {
    const auth = useAuth()
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    In order to access this application you must agree to the
                    terms of service.
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.confirmActions}>
                <Button
                    color="primary"
                    variant="contained"
                    align="center"
                    onClick={() => setOpen(false)}
                >
                    Agree
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        auth.logout()
                    }}
                >
                    Log out
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default function EULA() {
    const classes = useStyles()
    const { sendToast } = useToastContext()
    const [loading, showLoader, hideLoader] = useLoader()
    const auth = useAuth()
    const [numPages, setNumPages] = useState(null)
    const [showDialog, setShowDialog] = useState(false)

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages)
    }

    const handleAcceptEula = async () => {
        showLoader()
        acceptEula()
            .then((response) => {
                auth.acceptEula()
            })
            .catch((error) => {
                sendToast(
                    'An error occurred while accepting the EULA. Please try again.',
                    'error'
                )
            })
            .finally(() => {
                hideLoader()
            })
    }

    const handleDisagreeEula = async () => {
        setShowDialog(true)
    }

    if (!auth.isAuthenticated) {
        return <Redirect to="/"></Redirect>
    }

    if (auth.isEulaAccepted) {
        return <Redirect to="/"></Redirect>
    }

    return (
        <Box maxWidth={800} margin="auto" marginTop="5em" marginBottom="2em">
            {loading}
            <ConfirmDisagreeDialog
                open={showDialog}
                setOpen={setShowDialog}
            ></ConfirmDisagreeDialog>
            <Paper>
                <CardContent>
                    <Box
                        pt={1}
                        pb={4}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <LogoDark width="75%" height="7em" />
                        <Typography
                            variant="h2"
                            color="textPrimary"
                            align="center"
                        >
                            EULA Agreement
                        </Typography>
                    </Box>
                    <Divider />
                    <Box mt={3} className={classes.documentContainer}>
                        <Document
                            file={EULAAgreement}
                            onLoadSuccess={onDocumentLoadSuccess}
                            className={classes.document}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    className={classes.page}
                                />
                            ))}
                        </Document>
                    </Box>
                    <Box className={classes.actions}>
                        <Button
                            className={classes.buttons}
                            color="primary"
                            size="large"
                            variant="contained"
                            align="center"
                            fullWidth={false}
                            onClick={handleAcceptEula}
                        >
                            Accept and Continue
                        </Button>
                        <Button
                            className={classes.buttons}
                            size="small"
                            onClick={handleDisagreeEula}
                        >
                            Disagree
                        </Button>
                    </Box>
                </CardContent>
            </Paper>
        </Box>
    )
}
