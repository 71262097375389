import React, { useEffect, useState } from 'react'
import { Box, Divider, Paper, Typography, makeStyles } from '@material-ui/core'
import SignalsTable from '../Signals/SignalsList'
import { getEngagement } from 'src/actions/openapi'
import CredentialCard from './Credential'
import EngagementAssets from './EngagementAsset'
import EngagementContext from './EngagementContext'
import { useToastContext } from 'src/context/ToastContext'

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },
    cardRoot: {
        padding: theme.spacing(1),
    },
    cardContainer: {
        padding: theme.spacing(1),
        // boxShadow: `2px 2px 5px ${theme.palette.background.silver},
        //     -2px 0 5px ${theme.palette.background.silver},
        //     2px -2px 5px ${theme.palette.background.silver}`,
    },
    findingsRoot: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    cardHeading: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    detailBox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    engagementMetaContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
    },
    engagementMeta: {
        flexDirection: 'column',
        display: 'flex',
        marginTop: '16px',
    },
}))

const Detail = ({ title, value }) => {
    return (
        <Box>
            <Typography>{title}</Typography>
            <Typography
                style={{
                    fontSize: '14px',
                    marginTop: '4px',
                }}
                color="textSecondary"
            >
                {value}
            </Typography>
        </Box>
    )
}

const EngagementCard = ({ engagement }) => {
    const elevated = true
    const classes = useStyles()

    return (
        <Paper className={classes.cardRoot} elevation={elevated ? 1 : 1}>
            <Typography variant="h3" className={classes.cardHeading}>
                {engagement.name}
            </Typography>
            <Divider></Divider>
            <Box className={classes.engagementMetaContainer}>
                <Box className={classes.detailBox}>
                    <Detail title="Type" value={engagement.engagement_type} />
                </Box>
                <Box className={classes.detailBox}>
                    <Detail
                        title="Continuous"
                        value={engagement.continuous ? 'Yes' : 'No'}
                    />
                </Box>
                <Box className={classes.detailBox}>
                    <Detail title="Reason" value={engagement.reason} />
                </Box>
                <Box className={classes.detailBox}>
                    <Detail
                        title="Created Date"
                        value={engagement.created_date}
                    />
                </Box>
                {engagement.start_date ? (
                    <Box className={classes.detailBox}>
                        <Detail
                            title="Start Date"
                            value={engagement.start_date}
                        />
                    </Box>
                ) : null}
                {engagement.completed_date ? (
                    <Box className={classes.detailBox}>
                        <Detail
                            title="Completed Date"
                            value={engagement.completed_date}
                        />
                    </Box>
                ) : null}
                {engagement.standards?.length ? (
                    <Box className={classes.detailBox}>
                        <Typography>Standards</Typography>
                        {engagement.standards?.map(({ name, other_text }) => {
                            return (
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        marginLeft: '16px',
                                        marginTop: '4px',
                                    }}
                                    color="textSecondary"
                                >
                                    {name}
                                </Typography>
                            )
                        })}
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
        </Paper>
    )
}

const EngagementDetail = ({ match }) => {
    const classes = useStyles()
    const engagementId = match.params.engagementId
    const [engagement, setEngagement] = useState({})
    const { sendToast } = useToastContext()

    async function asyncFetchEngagement() {
        const results = await getEngagement(match.params.engagementId)
        setEngagement(results)
    }

    useEffect(() => {
        asyncFetchEngagement().catch((err) => {
            console.log('asyncFetchEngagement', err)
            sendToast(
                `Error getting engagement: ${err?.response?.data?.detail}`,
                'error'
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <EngagementContext.Provider
            value={{ engagement, asyncFetchEngagement, engagementId }}
        >
            <Box className={classes.root}>
                <EngagementCard engagement={engagement}></EngagementCard>
                <Box className={classes.cardContainer}></Box>
                <Box display={'flex'}>
                    <Box className={classes.cardContainer} width={'50%'}>
                        <EngagementAssets></EngagementAssets>
                    </Box>
                    <Box className={classes.cardContainer} width={'50%'}>
                        <CredentialCard></CredentialCard>
                    </Box>
                </Box>
                <Box className={classes.cardContainer}>
                    <SignalsTable
                        initialFilters={{
                            criticality: [],
                            type: [],
                            attack_surface: [],
                            project: [],
                            engagement: [engagementId],
                        }}
                    ></SignalsTable>
                </Box>
                <Box className={classes.findingsRoot}></Box>
            </Box>
        </EngagementContext.Provider>
    )
}

export default EngagementDetail
