import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Auth } from 'aws-amplify';
import MFAAddDevice from 'src/views/Auth/MFAAddDevice';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isLoginConfirmationInProgress: false,
  isMFAAddDeviceInProgress: false,
  user: null,
  isEulaAccepted: false,
  authToken: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE':
      return { ...state, ...action.payload, isInitialised: true };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        authToken: null,
      };
    case 'RESET_AUTH_STATE':
      return initialAuthState;
    case 'ACCEPTEULA':
      return { ...state, isEulaAccepted: true };
    default:
      return state;
  }
};

const handleError = (error, message) => {
  console.error(message, error);
};

const setAxiosAuthorizationHeader = (idToken) => {
  axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
};

const clearAxiosAuthorizationHeader = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  logout: () => {},
  register: () => Promise.resolve(),
  setUser: () => {},
  currentPreferredMFAType: () => Promise.resolve(),
  setupTOTP: () => Promise.resolve(),
  disableMFA: () => Promise.resolve(),
  setMFAPreference: () => Promise.resolve(),
  verifyTotpToken: () => Promise.resolve(),
  acceptEula: () => {},
  updateUserPassword: () => {},
  authToken: null,
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [user, setUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['id_token']);
  const removeCognitoLocalStorage = () => {
    localStorage.removeItem("idToken")
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('CognitoIdentityServiceProvider')) {
        localStorage.removeItem(key);
      }
    });
  };
  const setSession = (idToken) => {
    if (idToken) {
      localStorage.setItem('idToken', idToken);
      setCookie('id_token', `Bearer ${idToken}`, {
        path: '/',
        domain: process.env.NODE_ENV === 'production'
          ? 'uvcyber.io'
          : 'uvcyber.io',
        sameSite: 'none',
        secure: true,
      });
      setAxiosAuthorizationHeader(idToken);
      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: true, user, authToken: idToken },
      });
    } else {
      removeCookie('id_token');
      removeCognitoLocalStorage()
      clearAxiosAuthorizationHeader();
      dispatch({ type: 'LOGOUT' });
    }
  };

  const resetAuthStateAndReinitialize = async () => {
    dispatch({ type: 'RESET_AUTH_STATE' });
    await initialise();
  };

  const initialise = async () => {
    try {
      const user = await Auth.currentUserPoolUser();
      await checkAndRefreshTokens();
      setUser(user);
      const idToken = user.signInUserSession.getIdToken().getJwtToken();
      setSession(idToken);
    } catch (err) {
      handleError(err, 'Error initializing');
      Auth.federatedSignIn();
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null,
          authToken: null,
        },
      });
    }
  };

  const checkAndRefreshTokens = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      setSession(idToken);
    } catch (err) {
      handleError(err, 'Error refreshing token');
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null,
          authToken: null,
        },
      });
    }
  };

  const logout = async () => {
    try {
      const config = await Auth._config;
      const logoutURL = `https://${config.oauth.domain}/logout?client_id=${config.userPoolWebClientId}&response_type=code&scope=${config.oauth.scope.join('+')}&redirect_uri=${encodeURIComponent(config.oauth.redirectSignIn)}`;

      setSession(null, setCookie, removeCookie);
      window.open(logoutURL, '_self');
    } catch (error) {
      handleError(error, 'Error during logout');
    }
  };

  const setMFAPreference = async (
    smsMfaSettings = null,
    totpMfaSettings = null
  ) => {
    try {
      await user.setUserMfaPreference(smsMfaSettings, totpMfaSettings);
    } catch (err) {
      handleError(err, 'Error setting MFA preference');
    }
  };

  const generateQRCode = (email, code) =>
    `otpauth://totp/Warrior:${email}?secret=${code}&issuer=stage2sec`;

  const currentPreferredMFAType = async () => {
    try {
      const currentUser = user || (await Auth.currentAuthenticatedUser());
      return await Auth.getPreferredMFA(currentUser, {
        bypassCache: true,
      });
    } catch (error) {
      handleError(error, 'Error getting preferred MFA type');
      return null;
    }
  };

  const setupTOTP = async () => {
    const code = await Auth.setupTOTP(user);
    return generateQRCode(user.attributes.email, code);
  };

  const disableMFA = async (code) => {
    try {
      await Auth.verifyTotpToken(user, code, { bypassCache: true });
      await Auth.setPreferredMFA(user, 'NOMFA', { bypassCache: true });
    } catch (error) {
      handleError(error, 'Error disabling MFA');
    } finally {
      logout();
    }
  };

  const verifyTotpToken = async (challengeAnswer) => {
    try {
      await Auth.verifyTotpToken(user, challengeAnswer, {
        bypassCache: true,
      });
      await Auth.setPreferredMFA(user, 'TOTP', { bypassCache: true });
    } catch (error) {
      handleError(error, 'Error verifying TOTP token');
    } finally {
      logout();
    }
  };

  const acceptEula = () => dispatch({ type: 'ACCEPTEULA' });

  const updateUserPassword = async ({ oldPassword, newPassword }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      return { success: true };
    } catch (error) {
      console.error('Error changing password:', error);
      return { success: false, error: error.message };
    }finally{
      logout()
    }
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(checkAndRefreshTokens, 60000);
    return () => clearInterval(refreshInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isMFAAddDeviceInProgress) {
    return (
      <MFAAddDevice
        user={user}
        email={user?.attributes?.email}
        dispatch={dispatch}
      />
    );
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        logout,
        acceptEula,
        setUser,
        setupTOTP,
        resetAuthStateAndReinitialize,
        disableMFA,
        currentPreferredMFAType,
        setMFAPreference,
        verifyTotpToken,
        updateUserPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
