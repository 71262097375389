import React, { useState } from 'react'

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    makeStyles,
    Grid
} from '@material-ui/core'
import clsx from 'clsx'
import FileUpload from 'src/components/FileUpload'
import { uploadOrganizationLogo } from 'src/actions/openapi'
import AlertMessage from 'src/components/AlertMessage'

const useStyles = makeStyles(() => ({
    root: {marginTop:'15px'},
    cardInput: {
        width: '49%',
    },
    saveButton: {
        width: '148px',
    },
    logoUpload:{
        height:'60px'
    }
}))

const OrganizationSetting = () => {
    const classes = useStyles()
    const [files, setFiles] = useState([])
    const [isFileSelected, setFileSelected] = useState(false)
    const [showAlert, setShowAlert] = useState({
        isOpen: false,
        message: '',
        type: '',
    })

    const fileSelected = () => {
        if (files.length > 0) {
            setFileSelected((isFileSelected) => true)
            setShowAlert({
                isOpen: true,
                message: 'Image Saved Successfully',
                type: 'success',
            })
        }
    }

    return (        
            <Grid item md={7} xs={12}>
            <form>
            <Card className={clsx(classes.root)}>
                <CardHeader title="Organization Logo" titleTypographyProps={{variant:'h4' }}  />
                <Divider />
                <CardContent>
                    <Box
                        display={'flex'}
                        width="100%"
                        justifyContent="space-between"
                    >
                        <FileUpload
                            supported_formats=".jpeg, .png"
                            getter={files}
                            setter={setFiles}
                            className={classes.logoUpload}
                        ></FileUpload>
                    </Box>
                </CardContent>
                <Divider />
                <Box p={2} display="flex" justifyContent="flex-start">
                    <Button
                        color="primary"
                        className={classes.saveButton}
                        // disabled={isSubmitting}
                        type="button"
                        size='small'
                        onClick={() => {
                            async function handleSubmit() {
                                const res = await uploadOrganizationLogo({
                                    file: files[0],
                                })
                                if (res.url) {
                                    fileSelected()
                                } else {
                                    setFileSelected((isFileSelected) => true)
                                    setShowAlert({
                                        isOpen: true,
                                        message: 'Image Not Saved',
                                        type: 'error',
                                    })
                                }
                            }
                            handleSubmit()
                        }}
                        variant="contained"
                    >
                        Save
                    </Button>
                    {isFileSelected ? (
                        <AlertMessage
                            showAlert={showAlert}
                            setShowAlert={setShowAlert}
                        />
                    ) : (
                        ''
                    )}
                </Box>
            </Card>
            </form>
            </Grid>
        
    )
}

export default OrganizationSetting
