import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Card,
    Typography,
    makeStyles,
    Divider,
    Dialog,
    DialogTitle,
    IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import AssetsTable from './AssetsTable'

import CreateModal from './CreateAssetModal'
import UploadModal from './UploadAssetModal'

const useStyles = makeStyles((theme) => ({
    root: {},
    tableHeadingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2),
        alignItems: 'center',
    },
    tableHeading: {
        fontSize:'20px'
    },
    defaultTextContainer: {
        margin: theme.spacing(6),
        textAlign: 'center',
    },
    defaultTextHeader: {
        fontWeight: 'bold',
    },
    assetModal: {
        height: '500px',
        width: '500px',
    },    
    closeIcon: {
        position:'absolute',
        right:'15px',
        cursor: 'pointer',
        top: '14px'
    },
    assetsModalContent : {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 380px)',
        paddingRight:'5px'
    },
    modalHeader: {
        fontSize: '1.2146rem',
        fontWeight: '500'
    },
}))

const Scopes = ({
    addToEngage,
    assets,
    onAssetDelete,
    onAssetCreate,
    onAssetRefresh,
    onAddAssetToEngage,
}) => {
    const classes = useStyles()
    const [modelOpen, setModalOpen] = useState(false)
    const [modalType, setModalType] = useState(null)

    return (
        <Card className={classes.root}>
            <Dialog
                open={modelOpen}           
                
            >
                <DialogTitle className={classes.modalTitle}>
                <Typography className={classes.modalHeader} gutterBottom>{modalType === 'create'
                        ? 'Create Cloud Asset'
                        : 'Upload Cloud Asset'}</Typography>
                    <IconButton className={classes.closeIcon} size='small' onClick={() => {
                    setModalOpen(!modelOpen)
                }} >
                <CloseIcon  />
                </IconButton>
                </DialogTitle>
                {modalType === 'create' ? (
                    <CreateModal
                        modalHeading={'Create Cloud Asset'}
                        onFormPost={onAssetCreate}
                        closeModal={setModalOpen.bind(this, false)}
                    />
                ) : (
                    <UploadModal
                        modalHeading={'Upload Assets'}
                        closeModal={setModalOpen.bind(this, false)}
                        onAssetRefresh={onAssetRefresh}
                    />
                )}
            </Dialog>
            <Box className={classes.tableHeadingContainer}>
                <Typography variant="h4" className={classes.tableHeading}>
                    Assigned Assets
                </Typography>
                <Box>
                    <Button
                        color="primary"
                        onClick={() => {
                            setModalOpen(true)
                            setModalType('upload')
                        }}
                        style={{
                            alignSelf: 'center',
                            marginRight: '10px',
                        }}
                        variant="contained"
                        size="small"
                    >
                        Upload Asset
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            setModalOpen(true)
                            setModalType('create')
                        }}
                        style={{ alignSelf: 'center' }}
                        variant="contained"
                        size="small"
                    >
                        Create Asset
                    </Button>
                </Box>
            </Box>

            {assets.length <= 0 ? (
                <>
                    <Divider />
                    <Box className={classes.defaultTextContainer}>
                        <Typography className={classes.defaultTextHeader}>
                            Add Assets to Test
                        </Typography>
                        <Typography>
                            You can add IP addresses, Domains, CIDRs &amp;
                            Assets Groups
                        </Typography>
                    </Box>
                </>
            ) : (
                <AssetsTable
                    columnNames={[
                        { label: 'Alias', name: 'identifier' },
                        { label: 'Selector', name: 'assetType' },
                    ]}
                    addToEngage={addToEngage}
                    tableValues={assets}
                    onDeleteAction={async (id) => {
                        await onAssetDelete(id)
                    }}
                    onSubmit={onAddAssetToEngage}
                />
            )}
        </Card>
    )
}

export default Scopes

Scopes.propTypes = {
    assets: PropTypes.array,
    onAssetDelete: PropTypes.func.isRequired,
    onAssetCreate: PropTypes.func.isRequired,
}

Scopes.defaultProps = {
    assets: [],
}
