import React, { useRef, useEffect } from 'react'
import {
    Box, 
    CardContent,
    Button,
    TextField, 
    makeStyles, 
    Typography,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import QRCode from 'qrcode.react'

const useStyles = makeStyles(() => ({
    qrContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    enableMFAformContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    submitButton: {
        marginLeft: 10
    },
    disableBtn: {
        top: "11px",
        width: '150px',
        height: '45px'
    },
    error: {
        fontSize: '15px',
        color: 'red'
    }
}))

const formSchema = Yup.object().shape({
    verificationCode: Yup.string().required('Required')
})

export const QRCodeForm = ({ onSubmit, qrCode }) => {
    const classes = useStyles()
    const verificationCodeRef = useRef(null)

    useEffect(() => {
        if (verificationCodeRef.current) {
            verificationCodeRef.current.focus()
        }
    }, [])

    return (
        <CardContent className={classes.qrContainer}>
            <Typography variant='h2'>Enable Multi-Factor Authentication</Typography>
            <p>Scan the QR code and enter verification code to add device</p>
            <Box mt={3} mb={3} justifyContent='center' display='flex' flexDirection="column" alignItems="center">
                <QRCode value={qrCode} size={200} />
            </Box>
            <Box>
                <Formik
                    initialValues={{
                        verificationCode: '',
                    }}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, handleBlur, handleChange }) => (
                        <Form className={classes.enableMFAformContainer}>
                            <TextField
                                error={Boolean(touched.verificationCode && errors.verificationCode)}
                                label='Verification Code*'
                                name='verificationCode'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant='outlined'
                                inputRef={verificationCodeRef}
                                autoComplete='off' 
                                aria-errormessage=''
                            />
                            <Button
                                color='primary'
                                size='large'
                                type='submit'
                                variant='contained'
                                align='center'
                                className={classes.submitButton}
                            >
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </CardContent>
    )
}

export const AlreadyRegistered = ({ onSubmit }) => {
    const classes = useStyles()
    const verificationCodeRef = useRef(null)

    useEffect(() => {
        if (verificationCodeRef.current) {
            verificationCodeRef.current.focus()
        }
    }, [])

    return (
        <CardContent>
            <Box>
                <Formik
                    initialValues={{
                        verificationCode: '',
                    }}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, handleBlur, handleChange }) => (
                        <Form className={classes.formContainer}>
                            <Typography variant="h2">Disable Multi-Factor Authentication</Typography>
                            <p>Enter verification code received on authenticator app</p>
                            <TextField
                                error={Boolean(touched.verificationCode && errors.verificationCode)}
                                label='Verification Code*'
                                name='verificationCode'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant='outlined'
                                inputRef={verificationCodeRef}
                                autoComplete='off' 
                            />
                            <Button
                                className={classes.disableBtn}
                                variant='contained'
                                color='primary'
                                align='center'
                                size='large'
                                type='submit'
                            >
                                Disable
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </CardContent>
    )
}
