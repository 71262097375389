import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IndividualSignal from './IndividualSignal'
import { useToastContext } from 'src/context/ToastContext'

import { getIndividualFinding } from 'src/actions/openapi'
import camelcaseKeys from 'camelcase-keys'

const IndividualSignalPath = ({ match }) => {
    const [finding, setIndividualFinding] = useState({});
    const { sendToast } = useToastContext();

    const asyncGetIndividualFinding = async () => {
        try {
            const finding = await getIndividualFinding(match.params.findingId);
            setIndividualFinding(camelcaseKeys(finding));
        } catch (err) {
            console.log('asyncGetIndividualFinding', err);
            sendToast(
                `Error getting finding: ${err?.response?.data?.detail}`,
                'error'
            );
        }
    };

    useEffect(() => {
        asyncGetIndividualFinding();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.findingId]);
    return <IndividualSignal {...finding} asyncGetIndividualFinding={asyncGetIndividualFinding} onApiResponse={(response) =>
        setIndividualFinding(camelcaseKeys(response))
    } />
}

export default IndividualSignalPath

IndividualSignalPath.propTypes = {
    match: PropTypes.object,
}
