import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
} from '@material-ui/core'
import axios from 'axios'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { getAllOrganizations } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'

const useStyles = makeStyles(() => ({
    root: {},
    cardInput: {
        width: '100%',
    },
    saveButton: {
        marginRight: '5%',
    },
    passwordButton: {
        width: '184px',
    },
}))

const SwitchOrganization = ({setShowSwitchOrganization, className, ...rest }) => {
    const classes = useStyles()
    const [organizations, setOrganizations] = useState([])
    const [selectedOrganization, setSelectedOrganization] = useState(
        localStorage.getItem('organization_id')
    )
    const { sendToast } = useToastContext()

    const handleChange = (event) => {
        setSelectedOrganization(event.target.value)
    }

    const handleSave = () => {
        if (selectedOrganization) {
            localStorage.setItem('organization_id', selectedOrganization)
        }
        window.location.reload()
    }

    const handleReset = () => {
        localStorage.removeItem('organization_id')
        axios.defaults.params = {}
        setSelectedOrganization()
        window.location.reload()
    }

    const fetchOrganizations = async () => {
        try {
            const data = await getAllOrganizations();
            setOrganizations(data);
        } catch (err) {
            console.log('getAllOrganizations', err);
            sendToast(
                `Error getting organizations: ${err}`,
                'error'
            );
            setShowSwitchOrganization(false)
        }
    };

    useEffect(() => {
        fetchOrganizations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader title="Switch Organization" />
            <Divider />
            <CardContent>
                <Box
                    display={'flex'}
                    width="100%"
                    justifyContent="space-between"
                >
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.cardInput}
                    >
                        <InputLabel id="organization-label">
                            Organization
                        </InputLabel>
                        <Select
                            labelId="organization-label"
                            id="organization"
                            label="Organization"
                            value={selectedOrganization}
                            onChange={handleChange}
                        >
                            {organizations.map((data) => {
                                return (
                                    <MenuItem value={data.id} key={data.id}>
                                        {data.company_name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </CardContent>
            <Divider />
            <Box p={2} display="flex" justifyContent="center">
                <Button
                    color="primary"
                    className={classes.saveButton}
                    variant="contained"
                    onClick={handleSave}
                >
                    Save Changes
                </Button>
                <Button
                    color="primary"
                    className={classes.passwordButton}
                    onClick={handleReset}
                >
                    Revert To Self
                </Button>
            </Box>
        </Card>
    )
}

export default SwitchOrganization
