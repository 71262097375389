import _ from 'lodash'
import { colors, createTheme, responsiveFontSizes } from '@material-ui/core'
import { THEMES } from 'src/constants'
import { softShadows, strongShadows } from './shadows'
import { typography } from './typography'

const baseOptions = {
    direction: 'ltr',
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)',
            },
        },
        MuiAvatar: {
            root: {
                width: '30px',
                height: '30px',
                marginRight: '10px',
            },
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: '#6314ff',
            },
        },
        MuiTableCell: {
            root: {
                padding: '2px 10px 0px 10px',
            },
        },

    },
}

const themesOptions = [
    {
        name: THEMES.S2,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
            MUIDataTableToolbar: {
                titleText: {
                    fontSize: '20px',
                    position: 'relative',
                    left: '-8px',
                },
            },
            MuiTableCell: {
                head: {
                    fontWeight: '600',
                    paddingBottom: '0',
                    lineHeight: '1.2',
                },
                body: { background: '#F4F6F8' }
            },
            MUIDataTableHeadCell: {
                data: {
                    fontWeight: '600',
                    lineHeight: '1.2',
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: '#525252',
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: '#eaeaea',
                dark: '#212121',
                paper: colors.common.white,
                transparent: '#546e7a',
                darkGray: '#dbdada',
                lightBlue: 'transparent',
                error: '#f44336',
                autoFill: '#dbdbdb',
                tooltip: '#d7d7d7',
            },
            primary: {
                main: '#6314FF',
                critical: '172, 0, 0, 1',
                high: '222, 93, 40, 1',
                medium: '246, 150, 12, 1',
                low: '201, 222, 14, 1',
                informational: '99, 20, 255, 1',
                criticalbg: '172, 0, 0, 0.1',
                highbg: '222, 93, 40, 0.1',
                mediumbg: '246, 150, 12, 0.1',
                lowbg: '201, 222, 14, 0.1',
                informationalbg: '99, 20, 255, 0.1',
            },
            secondary: {
                main: '#1A4E62',
                navTitle: '#000',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
                orange: '222, 93, 40',
                purple: '99, 20, 255',
                violet: '#8F71F8',
                red: '172, 0, 0',
                lightdark: '#0F1721',
                darklight: '#0F1721',
                resizer:"rgba(112,96,144,.9)"
            },
            link: {
                main: '#0F1721',
                hover: '#8F71F8',
                active: '#6314FF',
                visited: '#6314FF',
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.LIGHT,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
            MUIDataTableToolbar: {
                titleText: {
                    fontSize: '20px',
                    position: 'relative',
                    left: '-8px',
                },
            },
            MuiTableCell: {
                head: {
                    fontWeight: '600',
                    paddingBottom: '0',
                    lineHeight: '1.2',
                },
                body: { background: '#F4F6F8' }
            },
            MUIDataTableHeadCell: {
                data: {
                    fontWeight: '600',
                    lineHeight: '1.2',
                },
            },
            MuiToolbar: {
                regular: {
                    minHeight: '45px !important',
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: '#626161',
                },
            },
            MuiFormLabel: {
                root: {
                    color: '#0f2e3c',
                },
            },
            MuiCircularProgress: {
                colorPrimary: {
                    color: '#6314ff',
                },
            },
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: '#f4f6f8',
                dark: '#F4F6F8',
                paper: colors.common.white,
                transparent: '#546e7a',
                darkGray: '#dbdada',
                lightGray: '#f7f7f7',
                iconColor: colors.common.black,
                lightBlue: 'transparent',
                error: '#f44336',
                tooltip: '#d7d7d7',
            },
            primary: {
                main: colors.indigo[600],
            },
            secondary: {
                main: '#5850EC',
                navTitle: '#fff',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
                darkGray: colors.grey[300],
            },
            link: {
                main: colors.blueGrey,
                hover: colors.blueGrey[400],
                active: colors.blueGrey[600],
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.ONE_DARK,
        overrides: {
            MUIDataTableToolbar: {
                titleText: {
                    fontSize: '20px',
                    position: 'relative',
                    left: '-8px',
                },
            },
            MuiTableCell: {
                head: {
                    fontWeight: '600',
                    paddingBottom: '0',
                    lineHeight: '1.2',
                },
                body: { background: '#0F1721' }
            },
            MUIDataTableHeadCell: {
                data: {
                    fontWeight: '600',
                    lineHeight: '1.2',
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: '#525252',
                },
            },
            MuiCircularProgress: {
                colorPrimary: {
                    color: '#6314ff',
                },
            },
        },
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: 'rgba(15, 23, 33, 1)',
                dark: '#0F1721',
                paper: '#0F1721',
                card: 'D1D1DE',
                silver: '#D1D1DE',
                silverRGB: '209, 209, 222',
                transparent: 'transparent',
                iconColor: colors.common.white,
                darkGray: '#404040',
                lightGray: '#404040',
                lightBlue: 'rgba(255, 255, 255, 0.12)',
                error: '#E6F600',
                autoFill: '#252d37',
                tooltip: '#182433',
            },
            primary: {
                main: '#D1D1DE',
                purple: '#6314FF',
                violet: '#8F71F8',
                critical: '172, 0, 0, 1',
                high: '222, 93, 40, 1',
                medium: '246, 150, 12, 1',
                low: '201, 222, 14, 1',
                informational: '99, 20, 255, 1',
                criticalbg: '172, 0, 0, 0.4',
                highbg: '222, 93, 40, 0.2',
                mediumbg: '246, 150, 12, 0.2',
                lowbg: '201, 222, 14, 0.2',
                informationalbg: '99, 20, 255, 0.2',
            },
            secondary: {
                main: '#fff',
            },
            text: {
                primary: '#F1F1EF',
                secondary: '#F1F1EF',
                orange: '222, 93, 40',
                purple: '99, 20, 255',
                violet: '#8F71F8',
                red: '172, 0, 0',
                lightdark: '#F1F1EF',
                darklight: '#F1F1EF',
                resizer:"rgba(255, 255, 255, .12)"
            },
            error: { main: '#E6F600' },
            link: {
                main: '#F1F1EF',
                hover: '#FFF16A',
                active: '#E6F600',
                visited: '#E6F600',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.UNICORN,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#2a2d3d',
                dark: '#222431',
                paper: '#2a2d3d',
                tooltip: '#182433',
            },
            primary: {
                main: '#a67dff',
            },
            secondary: {
                main: '#a67dff',
            },
            text: {
                primary: '#f6f5f8',
                secondary: '#9699a4',
            },
        },
        shadows: strongShadows,
    },
]

export const createMuiTheme = (config = {}) => {
    let themeOptions = themesOptions.find(
        (theme) => theme.name === config.theme
    )

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`))
            ;[themeOptions] = themesOptions
    }

    let theme = createTheme(
        _.merge({}, baseOptions, themeOptions, { direction: config.direction })
    )

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme)
    }

    return theme
}
